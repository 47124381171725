import React, { useState, useEffect } from 'react'
import { Toaster } from 'react-hot-toast';
import {  Modal, Alert } from 'flowbite-react'
import { Button, TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell, } from '@windmill/react-ui'
function QuoteModal({ isOpen, onClose, onCancel, onAccept, quoteData }) {
  const [modalSize, setModalSize] = useState('5xl');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionTaken, setActionTaken] = useState(false);


  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }

 const formatInitialPremium = (initialPremium) => {
    // Convert initialPremium to a string to check if it already has a decimal place
    const initialPremiumStr = initialPremium.toString();
    if (initialPremiumStr.indexOf('.') === -1) {
      
      return `${initialPremiumStr}.00`;
    } else {
      // If there's already a decimal place, return as is
      return initialPremiumStr;
    }
  };



  const handleAccept = () => {
    setActionTaken(true);
    onAccept();
  };

  const handleRecalculate = () => {
    setActionTaken(true);
    onCancel();
  };

  useEffect(() => {
    if (!isOpen && !actionTaken) {
      <Alert color="success" className="text-sm mt-2">
        Please either accept or re-calculate the quote
      </Alert>
    }

    if (isOpen) {
      setActionTaken(false);
    }
  }, [isOpen, actionTaken]);



  return (
    <>

     <div className="max-w-4xl">

      <Toaster />
      <Modal show={isOpen} size={modalSize} onClose={closeModal}>
         <Modal.Header style={{ background: '#792F7E'}} >
          <h3 className=' text-white sm:text-sm font-semibold my-2 md:text-3xl lg:text-4xl mx-auto text-base text-center justify-center' style={{justifyContent: 'center'}}>
                    Quotation Details
          </h3>
        </Modal.Header>
        <Modal.Body>
          <TableContainer className='mt-0'>
            <Table className="w-full">
              <TableHeader>
                <TableRow>
                  <TableCell>Policy Term</TableCell>
                  <TableCell>Basic Premium (GH₵)</TableCell>
                  <TableCell>Initial Sum Assured</TableCell>
                  <TableCell>Payment Frequency</TableCell>
                  <TableCell>Cover Types</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>{quoteData.policyTerm}</TableCell>
                    <TableCell>{formatInitialPremium(quoteData.basicPremium ? ` ${quoteData.basicPremium.toLocaleString('en-US')}` : '')}</TableCell>
                    
                  <TableCell>{quoteData.initialSumInsured ? `${quoteData.initialSumInsured.toLocaleString('en-US')}` : ''}</TableCell>
                  <TableCell>{quoteData.paymentFrequency}</TableCell>
                 <TableCell>
  <ul className="list-disc pl-4">
    {quoteData.coverTypeAllocations.map((coverType) => (
      <li key={coverType.type} className="mt-1">
        {`${coverType.type}: GH₵ ${coverType.coverTypeSumAssured.toLocaleString('en-US')}`}
      </li>
    ))}
  </ul>
</TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
         
        </Modal.Body>
        <Modal.Footer className="flex justify-between">
          <Button className="w-full sm:w-auto flex justify-start" layout="outline" onClick={handleRecalculate}>
            Re-Calculate Quote
          </Button>
          <Button className="w-full sm:w-auto" style={{ backgroundColor: '#792F7E'}} onClick={handleAccept}>Accept Quote</Button>
        </Modal.Footer>
        </Modal>
      </div>
      </>
      
  )
}
export default QuoteModal