import React, { createContext, useState } from 'react';

export const SignatureContext = createContext();

export const SignatureContextProvider = ({ children }) => {
  const [signatureValue, setSignatureValue] = useState('');
  // const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false);
  



  return (
    <SignatureContext.Provider
      value={{
        signatureValue, setSignatureValue
      }}
    >
      {children}
    </SignatureContext.Provider>
  );
};
