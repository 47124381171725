import React, {useEffect} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, Toaster } from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { clientOtpVerify } from '../utils/actions/authActions';
import { formatPhoneNumber } from 'react-phone-number-input'
import { Button, Modal, Spinner } from 'flowbite-react';
import { clientOtpGenerate } from '../utils/actions/authActions';
import { Input, Label, HelperText } from '@windmill/react-ui';
import { useState } from 'react';

export default function FinalOTPVerification  ({ isOpen, onClose, onOTPSuccess   })  { 
 const applicationID = localStorage.getItem('currentApplicationID');

  const [modalSize, setModalSize] = useState('xl');
  const [loading, setLoading] = useState(false);



  const initialValues = {
    otp: '',
  };

 const validationSchema = Yup.object({
  otp: Yup.string().required('OTP code is required!').length(6, 'Enter all the OTP codes'),
 });
  
  const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'))||[];

  const matchingApplication = ongoingApplications.find((application) => application.id === applicationID);



  
  
  const clientPhoneNumber = formatPhoneNumber(matchingApplication?.data?.clientData?.mobileNumber).replace(/\s/g, '')
  const productInitials = matchingApplication?.id?.match(/[A-Za-z]+/)[0];
  const handleResendClientOTP = async () => {
    try {
      const response = await clientOtpGenerate(clientPhoneNumber, productInitials); 

      if (response.success) {
        if (response.message) {
        toast.success(response.message);
        return true;
      }
      else if (response.otpCode) {
        toast.success("OTP sent successfully.");
        return true; 
      }
    }
    
    toast.error(response.message || 'Failed to send OTP. Please try again.');
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('An error occurred while sending OTP. Please try again.');
      return false;
    }
  };


  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (formik.isValid && !formik.isValidating) {
          setLoading(true);
          const verifyOTPResult = await clientOtpVerify(values, productInitials);

          if (verifyOTPResult.success) {
             toast.success(verifyOTPResult.message, { duration: 4000 });
            onOTPSuccess();
            
           
          } else {
            toast.error(verifyOTPResult.message);
            return false;
          }
        }
      } catch (error) {
        toast.error('An error occurred during login. Please try again.');
        console.log("Error in otp verification", error);
      } finally {
        setLoading(false);
      }
    },

  });

  
const closeModal = () => {
    onClose();
  };

  return (
    <>
      
       <div className="max-w-4xl">
        <Toaster />

     
        <Modal show={isOpen} size={modalSize} onClose={onClose}>
          <Modal.Header>OTP Verification </Modal.Header>
        
          <Modal.Body>

          <div className="space-y-6 p-6 justify-center items-center">
            <p className="text-base text-center mt-2 leading-relaxed text-gray-500 dark:text-gray-400">
              To submit the proposal application, please enter the 6-digit OTP code we just sent to{' '}
                <strong>the client’s</strong> phone number and email address.
              </p>
              
              <form onSubmit={formik.handleSubmit}>
                <div className="mt-5">
                 <Label className="font-medium font-semibold text-center justify-center items-center">OTP Code</Label>
                <div className="flex justify-center mt-5">
                  <OtpInput
                    value={formik.values.otp}
                    onChange={formik.handleChange('otp')}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    isInputNum={true}
                    renderInput={(inputProps, index) => <Input {...inputProps} valid={formik.touched.otp && !formik.errors.otp} />}
                    containerStyle={{}}
                    inputStyle={{
                      width: '4rem',
                      height: '4rem',
                      fontSize: '1.5rem',
                      borderRadius: '0.75rem',
                      border: '1px solid #792F7E',
                      margin: '0 0.25rem',
                      textAlign: 'center',
                    }}
                  />
                </div>
                 {formik.touched.otp && formik.errors.otp && (
                      <HelperText className="text-red-600 text-center items-center justify-center">
                        {formik.errors.otp}
                      </HelperText>
                    )}

      <Modal.Footer className="flex justify-center" style={{ backgroundColor: 'white' }}>
                  <div className="flex justify-center mt-10">
                     <Button
      type="submit"
      style={{ background: '#792F7E' }}
      className="w-full mt-3 py-1 inline-flex justify-center items-center rounded-md border border-transparent font-semibold text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
      disabled={!formik.isValid || formik.isValidating}
    >
      <div className="flex items-center">
        {loading && <Spinner size="md"  className="mr-2" style={{ color: '#792F7E' }} />}
        <span>Verify OTP</span>
      </div>
      </Button>

      </div>
      </Modal.Footer>
                </div>
                <div className="py-4">
                <span className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  Didn't receive the OTP or it expired?
                  <button
                   onClick={handleResendClientOTP}
                    className="decoration-2 hover:underline font-medium px-2"
                    style={{ color: '#792F7E' }}
                  >
                    Resend OTP
                  </button>
                </span>
              </div>
              </form>
            
          </div>
        </Modal.Body>
        </Modal>
      </div>
    </>);
}
