import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, Label, HelperText, Select } from '@windmill/react-ui';

const DSO = ({ onNext }) => {
  const initialValues = {
    referredByDSO: '',
    dsoNumber: '',
  };

  const validationSchema = Yup.object().shape({
    referredByDSO: Yup.string().required('Please select an option'),
    dsoNumber: Yup.string().when('referredByDSO', {
      is: 'true', // Corrected the value to 'true'
      then: Yup.string().required('Please enter the DSO number'),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onNext(values);
    },
  });

  return (
    <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-700 capitalize dark:text-white">DSO Information</h2>
      <form onSubmit={formik.handleSubmit} className="mt-4">
        <Label htmlFor="referredByDSO">Referred by DSO</Label>
        <Select
          id="referredByDSO"
          name="referredByDSO"
          className="mt-1"
          {...formik.getFieldProps('referredByDSO')}
          valid={formik.touched.referredByDSO && !formik.errors.referredByDSO}
          disabled={formik.isSubmitting}
        >
          <option value="">Select an option</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
        {formik.touched.referredByDSO && formik.errors.referredByDSO && (
          <HelperText className="text-red-600">{formik.errors.referredByDSO}</HelperText>
        )}

        {formik.values.referredByDSO === 'true' && (
          <div className="mt-4">
            <Label htmlFor="dsoNumber">DSO Number</Label>
            <Input
              id="dsoNumber"
              name="dsoNumber"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dsoNumber}
              valid={formik.touched.dsoNumber && !formik.errors.dsoNumber}
              disabled={formik.isSubmitting}
            />
            {formik.touched.dsoNumber && formik.errors.dsoNumber && (
              <HelperText className="text-red-600">{formik.errors.dsoNumber}</HelperText>
            )}
          </div>
        )}

        <div className="mt-6">
          <button
            type="submit"
            className="px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700 focus:bg-blue-700"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Submitting' : 'Next'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DSO;
