export const titles = [
    {
        "Code": 121,
        "Title": "SNTR"
    },
    {
        "Code": 9995,
        "Title": "REV."
    },
    {
        "Code": 10185,
        "Title": "HON."
    },
    {
        "Code": 141,
        "Title": "LT. CDR"
    },
    {
        "Code": 7558,
        "Title": "BISHOP"
    },
    {
        "Code": 7560,
        "Title": "MR."
    },
    {
        "Code": 7563,
        "Title": "ENGINEER"
    },
    {
        "Code": 7583,
        "Title": "ALHAJI"
    },
    {
        "Code": 7593,
        "Title": "CHIEF"
    },
    {
        "Code": 7643,
        "Title": "MS."
    },
    {
        "Code": 7706,
        "Title": "MASTER"
    },
    {
        "Code": 7774,
        "Title": "MRS."
    },
    {
        "Code": 7801,
        "Title": "SIR"
    },
    {
        "Code": 7887,
        "Title": "REV. FR"
    },
    {
        "Code": 7956,
        "Title": "ENGR."
    },
    {
        "Code": 7974,
        "Title": "PRINCE"
    },
    {
        "Code": 8016,
        "Title": "DR. (MRS)"
    },
    {
        "Code": 8092,
        "Title": "MISS"
    },
    {
        "Code": 8119,
        "Title": "LADY"
    },
    {
        "Code": 14938,
        "Title": "REV. DR"
    },
    {
        "Code": 15024,
        "Title": "AVM (RTD)"
    },
    {
        "Code": 14571,
        "Title": "CAPT."
    },
    {
        "Code": 10923,
        "Title": "AMBASADOR"
    },
    {
        "Code": 10944,
        "Title": "SURV."
    },
    {
        "Code": 10950,
        "Title": "PROPHET"
    },
    {
        "Code": 11098,
        "Title": "REV. (MRS)"
    },
    {
        "Code": 11105,
        "Title": "ARCH."
    },
    {
        "Code": 11452,
        "Title": "PASTOR MRS"
    },
    {
        "Code": 11732,
        "Title": "CHIEF (DR)"
    },
    {
        "Code": 11801,
        "Title": "PRINCESS"
    },
    {
        "Code": 12134,
        "Title": "BARR (MRS)"
    },
    {
        "Code": 16433,
        "Title": "HAJIA"
    },
    {
        "Code": 25579,
        "Title": "DR."
    },
    {
        "Code": 25646,
        "Title": "QUEEN"
    },
    {
        "Code": 25678,
        "Title": "DR (MRS)"
    },
    {
        "Code": 8388,
        "Title": "PROF."
    },
    {
        "Code": 8423,
        "Title": "ELDER"
    },
    {
        "Code": 8482,
        "Title": "CAPT. (RTD)"
    },
    {
        "Code": 8577,
        "Title": "PASTOR"
    },
    {
        "Code": 8578,
        "Title": "BARR."
    },
    {
        "Code": 8742,
        "Title": "DEACON"
    },
    {
        "Code": 8783,
        "Title": "COMRADE"
    },
    {
        "Code": 8910,
        "Title": "CHIEF MRS"
    },
    {
        "Code": 8256,
        "Title": "REV"
    },
    {
        "Code": 9113,
        "Title": "DR & MRS"
    },
    {
        "Code": 26131,
        "Title": "ENGR & MRS"
    },
    {
        "Code": 201,
        "Title": "555"
    },
    {
        "Code": 203,
        "Title": "202"
    }
]