import React, { createContext, useState } from 'react';

export const TrusteeContext = createContext();

export const TrusteeContextProvider = ({ children }) => {
    const[isTrusteeAdded, setIsTrusteeAdded] = useState(false);
 
  
  const addTrustee = (newTrustee) => {

  }

  return (
    <TrusteeContext.Provider value={{ addTrustee, isTrusteeAdded, setIsTrusteeAdded }}>
      {children}
    </TrusteeContext.Provider>
  );
};
