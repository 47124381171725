import React from 'react';

function SectionTitle({ children, bgColor, textColor, textAlign }) {
  const sectionTitleStyle = {
    backgroundColor: bgColor,
    padding: '0.25rem 0.5rem',
    borderRadius: '0.25rem',
    color: textColor || 'text-gray-600',
    display: 'block',
    textAlign: textAlign || 'left', 
  };

  return (
    <h2 className={`mb-4 text-lg font-semibold ${sectionTitleStyle.color}`} style={{ textAlign: sectionTitleStyle.textAlign }}>
      <span style={sectionTitleStyle}>{children}</span>
    </h2>
  );
}

export default SectionTitle;
