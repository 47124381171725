import React, { useState, useEffect, useContext } from "react";
import {useHistory, useLocation, useParams} from 'react-router-dom';
import { BallTriangle } from  'react-loader-spinner'
import { ClientInfo } from "./ClientInfo";
import  {ClientType}  from "./ClientType";
import { ProductDetailsWPP } from "./ProductDetailsWPP";
import BeneficiaryDetails from "./BeneficiaryDetails";
import Trustee from "./Trustee";
import Dependents from "./Dependents";
import MedicalQuestions from "./MedicalQuestions";
import { format, parseISO, differenceInYears, parse } from 'date-fns';
import PaymentDetails from "./PaymentDetails";
import { productMappings } from "../constants/productMappings";
import Confirmation from "./Confirmation";

import { formatPhoneNumber } from 'react-phone-number-input'
import ApplicationConfirmationModal from "./ApplicationConfirmationModal";
import SubmittedCheckMark from "./SubmittedCheckMark";
import FinalOTPVerification from "./FinalOTPVerification";
import { Button } from "@windmill/react-ui";
import DSO from "./DSO";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { ClientContext } from '../context/ClientContext';
import { CheckboxContext } from '../context/CheckboxContext';
import { clientOtpGenerate } from '../utils/actions/authActions';
import { useApplicationContext } from '../context/ApplicationContext';
import { combinePDFs } from '../utils/actions/proposalAndMandateForm';
import { generateApplicationID, determineStepsCount } from "../utils/actions/helper";

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiKey = process.env.REACT_APP_API_KEY;
const apiUser = process.env.REACT_APP_API_USER;
const authAPI = process.env.REACT_APP_API_AUTH_BASE_URL;


const ClientDetailsStepper = () => {

const clearLocalStorage = (applicationID) => {
  const keysToPreserve = ['proposalDetails', 'currentApplicationID', 'proposal_number', 'agentData', 'ongoingApplications', 'isLoggedIn', 'applicationsData'];
  const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const updatedApplications = ongoingApplications.filter(application => application.id !== applicationID);
  localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));
  Object.keys(localStorage).forEach(key => {
    if (!keysToPreserve.includes(key)) {
      localStorage.removeItem(key);
    }
  });
};


  const { stepCompletionStatus } = useContext(ClientContext);
  

  
   const {   resetCheckboxes, checkboxesChecked  } = useContext(CheckboxContext);
  
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  
  const [showFinalOTPModal, setShowFinalOTPModal] = useState(false);
  const [isSubmittingProposal, setIsSubmittingProposal] = useState(false);
  const [showSubmittedCheckmark, setShowSubmittedCheckmark] = useState(false);
  const { globalBeneficiaryUnder18, isTrusteeAdded, globaltotalBenPercentage, isClientVerified } = useApplicationContext();
  const [isTotalPercentage100, setIsTotalPercentage100] = useState(false);

  


const history = useHistory();
const location = useLocation();
let applicationID;
let {initials} = useParams();
let productName = productMappings.find(mapping => mapping.initials === initials)?.productName || '';

if (location.pathname.includes('/client')) {
  if (!sessionStorage.getItem('newApplicationCreated')) {
    applicationID = generateApplicationID(productName);
    sessionStorage.setItem('newApplicationCreated', 'true');
    localStorage.setItem('currentApplicationID', applicationID);
  } else {
    applicationID = localStorage.getItem('currentApplicationID');
  }
} else {
  applicationID = localStorage.getItem('currentApplicationID');
}
  


const queryParams = new URLSearchParams(location.search);
  const currentStep = parseInt(queryParams.get('step')) || 0;
  

const [steps, setSteps] = useState({
  stepsItems: [
    "Client Type",
    "Client Information",
    ...(initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP" ? ["Dependents"] : []),
    "Product Details",
    "Beneficiary Details",
    "Trustee Details",
    "Medical Questions",
    "Payment Details",
    "Confirmation",
  ].filter(step => step),
  currentStep: currentStep,
});

  

  
  let ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications') || '[]');
  const stepsCount = determineStepsCount(applicationID.substring(0,3));
  let formSubmissionStatus = {};

  for (let i = 0; i < stepsCount; i++) {
    formSubmissionStatus[i] = false;
  }

function findApplication(applicationID) { return ongoingApplications.find(app => app.id === applicationID);}
  

let matchingApplication = findApplication(applicationID);
if (!matchingApplication) {
  const newApplication = {
    id: applicationID,
    product: productName,  
    data: {
      clientData: {
        firstName: "",
        otherNames: "",
        lastName: "",
        dob: null,
        gender: "",
        occupation: "",
        idType: "",
        ghanaCardNumber: "",
        ghanaCardFrontFile: null,
        ghanaCardBackFile: null,
        email: "",
        clientPostalAddress: "",
        residentialAddress: "",
        maritalStatus: "",
        title: "",
        mobileNumber: ""
      }
    },
    status: "Uncompleted",
    formSubmissionStatus,
    step: 0
  };

  ongoingApplications.push(newApplication);
  localStorage.setItem('ongoingApplications', JSON.stringify(ongoingApplications));
  matchingApplication = newApplication;
}



const [isButtonDisabled, setIsButtonDisabled] = useState(true);
useEffect(() => {
    const shouldDisableButton = () => {
      const isCompleted = stepCompletionStatus[steps.currentStep] ?? false;
      if (!isCompleted) return true;
      if (steps.currentStep === 0 && !isClientVerified) return true;
      if ((initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP") &&
          ((steps.currentStep === 4 && !isTotalPercentage100) ||
           (steps.currentStep === 5 && (globalBeneficiaryUnder18 && !isTrusteeAdded)))) {
        return true;
      }
      return false;
    };

    setIsButtonDisabled(shouldDisableButton());
  },  [stepCompletionStatus]);



 const sendEndowmentPoliciesEmail = async (payload) => {
  try {
    const response = await axios.post(`${authAPI}/send-proposal`, payload);
    console.log('Email sent successfully:', response.data);
  } catch (error) {
    console.error('Error sending endowment policies email:', error);
  }
};

  const checkAndSendEmail = async (matchingApplication, initials) => {
  console.log('MATCHING APPLICATION IN EMAIL FUNCTION', matchingApplication);
  const { clientData, productDetailsData } = matchingApplication.data;
  const clientDob = new Date(clientData.dob);
  const clientAge = differenceInYears(new Date(), clientDob); 
  const sumAssured = productDetailsData.quoteDetails.initialSumAssured;
  const medicalQuestions = matchingApplication.medicalQuestions
  const medicalConditionsNo = medicalQuestions.medicalCondition === "No" && 
                              medicalQuestions.onMedication === "No" && 
                              medicalQuestions.surgicalOperationOrProcedure === "No";

  const endowmentInitials = ["WPP", "CLP", "CBP", "FCL", "PCL", "FWP", "PWP"];
  const ultimateInitials = ["PUP", "UPP", "FUP"];

  const emailPayload = {
    client_name: `${clientData.firstName} ${clientData.otherNames} ${clientData.lastName}`,
    initials,
    dob: format(clientDob, 'dd-MM-yyyy'),
    email: clientData.email,
    phone_number: clientData.mobileNumber,
    proposal_number: localStorage.getItem("proposal_number"),
    basic_premium: productDetailsData.quoteDetails.basicPremium,
    initial_sum_assured: sumAssured
  };

  if ((endowmentInitials.includes(initials) && clientAge >= 18 && clientAge < 45 && sumAssured < 300000 && medicalConditionsNo) ||
      (endowmentInitials.includes(initials) && clientAge >= 46 && clientAge < 60 && sumAssured < 250000 && medicalConditionsNo) ||
      (ultimateInitials.includes(initials) && clientAge >= 18 && clientAge < 45 && sumAssured < 300000 && medicalConditionsNo) ||
      (ultimateInitials.includes(initials) && clientAge >= 46 && clientAge < 57 && sumAssured < 200000 && medicalConditionsNo)) {
    await sendEndowmentPoliciesEmail(emailPayload);
  }
};

  

  
  

  const handleBack = () => {
  if (steps.currentStep > 0) {
    if (
      (steps.currentStep === 5 && !globalBeneficiaryUnder18) ||
      (steps.currentStep === 4 && !globalBeneficiaryUnder18 && !(initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP"))
    ) {
     
      setSteps((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 2,
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep - 1,
      }));
    }
  }
};


  const handleNext = () => {
  if (steps.currentStep < steps.stepsItems.length - 1) {
    if (
      (steps.currentStep === 4 && !globalBeneficiaryUnder18) ||
      (steps.currentStep === 3 && !globalBeneficiaryUnder18 && !(initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP"))
    ) {
      setSteps((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep + 2, 
      }));
    } else {
      setSteps((prevState) => ({
        ...prevState,
        currentStep: prevState.currentStep + 1,
      }));
    }
  } else if (steps.currentStep === steps.stepsItems.length - 1) {
    if (!checkboxesChecked.checkbox1) {
      toast.error("Please consent to the processing of your personal data.");
      return;
    }

    if (!checkboxesChecked.checkbox2) {
      toast.error("Please confirm that your statements are true and correct.");
      return;
    }

    if (!checkboxesChecked.checkbox3) {
      toast.error("Please confirm and agree with the terms and conditions of this policy.");
      return;
    }

    openConfirmationModal();
  }
};



  const isLastStep = steps.currentStep === steps.stepsItems.length - 1;
  const buttonText = isLastStep ? "Submit" : "Next";




  const submitTrustee = async () => {
  const webBenCodes = JSON.parse(localStorage.getItem('benAgeUnder18')) || [];
  try {
    const beneficiaryData = matchingApplication.data.beneficiaryData;

    if (!beneficiaryData || beneficiaryData.length === 0) {
      console.log('No data to submit trustee.');
      return;
    }

    const applicationData = matchingApplication.data;
    const trusteeData = beneficiaryData.trustee;

    if (!trusteeData || trusteeData.length === 0 || webBenCodes.length === 0) {
      console.log('No trustee data to submit or no beneficiaries under 18.');
      return;
    }

    const trustee = trusteeData[0]; // Assuming there's only one trustee
    console.log('Trustee data points', trustee);

    let clientCode, clientType;
    if (applicationData.clientData.New_Client_Code) {
      clientCode = applicationData.clientData.New_Client_Code.toString();
      clientType = 'W'; // Assuming 'W' is for new client code
    } else if (applicationData.clientData.existingClientCode) {
      clientCode = applicationData.clientData.existingClientCode.toString();
      clientType = 'C'; // Assuming 'C' is for existing clients
    } else {
      console.log('No client code available.');
      return;
    }

    // Submit trustee information for each beneficiary under 18
    for (const webBenCode of webBenCodes) {
      const payload = {
        Client_Type: clientType,
        Client_Code: clientCode,
        Web_Quote_Code: matchingApplication.data.productDetailsData.quoteDetails.quote_code.toString(),
        Web_Ben_Code: webBenCode,
        DOB: format(new Date(trustee.dob), 'dd-MM-yyyy'),
        First_Name: trustee.firstName,
        Middle_Name: trustee.otherName || '',
        Last_Name: trustee.lastName,
        Gender: trustee.gender.charAt(0).toUpperCase(),
        Address: trustee.Address,
        Relationship: trustee.relationship,
        Contact_Number: formatPhoneNumber(trustee.contactNumber).replace(/\s/g, ''),
        Email: trustee.email,
        Api_User: apiUser,
        Api_Key: apiKey,
      };

      await axios.post(`${apiUrl}/SL_CreateTrustees`, payload);
      console.log(`Trustee for beneficiary code ${webBenCode} submitted.`);
    }
    
    console.log('All trustees submitted for beneficiaries under 18.');
  } catch (error) {
    console.error('Error submitting trustees:', error);
  }
};



  




const submitDependent = async () => {
  try {
    const dependentData = matchingApplication.data.dependentData;

    if (!dependentData || dependentData.length === 0) {
      console.log('No data to submit dependent.');
      return;
    }

    
    const dependentTypesResponse = await axios.post(
      `${apiUrl}/SL_DependentTypes`	,
      {
        "Api_User": apiUser,
        "Api_Key": apiKey,
      }
    );
    const dependentTypes = dependentTypesResponse.data;

    for (const dependent of dependentData) {
     
      const matchedDependentType = dependentTypes.find(type => type.Desciption === dependent.dependent);
      if (!matchedDependentType) {
        console.log(`No Dep_Code found for dependent ${dependent.lastName}`);
        continue;
      }

      const payload = {
        "Quote_Code": matchingApplication.data.productDetailsData.quoteDetails.calculation_quote_number,
        "Dep_Code": matchedDependentType.Dep_Code, 
        "Surname": dependent.lastName,
        "Othernames": dependent.otherNames || '',
        "DOB": format(new Date(dependent.dob), 'dd-MM-yyyy'),
        "Sex": dependent.gender.charAt(0).toUpperCase().toString(),
        "Api_User": apiUser,
        "Api_Key": apiKey,
      };

      await axios.post(`${apiUrl}/SL_AddDependent`, payload);
      console.log(`Dependent ${dependent.lastName} submitted.`);
    }

    console.log('All dependents submitted.');
  } catch (error) {
    console.error('Error submitting dependents:', error);
  }
};


  







const submitBeneficiary = async () => {
  try {
    const beneficiaries = matchingApplication.data.beneficiaryData.beneficiaries;

    if (!beneficiaries || beneficiaries.length === 0) {
      console.log('No beneficiaries to submit.');
      return;
    }
    let benUnder18Codes = [];

    for (const beneficiary of beneficiaries) {
      const payload = {
        Client_Type: matchingApplication?.data.clientData.New_Client_Code ? 'W' : 'C',
        Client_Code: `${matchingApplication?.data.clientData.New_Client_Code ?? matchingApplication?.data.clientData.existingClientCode}`,
        Web_Quote_Code: matchingApplication.data.productDetailsData.quoteDetails.quote_code.toString(),
        DOB: format(parseISO(beneficiary.dob), 'dd-MM-yyyy'), 
        First_Name: beneficiary.firstNames,
        Last_Name: beneficiary.lastName,
        Gender: beneficiary.gender.charAt(0).toUpperCase(),
        Proportion_Benefit: beneficiary.percentage.toString(),
        Relationship: beneficiary.relationship,
        Contact_Number: formatPhoneNumber(beneficiary.contactNumber).replace(/\s/g, ''),
        Api_User: apiUser,
        Api_Key: apiKey,
      };

      const response = await axios.post(`${apiUrl}/SL_CreateBeneficiaries`, payload);
      console.log(`Beneficiary ${beneficiary.firstNames} submitted.`);

      // Assuming the API returns an array of objects for each beneficiary submitted in this call
      response.data.forEach(ben => {
        const dob = parse(ben.wbnfDob, 'dd-MM-yyyy', new Date());
        const age = differenceInYears(new Date(), dob);

        if (age <= 18) {
          benUnder18Codes.push(ben.wbnfCode.toString()); 
          console.log('Beneficiary under 18:', ben);
        }
      });
    }

    if (benUnder18Codes.length > 0) {
      localStorage.setItem("benAgeUnder18", JSON.stringify(benUnder18Codes));
    }


    console.log('All beneficiaries submitted.');
  } catch (error) {
    console.error('Error submitting beneficiaries:', error);
  }
};












  const submitProposal = async () => {
  const quoteCode = matchingApplication.data.productDetailsData.quoteDetails.quote_code.toString();
  const payload = {
    Web_Quote_Code: quoteCode,
    Api_User: apiUser,
    Api_Key: apiKey,
  };
  console.log("payload in submitProposal", payload);
  setIsSubmittingProposal(true);

  try {
    const response = await axios.post(`${apiUrl}/SL_GenerateProposal`, payload);
    console.log("response from generateProposal", response);

    if (response.data.message && response.data.message.includes("Generated proposal Number")) {
      // Handle the case with a message field
      const proposalNumberMatch = response.data.message.match(/Generated proposal Number (.*) is already in use/);
      if (proposalNumberMatch && proposalNumberMatch[1]) {
        const proposalNumber = proposalNumberMatch[1];
        localStorage.setItem("proposal_number", proposalNumber);
        console.log(`Extracted proposal number: ${proposalNumber}`);
        setIsSubmittingProposal(false);
        return true;
      } else {
        console.log('Could not extract proposal number.');
        setIsSubmittingProposal(false);
        return false;
      }
    } else if (response.data.response) {
      // Directly use the response field if present
      const proposalNumber = response.data.response;
      localStorage.setItem("proposal_number", proposalNumber);
      console.log(`Set proposal number from response: ${proposalNumber}`);
      setIsSubmittingProposal(false);
      return true;
    } else {
      console.log('Please try again later');
      setIsSubmittingProposal(false);
      return false;
    }
  } catch (error) {
    console.error("Error making the request:", error.response ? error.response.data : error);
    setIsSubmittingProposal(false);
    return false;
  }
};






const handleOTPVerificationSuccess = async () => {
  setShowFinalOTPModal(false);
  let sendProposalTQ;
  const maxRetries = 3;
  let currentTry = 0;

  try {
    const dependentData = matchingApplication.data.dependentData;
    if (dependentData && dependentData.length > 0) {
      await submitDependent();
    }

    const beneficiaryData = matchingApplication.data.beneficiaryData;
    if (beneficiaryData && beneficiaryData.beneficiaries && beneficiaryData.beneficiaries.length > 0) {
      await submitBeneficiary();
    }

    if (beneficiaryData && beneficiaryData.trustee && beneficiaryData.trustee.length > 0) {
      await submitTrustee();
    }

    const submissionSuccessful = await submitProposal();
    console.log("submissionSuccessful", submissionSuccessful);

    if (submissionSuccessful) {
      setShowSubmittedCheckmark(true);

      while (currentTry < maxRetries) {
        sendProposalTQ = await combinePDFs();
        if (sendProposalTQ && sendProposalTQ.success) {
          await checkAndSendEmail(matchingApplication, initials);
          break; // Exit the loop if successful
        } else {
          currentTry++;
          console.log(`Attempt ${currentTry}: Failed to send PDF documents.`);
        }
      }

      if (currentTry === maxRetries) {
        console.error('All attempts to combine PDFs have failed.');
        // Optionally handle the final failure case here, such as notifying an admin
      }
      
    } else {
      setShowSubmittedCheckmark(false);
      toast.error('We could not submit that application. Please double check that you have provided all required information!!');
    }
  } catch (error) {
    console.error('Error submitting proposal:', error);
  }
};


  
  
  function openConfirmationModal() {
  setShowConfirmationModal(true);
  };

  function closeConfirmationModal() {
    setShowConfirmationModal(false);
    console.log("Closing confirmation modal");
}
  
function openFinalOTPModal () {
  setShowFinalOTPModal(true);
  };

const handleConfirmationYes = async () => {
  closeConfirmationModal();
  const productInitials = matchingApplication?.id.match(/[A-Za-z]+/)[0];
  try {
    const response = await clientOtpGenerate(clientPhoneNumber, productInitials);
    
    if (response.success) {
      if (response.message) {
        toast.success(response.message);
        handleOTPVerificationSuccess();
        return;
      }
      else if (response.otpCode) {
        toast.success("OTP sent successfully.");
        openFinalOTPModal();
        return; 
      }
    }
    
    toast.error(response.message || 'Failed to send OTP. Please try again.');
  } catch (error) {
    console.error('Error sending OTP:', error);
    toast.error('An error occurred while sending OTP. Please try again.');
  }
};




  const clientPhoneNumber = formatPhoneNumber(matchingApplication?.data?.clientData?.mobileNumber).replace(/\s/g, '')
  
  
  
useEffect(() => {
  const { currentStep } = steps;
  const cachedOngoingApplications = localStorage.getItem('ongoingApplications');
  
  if (cachedOngoingApplications) {
    const updatedApplications = JSON.parse(cachedOngoingApplications).map(application => {
      if (application.id === applicationID) {
        return {
          ...application,
          step: currentStep, 
        };
      }
      return application;
    });
    localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));
  }
}, [applicationID, steps]);



useEffect(() => {
  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));

  if (cachedOngoingApplications) {
    const matchingApplication = cachedOngoingApplications.find(application => application.id === applicationID);

    // Assuming beneficiaryData is directly within the data object of each application
    if (matchingApplication && matchingApplication.data && matchingApplication.data.beneficiaryData) {
      const totalBenPercentage = matchingApplication.data.beneficiaryData.totalBenPercentage;
      console.log('totalBenPercentage in global', totalBenPercentage);

      setIsTotalPercentage100(totalBenPercentage === 100);
    } else {
      // Handle the case where beneficiary data doesn't exist or is malformed
      setIsTotalPercentage100(false);
    }
  }
}, [applicationID, globaltotalBenPercentage]);



  
// useEffect(() => {
//   console.log('useEffect is triggered');

//   const applicationID = localStorage.getItem('currentApplicationID');
//   const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

//   const allApplications = [].concat(...ongoingApplications);

//   const application = allApplications.find(app => app.id === applicationID);

//   if (application && application.formSubmissionStatus) {
//     const currentStepStatus = application.formSubmissionStatus[steps.currentStep];

//     setFormStatus(currentStepStatus);
//   }
// }, [steps.currentStep]);




  return (
    <div className="w-full min-h-[400px] py-3 flex flex-col mr-10 justify-start mt-10 mb-10 bg-white rounded-lg shadow-lg dark:bg-gray-800">
      <Toaster />
      <ul
        aria-label="Steps"
        className="items-center text-gray-600 font-medium md:flex"
      >
        {steps.stepsItems.map((item, idx) => (
          <li
            key={item}
            aria-current={steps.currentStep === idx ? "step" : false}
            className="flex gap-x-3 md:flex-col md:flex-1 md:gap-x-0"
          >
            <div className="flex flex-col items-center md:flex-row md:flex-1">
              <hr
  className={`w-full border hidden md:block ${
    idx === 0 ? "border-none" : ""
  }`}
  style={{
    borderColor: steps.currentStep >= idx ? "#792F7E" : "",
    borderWidth: "1px",
  }}
/>

            <div
  className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${
    steps.currentStep > idx ? "border-gray-600" : ""
  } ${steps.currentStep === idx ? "border-green-400" : ""}`}
  style={{
    backgroundColor: steps.currentStep > idx ? "#792F7E" : "",
    borderColor: steps.currentStep >= idx ? "#792F7E" : "",
    borderWidth: "1px",
  }}
>

                <span
                  className={`w-2.5 h-2.5 rounded-full border-green-400 ${
                    steps.currentStep !== idx ? "hidden" : ""
                  }`}
                ></span>
                {steps.currentStep > idx && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                )}
              </div>
              <hr
                className={`h-12 border md:w-full md:h-auto ${
                  idx + 1 === steps.stepsItems.length ? "border-none" : ""
                  } ${steps.currentStep > idx ? "" : ""}`}
                  style={{
    borderColor: steps.currentStep >= idx ? "#792F7E" : "",
    borderWidth: "1px",
  }}
              />
            </div>
            <div className="h-8 flex justify-center items-center md:mt-3 md:h-auto">
              <h3
                className={`text-sm ${
                  steps.currentStep === idx ? "" : ""
                }`}
               
              >
                {item}
              </h3>
            </div>
          </li>
        ))}
      </ul>

       {steps.currentStep === 0 && <ClientType />}
{steps.currentStep === 1 && <ClientInfo />}
{steps.currentStep === 2 && (initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP") && <Dependents />}
{steps.currentStep === (initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP" ? 3 : 2) && <ProductDetailsWPP />}
{steps.currentStep === (initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP" ? 4 : 3) && <BeneficiaryDetails />}
{steps.currentStep === (initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP" ? 5 : 4) && <Trustee />}
{steps.currentStep === (initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP" ? 6 : 5) && <MedicalQuestions />}
{steps.currentStep === (initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP" ? 7 : 6) && <PaymentDetails />}
{steps.currentStep === (initials === "EHP" || initials === "FEH" || initials === "PEH" || initials === "FPP" ? 8 : 7) && <Confirmation />}


     {/* Back and Next Buttons */}
<div className="flex mt-8  justify-between w-full px-4">
  <Button
    className="px-10 py-2 text-white rounded-md"
    style={{ backgroundColor: "#792F7E" }}
    onClick={handleBack}
    disabled={steps.currentStep === 0}
  >
    Back
  </Button>

<Button
  disabled={isButtonDisabled}
  className="py-2 text-white rounded-md px-10"
  style={{ backgroundColor: "#792F7E" }}
  onClick={handleNext}
>
  {buttonText}
</Button>







</div>




   {showConfirmationModal && (
      <ApplicationConfirmationModal
        isOpen={showConfirmationModal} 
        onClose={closeConfirmationModal}
        onConfirm={handleConfirmationYes} 
      />
    )}
    {showFinalOTPModal && (
      <FinalOTPVerification
        isOpen={showFinalOTPModal}
          onClose={() => setShowFinalOTPModal(false)}
          onOTPSuccess={handleOTPVerificationSuccess}
      />
      )}

      {isSubmittingProposal && (
        <div className="fixed inset-0 text-left top-0 left-0 w-screen h-screen flex flex-col justify-center items-center bg-white bg-opacity-70 z-50">
          <div className="flex flex-col items-center">
           <BallTriangle
                  width='50'
                  height='50'
                color="#792F7E"
                wrapperClassName="mx-auto flex flex-col items-center justify-center mt-10" />
  <p className="mt-5 text-lg text-gray-900">We are submitting your application. Please wait...</p>
          </div>
  
</div>

      )}
    {showSubmittedCheckmark && (
        <SubmittedCheckMark
          isOpen={showSubmittedCheckmark}
          onClose={() => setShowSubmittedCheckmark(false)}
          onDashboard={() => {
            history.push('/app/dashboard')
            clearLocalStorage(applicationID);
            resetCheckboxes();

          }}
          onOnboard={() => {
            history.push('/app/products')
            clearLocalStorage(applicationID);
            resetCheckboxes();

          }}
        />
      )}

    </div>
  );
};

export default ClientDetailsStepper;
