import React, { createContext, useState } from 'react';

export const ProductDetailsContext = createContext();

export const ProductDetailsContextProvider = ({ children }) => {
  const [productDetailsData, setProductDetailsData] = useState({});
  // // const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false);
  // const [formSubmitted, setFormSubmitted] = useState(false);
  // // const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false);



  return (
    <ProductDetailsContext.Provider
      value={{
        productDetailsData,
        setProductDetailsData,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
};
