import React, { useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import * as Yup from 'yup';
import SectionTitle from '../components/Typography/SectionTitle';
import { Label, Select, HelperText, Textarea, Button } from '@windmill/react-ui';
import { ClientContext } from '../context/ClientContext'; 
import OnboardingAlert from '../components/Alerts/OnboardingAlert';


const MedicalQuestions = () => {
let applicationID = localStorage.getItem('currentApplicationID');
  const { updateStepCompletionStatus } = useContext(ClientContext);
  const initials = applicationID.substring(0, 3);

const [medicalQuestions, setMedicalQuestions] = useState(() => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const matchingApplication = storedOngoingApplications.find(application => application.id === applicationID);
  return matchingApplication?.medicalQuestions || {
    goodHealth: '',
    medicalCondition: '',
    medicalConditionDetails: '',
    onMedication: '',
    medicationDetails: '',
    surgicalOperationOrProcedure: '',
    surgicalOperationOrProcedureDetails: '',
  };
});





  const validationSchema = Yup.object().shape({
    goodHealth: Yup.string().required('Please select whether you are presently in good health'),
    medicalCondition: Yup.string().required('Please select whether you have been diagnosed with any medical condition or illness'),
    medicalConditionDetails: Yup.string().when('medicalCondition', {
      is: (medicalCondition) => medicalCondition === 'Yes',
      then: (schema) => schema.required('Please provide details of your medical condition'),
      otherwise: (schema) => schema.notRequired(),
    }),
    onMedication: Yup.string().required('Please select whether you are on medication'),
      
    medicationDetails: Yup.string().when('onMedication', {
      is: (onMedication) => onMedication === 'Yes',
      then: (schema) => schema.required('Please state the type of medication and dosage'),
      otherwise: (schema) => schema.notRequired(),
    }),
  
  
    surgicalOperationOrProcedure: Yup.string().required('Please select whether you have undergone any surgical operations or procedures'),
    surgicalOperationOrProcedureDetails: Yup.string().when('surgicalProcedure', {   
      is: (surgicalProcedure) => surgicalProcedure === 'Yes',
      then: (schema) => schema.required('Please state the type of surgical operation or procedure"'),
      otherwise: (schema) => schema.notRequired(),
    }),       
  });

  const initialValues = {
  goodHealth: medicalQuestions.goodHealth || '',
  medicalCondition: medicalQuestions.medicalCondition || '',
  medicalConditionDetails: medicalQuestions.medicalConditionDetails || '',
  onMedication: medicalQuestions.onMedication || '',
  medicationDetails: medicalQuestions.medicationDetails || '',
  surgicalOperationOrProcedure: medicalQuestions.surgicalOperationOrProcedure || '',
  surgicalOperationOrProcedureDetails: medicalQuestions.surgicalOperationOrProcedureDetails || '',
};




  const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: async (values, { resetForm }) => {

      if (
      values.goodHealth === 'Yes' || values.goodHealth === 'No' &&
      values.onMedication === 'No' &&
      values.surgicalOperationOrProcedure === 'No' &&
      !values.medicalConditionDetails &&
      !values.surgicalOperationOrProcedureDetails &&
      !values.medicationDetails
    ) {
     
        toast.success('Great, you are in good health!');
        updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 6 : 5, true);
     
      console.log(formik.values);
    } else {
      let payload = {
        ClntType: 'W',
        ClntCode: localStorage.getItem('New_Client_Code'),
        WebQuoteCode: localStorage.getItem('quote_code'),
        Condition: '',
        ConditionTreatment: '',
        MedicationType: '',
        Api_User: 'infosapp',
        Api_Key: '1nf0s@pp',
      };

      if (values.medicalCondition === 'No') {
        payload.Condition = 'No Condition';
      } else if (values.medicalCondition === 'Yes' && values.medicalConditionDetails) {
        payload.Condition = values.medicalConditionDetails;
      }
        

      if (values.surgicalOperationOrProcedure === 'Yes' && values.surgicalOperationOrProcedureDetails) {
        payload.ConditionTreatment = values.surgicalOperationOrProcedureDetails;
      }

      if (values.onMedication === 'Yes' && values.medicationDetails) {
        payload.MedicationType = values.medicationDetails;
      }

        try {
         const apiUrl = 'https://corsproxy.io/?' + encodeURIComponent('https://slacapi.starlifecentral.com/StarLife/SL_PersonalMedicalNew');
        const response = await axios.post(apiUrl, payload);

        if (response.data.message_code === 100) {
         updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 6 : 5, true);
          toast.success(
            <span>
              {response.data.message} <br /> Please Click on <strong>Next</strong> to add payment details.
            </span>,
            { duration: 6000 }
          );
        } else {
          console.log(response.data.message);
          toast.error('Oops! Please try again');
          updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 6 : 5, false);

        }
      } catch (error) {
        console.log(error);
        toast.error('An error occurred while submitting the form');
      }
    }
  },
  });
  


useEffect(() => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const matchingApplication = storedOngoingApplications.find(app => app.id === applicationID);
  if (matchingApplication && matchingApplication.medicalQuestions) {
    setMedicalQuestions(matchingApplication.medicalQuestions);
  }
}, [applicationID]);




useEffect(() => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const applicationIndex = storedOngoingApplications.findIndex(application => application.id === applicationID);
  
  if (applicationIndex !== -1) {
    // Clone the applications array for immutability
    const updatedOngoingApplications = [...storedOngoingApplications];
    // Update the specific application's medical questions
    updatedOngoingApplications[applicationIndex] = {
      ...updatedOngoingApplications[applicationIndex],
      medicalQuestions: formik.values,
    };
    // Save back to local storage
    localStorage.setItem('ongoingApplications', JSON.stringify(updatedOngoingApplications));
  }
}, [formik.values, applicationID]);



 

  return (
   <div className=" py-3 mb-8 flex flex-col mr-10 justify-start mt-10">
      <div className="mx-6 flex-auto  justify-start">
        <Toaster />
        <OnboardingAlert />
        <SectionTitle textAlign="center">Medical History</SectionTitle>
        <form onSubmit={formik.handleSubmit}>

          <div className="grid grid-cols-2 gap-8 mb-8">


            <div className="col-span-1 flex flex-col ">

               <Label className="mt-4">
            <span>1. Are you presently in good health?</span>
            <Select
              className="mt-1"
              id="goodHealth"
              {...formik.getFieldProps('goodHealth')}
              valid={formik.touched.goodHealth && !formik.errors.goodHealth}
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {formik.touched.goodHealth && formik.errors.goodHealth && (
              <HelperText className="text-red-500">{formik.errors.goodHealth}</HelperText>
            )}
              </Label>
              

              <Label className="mt-4"v>
            <span>2. Have you been diagnosed with any medical condition or illness?</span>
            <Select
              className="mt-1"
              id="medicalCondition"
              {...formik.getFieldProps('medicalCondition')}
              valid={formik.touched.medicalCondition && !formik.errors.medicalCondition}
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {formik.touched.medicalCondition && formik.errors.medicalCondition && (
              <HelperText className="text-red-500">{formik.errors.medicalCondition}</HelperText>
            )}
          </Label>

          {formik.values.medicalCondition === 'Yes' && (
            <Label className="mt-4">
              <span className="mt-1"> Please Provide details of your medical condition</span>
              <Textarea
                className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                id="medicalCondition"
                {...formik.getFieldProps('medicalConditionDetails')}
                placeholder="Enter details of your medical condition"
              />
              {formik.touched.medicalConditionDetails && formik.errors.medicalConditionDetails && (
                <HelperText className="text-red-500">{formik.errors.medicalConditionDetails}</HelperText>
              )}
            </Label>
          )}


            </div>


            <div className="col-span-1 flex flex-col ">
              
                  {/* Question 3: Are you currently on any medication? */}
          <Label className="mt-4">
            <span>3. Are you on any medication?</span>
            <Select
              className="mt-1"
              id="onMedication"
              {...formik.getFieldProps('onMedication')}
              valid={formik.touched.onMedication && !formik.errors.onMedication}
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {formik.touched.onMedication && formik.errors.onMedication && (
              <HelperText className="text-red-500">{formik.errors.onMedication}</HelperText>
            )}
          </Label>

          {/* Medication Textarea */}
          {formik.values.onMedication === 'Yes' && (
            <Label className="mt-4">
              <span>Medication Details</span>
              <Textarea
                className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                id="medication"
                {...formik.getFieldProps('medicationDetails')}
                placeholder="Enter details of your medication"
              />
              {formik.touched.medicationDetails && formik.errors.medicationDetails && (
                <HelperText className="text-red-500">{formik.errors.medicationDetails}</HelperText>
              )}
            </Label>
          )}

          {/* Question 4: Have you undergone any surgical operations or procedures? */}
          <Label className="mt-4">
            <span>4. Have you undergone any surgical operations or procedures?</span>
            <Select
              className="mt-1"
              id="surgicalProcedure"
              {...formik.getFieldProps('surgicalOperationOrProcedure')}
              valid={formik.touched.surgicalOperationOrProcedure && !formik.errors.surgicalOperationOrProcedure}
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {formik.touched.surgicalOperationOrProcedure && formik.errors.surgicalOperationOrProcedure && (
              <HelperText className="text-red-500">{formik.errors.surgicalOperationOrProcedure}</HelperText>
            )}
          </Label>


          {/* Medication Textarea */}
          {formik.values.surgicalOperationOrProcedure === 'Yes' && (
            <Label className="mt-4">
              <span>Surgical Operations or Procedures Details</span>
              <Textarea
                className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                id="medication"
                {...formik.getFieldProps('surgicalOperationOrProcedureDetails')}
                placeholder="Enter details of your medication"
              />
              {formik.touched.surgicalOperationOrProcedureDetails && formik.errors.surgicalOperationOrProcedureDetails && (
                <HelperText className="text-red-500">{formik.errors.surgicalOperationOrProcedureDetails}</HelperText>
              )}
            </Label>
          )}

            </div>
            

            <div className="col-span-2 flex justify-start mt-10">
          <Button onSubmit={formik.handleSubmit}  
            type="submit"
            className="text-white px-4 py-2 rounded hover:bg-gray-600 rounded hover:bg-gray-600 sm:w-auto"
            style={{ backgroundColor: '#792F7E' }}
            disabled={formik.isSubmitting}
          >
            Add Medical History
            </Button>
            </div>

          </div>
          </form>
            

      </div>
    </div>
  );
};

export default MedicalQuestions;
