import React, { useContext, useEffect, useState } from 'react';
import {useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import 'react-phone-number-input/style.css'
import * as Yup from 'yup';
import SectionTitle from '../components/Typography/SectionTitle';
import { Input, HelperText, Label, Select, Button, TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell } from '@windmill/react-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {  differenceInYears } from 'date-fns';
import { toast, Toaster } from 'react-hot-toast';
import { EditIcon } from '../icons';
import {TrashIcon} from '../icons';
import EditDependentModal from './EditDependentModal';
import { ClientContext } from '../context/ClientContext';
import OnboardingAlert from '../components/Alerts/OnboardingAlert';




const relationshipOptions = [
  { Dep_Code: 1002, Description: 'CHILD', genderOptions: ['MALE', 'FEMALE'], AgeLimit: 23 },
  { Dep_Code: 20059, Description: 'FATHER', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 200510, Description: 'MOTHER', genderOptions: ['FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201632, Description: 'MOTHER-IN-LAW', genderOptions: ['FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201633, Description: 'FATHER-IN-LAW', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 201638, Description: 'NEPHEW', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 201639, Description: 'FIANCE(E)', genderOptions: ['MALE', 'FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201637, Description: 'NIECE', genderOptions: ['FEMALE'], AgeLimit: 74 },
  { Dep_Code: 1001, Description: 'SPOUSE', genderOptions: ['MALE', 'FEMALE'], AgeLimit: 64 },
  { Dep_Code: 201640, Description: 'BROTHER', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 201641, Description: 'SISTER', genderOptions: ['FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201745, Description: 'AUNT', genderOptions: ['FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201749, Description: 'BROTHER-IN-LAW', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 201750, Description: 'SISTER-IN-LAW', genderOptions: ['FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201644, Description: 'UNCLE', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 201642, Description: 'COUSIN', genderOptions: ['MALE', 'FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201635, Description: 'DAUGHTER', genderOptions: ['FEMALE'], AgeLimit: 23 },
  { Dep_Code: 201855, Description: 'SON', genderOptions: ['MALE'], AgeLimit: 23 },
  { Dep_Code: 201743, Description: 'GRANDFATHER', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 201744, Description: 'GRANDMOTHER', genderOptions: ['FEMALE'], AgeLimit: 74 },
  { Dep_Code: 201746, Description: 'GUARDIAN', genderOptions: ['FEMALE', 'MALE'], AgeLimit: 74 },
  { Dep_Code: 201747, Description: 'STEP FATHER', genderOptions: ['MALE'], AgeLimit: 74 },
  { Dep_Code: 201748, Description: 'STEP MOTHER', genderOptions: ['FEMALE'], AgeLimit: 74 },
];

const ageLimitMap = relationshipOptions.reduce((map, option) => {
  if (!map.hasOwnProperty(option.Description)) {
    map[option.Description] = option.AgeLimit; 
  }
  return map;
}, {});



export const Dependents = () => {
  let applicationID = localStorage.getItem('currentApplicationID');

   const [genderOptions, setGenderOptions] = useState([]);
  const [dependents, setDependents] = useState([]); 
  const [editDependentIndex, setEditDependentIndex] = useState(null); 
  const { updateStepCompletionStatus } = useContext(ClientContext);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [numChildren, setNumChildren] = useState(0);

  const initials = applicationID.substring(0, 3);
   let selectedProduct = null;
   switch (initials) {
    case 'EHP':
      selectedProduct = 'Esteem Homecall Plus';
       break;
    case 'FEH':
      selectedProduct = 'Fabulous Homecall';
       break;
    case 'PEH':
      selectedProduct = 'Phobia Homecall';
      break;
    case 'FPP':
      selectedProduct = 'Family Protection Plan';
       break;
    default:
      selectedProduct = 'Unknown Product';
  }

const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

    
     const matchingApplication = storedOngoingApplications.find(app => app.id === applicationID);
  
 const dependentsData = matchingApplication.data.dependentsData;


const initialValues = {
  lastName: '',
  otherNames:  '',
  dob: null,
  gender: '',
  dependent: '',
};


  const validationSchema =Yup.object().shape({
    otherNames: Yup.string().required('Other Names are required'),
    lastName: Yup.string().required('Last Name is required'),
    dob: Yup.date().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required'),
    dependent: Yup.string().required('Dependent is required'),
  });


const handleRelationshipChange = (event) => {
  const selectedRelationship = event.target.value;
  const selectedOption = relationshipOptions.find((option) => option.Description === selectedRelationship);

  if (selectedOption) {
    formik.setFieldValue('dependent', selectedOption.Description);
    formik.setFieldValue('gender', ''); 
    setGenderOptions(selectedOption.genderOptions);
  } else {
    formik.setFieldValue('dependent', '');
    formik.setFieldValue('gender', '');
    setGenderOptions([]);
  }
  };
  



 const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };

  


   const openEditModal = (index) => {
    setEditDependentIndex(index);
    setEditModalOpen(true);
  };


  const handleEditSubmit = (editedIndex, editedValues) => {
  editedValues.otherNames = editedValues.otherNames.toUpperCase();
  editedValues.lastName = editedValues.lastName.toUpperCase();
  const age = calculateAge(editedValues.dob);
  const relationshipDescription = editedValues.dependent;
  const maxAge = ageLimitMap[relationshipDescription];

  if (!maxAge) {
    toast.error('Invalid Dependent selected.');
    return;
  }

  if (relationshipDescription === 'CHILD' && numChildren >= 4) {
    toast.error("You cannot add more than 4 children.");
    return;
  }

  if (age > maxAge) {
    if (relationshipDescription === 'CHILD') {
      toast.error("A child cannot be more than 23 years.");
    } else if (relationshipDescription === 'SPOUSE') {
      toast.error("A spouse cannot be more than 64 years.");
    } else {
      toast.error(`Dependent age must be less than ${maxAge} years.`);
    }
    return;
  }

  const isChild = editedValues.dependent === 'CHILD';
  const oldChildCount = dependents.filter((dependent, index) => {
    if (index === editedIndex) {
      return false;
    }
    return dependent.dependent === 'CHILD';
  }).length;

  const newChildCount = isChild && editedValues.dob && calculateAge(editedValues.dob) <= 23 ? 1 : 0;

  setNumChildren((prevNumChildren) => prevNumChildren - oldChildCount + newChildCount);

  const updatedDependents = dependents.map((dependent, index) =>
    index === editedIndex ? { ...dependent, ...editedValues } : dependent
  );

 
  setDependents(updatedDependents);
  updateLocalStorage(updatedDependents);

  setEditModalOpen(false);
  setEditDependentIndex(null);
};












const updateLocalStorage = (newDependentData) => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

  // Find the application object by ID
  const applicationObject = storedOngoingApplications.find(application => application.id === applicationID);

  // If the application exists, update its dependent data
  if(applicationObject) {
    applicationObject.data = {
      ...applicationObject.data,
      dependentData: newDependentData,
    };
  }

  // Update localStorage with the modified array
  localStorage.setItem('ongoingApplications', JSON.stringify(storedOngoingApplications));
};







  

const calculateAge = (dob) => {
    return differenceInYears(new Date(), dob);
  };


const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: async (values) => {
    const age = calculateAge(values.dob);

    const relationshipDescription = values.dependent;
    const maxAge = ageLimitMap[relationshipDescription];

    if (!maxAge) {
      toast.error('Invalid Dependent selected.');
      return;
    }

    if (relationshipDescription === 'CHILD' && numChildren >= 4) {
      toast.error("You cannot add more than 4 children.");
      return;
    }

    if (age > maxAge) {
      if (relationshipDescription === 'CHILD') {
        toast.error("A child cannot be more than 23 years.");
      } else if (relationshipDescription === 'SPOUSE') {
        toast.error("A spouse cannot be more than 64 years.");
      } else {
        toast.error(`Dependent age must be less than ${maxAge} years.`);
      }
      return;
    }

    const newDependent = {
      lastName: values.lastName,
      otherNames: values.otherNames,
      dob: values.dob,
      gender: values.gender,
      dependent: values.dependent,
    };

    const updatedDependents = [...dependents, newDependent];
    setDependents(updatedDependents);

    updateStepCompletionStatus(2,true);
    formik.resetForm();
  },
});




  
const deleteDependent = (index) => {
  const updatedDependents = [...dependents];
  const removedDependent = updatedDependents[index];

  if (removedDependent.dependent === 'CHILD') {
    setNumChildren((prevNumChildren) => prevNumChildren - 1);
  }

  updatedDependents.splice(index, 1);
  setDependents(updatedDependents);

  updateLocalStorage(updatedDependents);
};


  
  useEffect(() => {
  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));

  const matchingApplication = cachedOngoingApplications.find(application => application.id === applicationID);

  if (matchingApplication) {
      const savedDependentData = matchingApplication.data.dependentData; 

   if (savedDependentData) {
      setDependents(savedDependentData); 
    } else {
      setDependents([]);
    }
  }
}, [applicationID]);
  




useEffect(() => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

  // Find the application to update
  const applicationToUpdate = storedOngoingApplications.find(application => application.id === applicationID);

  // If found, update the application in place
  if (applicationToUpdate) {
    applicationToUpdate.data = {
      ...applicationToUpdate.data,
      dependentData: dependents,
    };
  }

  localStorage.setItem('ongoingApplications', JSON.stringify(storedOngoingApplications));
}, [dependents]);









  return (
    <div className=" py-3 mb-8 flex flex-col mr-10 justify-start mt-10">
      <Toaster />
      <OnboardingAlert />
     <div className="mx-6 flex-auto  justify-start">
       
        <SectionTitle textAlign="center">Dependent Details</SectionTitle>
         <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-8">

            <div className="col-span-1 flex flex-col ">
              
              
        <Label className="mt-4">
          <span>Last Name</span>
          <Input
            className="mt-1"
            placeholder="Enter your last name"
                {...formik.getFieldProps('lastName')}
                onChange={(e) => handleInputChange('lastName', e)}
            valid={formik.touched.lastName && !formik.errors.lastName}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <HelperText className="text-red-600">{formik.errors.lastName}</HelperText>
          )}
        </Label>

        {/* Other Names */}
        <Label className="mt-4">
          <span>Other Names</span>
          <Input
            className="mt-1"
            placeholder="Enter your other names"
                {...formik.getFieldProps('otherNames')}
                onChange={(e) => handleInputChange('otherNames', e)}
            valid={formik.touched.otherNames && !formik.errors.otherNames}
          />
          {formik.touched.otherNames && formik.errors.otherNames && (
            <HelperText className="text-red-600">{formik.errors.otherNames}</HelperText>
          )}
        </Label>

      

        {/* Date of Birth */}
         <Label htmlFor="dob" className="mt-4">
              <span>Date of Birth</span>
           <div className="mt-1">
           <DatePicker
          id="dob"
      selected={formik.values.dob}
      onChange={(date) => formik.setFieldValue('dob', date)}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
      customInput={
        <Input
          type="text"
          placeholder="Select date"
          className={formik.touched.dob && formik.errors.dob ? 'error' : ''}
          value={formik.values.dob ? formik.values.dob.toString() : ''}
          
        />
      }
      onBlur={() => formik.setFieldTouched('dob', true)}
      showMonthDropdown
      showYearDropdown
                dropdownMode="select"
                placeholderText="Select date"
      />
      </div>
      {formik.touched.dob && formik.errors.dob && (
      <div className="text-red-500">{formik.errors.dob}</div>
      )}
            </Label>
            
            </div>
            
            <div className="col-span-1 flex flex-col ">


                {/* Relationship */}
       <Label htmlFor="dependent" className="mt-4">
      <span>Dependent</span>
      <Select
    id="dependent"
    className="mt-1"
    {...formik.getFieldProps('dependent')}
    valid={formik.touched.dependent && !formik.errors.dependent}
    onChange={handleRelationshipChange}
      >
    <option value="">Select Dependent</option>
    {relationshipOptions.map((option) => (
      <option key={option.Dep_Code} value={option.Description}>
        {option.Description}
      </option>
    ))}
  </Select>
  {formik.touched.dependent && formik.errors.dependent && (
    <HelperText className="text-red-500">{formik.errors.dependent}</HelperText>
  )}
</Label>

{/* Gender */}
{genderOptions.length > 0 && (
  <Label htmlFor="gender" className="mt-4">
    <span>Gender</span>
    <Select
      id="gender"
      className="mt-1"
      {...formik.getFieldProps('gender')}
                  valid={formik.touched.gender && !formik.errors.gender}
                  onChange={(e) => handleInputChange('gender', e)}
    >
      <option value="">Select Beneficiary's Gender</option>
      {genderOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
    {formik.touched.gender && formik.errors.gender && (
      <HelperText className="text-red-500">{formik.errors.gender}</HelperText>
    )}
  </Label>
)}

        


            </div>

<div className="col-span-2 flex mt-12">
            <Button onClick={formik.handleSubmit} type="submit" className="text-white px-4 py-2 rounded hover:bg-gray-600" style={{backgroundColor: "#792F7E"}} >
              Add New Dependent
            </Button>
          </div>

          </div>
           </form>
          
      


      <div className="col-span-2 mt-12">
  {dependents.length > 0 && (
    <div>
      <SectionTitle>Dependent(s) Added</SectionTitle>
      <TableContainer className="mt-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell>Other Name(s)</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Dependent</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {dependents.map((dependent, index) => (
              <TableRow key={index}>
                <TableCell>{dependent.lastName}</TableCell>
                <TableCell>{dependent.otherNames}</TableCell>
                <TableCell>{new Date(dependent.dob).toLocaleDateString('en-GB')}</TableCell>

                <TableCell>{dependent.gender}</TableCell>
                <TableCell>{dependent.dependent}</TableCell>
                <TableCell>
                  <TableCell className="flex items-center">
                    <TrashIcon
                      className="w-8 h-8 mr-8"
                      style={{ cursor: 'pointer', fill: "#dc2626" }}
                      onClick={() => deleteDependent(index)}
                    />
                    <EditIcon
                      className="w-8 h-8"
                      style={{ cursor: 'pointer' }}
                      onClick={() => openEditModal(index)}
                    />
                  </TableCell>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
              <div className='mb-10'>
               
              </div>
            </div>
          )}
           


        </div>
      </div>
      
<EditDependentModal
  isOpen={editModalOpen}
  onClose={() => setEditModalOpen(false)}
  dependentIndex={editDependentIndex} 
  initialValues={dependents[editDependentIndex]} 
  saveEditedDependent={handleEditSubmit}
  validationSchema={validationSchema}
  relationshipOptions={relationshipOptions}
/>

      </div>
  );
};

export default Dependents;
