export const productMappings = [
  { initials: 'WPP', productName: 'Wealth Master Plus' },
  { initials: 'CLP', productName: 'Child Lifeline Plus' },
  { initials: 'UPP', productName: 'Ultimate Protection Plus' },
  { initials: 'EHP', productName: 'Esteem Homecall Plus' },
  { initials: 'SUH', productName: 'Supreme Homecall Plan' },
  { initials: 'CBP', productName: 'Cashbuilder Plus' },
  { initials: 'FPP', productName: 'Family Protection Plan' },
  { initials: 'FCL', productName: 'Fabulous Child Education' },
  { initials: 'FWP', productName: 'Fabulous Wealthmaster' },
  { initials: 'FUP', productName: 'Fabulous Ultimate Protection' },
  { initials: 'FEH', productName: 'Fabulous Homecall' },
  { initials: 'PCL', productName: 'Phobia Child Education' },
  { initials: 'PUP', productName: 'Phobia Ultimate Protection' },
  { initials: 'PWP', productName: 'Phobia Wealth Master' },
  { initials: 'PEH', productName: 'Phobia Homecall' }
];