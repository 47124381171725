import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation  } from 'react-router-dom';
import PhoneInput, {  formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { Label, Select, Button, Input, HelperText } from '@windmill/react-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SectionTitle from '../components/Typography/SectionTitle';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios'; 
import { Spinner, Alert } from 'flowbite-react';
import { useApplicationContext } from '../context/ApplicationContext';
import { ClientContext } from '../context/ClientContext';
import OnboardingAlert from '../components/Alerts/OnboardingAlert';

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiKey = process.env.REACT_APP_API_KEY;
const apiUser = process.env.REACT_APP_API_USER;

export const ClientType = () => {
  let applicationID = localStorage.getItem('currentApplicationID');
    const initials = applicationID.substring(0, 3);
  const [isReferredByAgent, setIsReferredByAgent] = useState(false);
  const [showAgentAlert, setShowAgentAlert] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setIsClientVerified, existingClientData, setExistingClientData } = useApplicationContext();
  const { updateStepCompletionStatus  } = useContext(ClientContext);

   const location = useLocation();
  const showReferredField = location.pathname.includes('/client');
  
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

    
  const matchingApplication = storedOngoingApplications.find(app => app.id === applicationID);

const clientInfo = matchingApplication ? matchingApplication.data.clientData : null;

const validationSchema = Yup.object().shape({
    hasPolicy: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().when('hasPolicy', {
      is: (clientType) => clientType === 'YES',
      then: (schema) => schema.required('Phone Number is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    referredByAgent: Yup.string().required('This field is required'),
agentCode: Yup.string().when('referredByAgent', {
  is: (agent) => agent === 'YES',
 then: (schema) => schema.required('Agent Code is required'),
otherwise: (schema) => schema.notRequired().default('DIRECT'),
}),

});
  
  
   const validationSchemaWithAgent = Yup.object().shape({
    hasPolicy: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().when('hasPolicy', {
      is: (clientType) => clientType === 'YES',
      then: (schema) => schema.required('Phone Number is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    referredByAgent: Yup.string().required('This field is required'),
    agentCode: Yup.string().when('referredByAgent', {
      is: (agent) => agent === 'YES',
      then: (schema) => schema.required('Agent Code is required'),
      otherwise: (schema) => schema.notRequired().default('DIRECT'),
    }),
  });

  const validationSchemaWithoutAgent = Yup.object().shape({
    hasPolicy: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().when('hasPolicy', {
      is: (clientType) => clientType === 'YES',
      then: (schema) => schema.required('Phone Number is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const useAgentValidationSchema = location.pathname.includes('/client');
  const selectedValidationSchema = useAgentValidationSchema
    ? validationSchemaWithAgent
    : validationSchemaWithoutAgent;
  

  const initialValues = {
    hasPolicy: matchingApplication?.clientType || '', 
    phoneNumber: matchingApplication?.phoneNumber || '',
    referredByAgent: '',
  agentCode: '',
  };


const verifyAgentCode = async (agentCode) => {
  const payload = {
  Agn_Code: agentCode, 
  Api_User: apiUser,
  Api_Key: apiKey,
};
   try {
    const response = await axios.post(`${apiUrl}/SL_FindAgency`, payload);
    if (response.data.message_code === 100 && response.data.agnCode) {
  setAgentDetails({
    name: response.data.agnName,
    phoneNumber: response.data.agnTel1,
    status: response.data.agnStatus,
    email: response.data.agnEmailAddress
  });
  setShowAgentAlert(true);
} else {
  throw new Error(response.data.message);
}

  } catch (error) {
    console.error("Error verifying agent:", error);
    setAgentDetails(null);  
  }
};





async function checkExistingPolicy(phoneNumber, initials) {
  try {
    const payload = {
      Customer_Mobile: formatPhoneNumber(phoneNumber).replace(/\s/g, ''),
      Api_User: apiUser,
      Api_Key: apiKey,
    };

    const response = await axios.post(`${apiUrl}/SL_ExistingPolicies`, payload);

    // Check if client is not identified (meaning no existing policies)
    if (response.data.message_code === 101) {
      return false; // Client does not have existing policies
    }

    // If client is identified, check for policies with matching initials and ACTIVE status
    const data = response.data.data;
    for (const item of data) {
      const proposalNumber = item.PROPOSAL_NUMBER ? item.PROPOSAL_NUMBER.substring(1, 4) : '';
      const policyNumber = item.POLICY_NUMBER ? item.POLICY_NUMBER.substring(0, 3) : '';
      const status = item.STATUS;

      if ((proposalNumber === initials || policyNumber === initials) && status === 'ACTIVE') {
        return true; // Client has an existing ACTIVE policy with the given initials
      }
    }

    return false; // No matching ACTIVE policies found
  } catch (error) {
    console.error('Error:', error);
    return false; // In case of an error, assume no existing policies
  }
}


  


  const formik = useFormik({
    initialValues,
    validationSchema: selectedValidationSchema,
    onSubmit: async (values) => {
      console.log('client values', values);

      try {
        setLoading(true);

        const payload = {
          Customer_Mobile: formatPhoneNumber(values.phoneNumber).replace(/\s/g, ''),
          Api_User: apiUser,
          Api_Key: apiKey,
        };

        const response = await axios.post(`${apiUrl}/SL_ClientVerificationByPhone`, payload);

        if (response.data.clntCode) {
          

            const existingPolicy = await checkExistingPolicy(values.phoneNumber);
            if (existingPolicy) {
              toast.error(`This client already has a ${initials} policy.`,
                {
                  duration: 6000,
                  pauseOnHover: true,
                });
              setIsClientVerified(false)
              updateStepCompletionStatus(0, false);
              return;
            }
          setIsClientVerified(true)
          updateStepCompletionStatus(0, true);

          const clientDataToUpdate = {
            existingClientCode: response.data.clntCode,
            clientPostalAddress: response.data.webClntPostalAddress,
            dob: response.data.webClntDob,
            email: response.data.webClntEmail,
            firstName: response.data.webClntFirstName,
            gender: response.data.webClntGender ? response.data.webClntGender : 'F',
            ghanaCardBackFile: null,
            ghanaCardFrontFile: null,
            ghanaCardNumber: response.data.webClntIdRegNo,
            idType: response.data.webClntIdRegDoc,
            lastName: response.data.webClntLastName,
            maritalStatus: response.data.webClntMaritalStatus,
            mobileNumber: `+233${response.data.webClntMobileNo.substring(1)}`,
            occupation: response.data.webClntOccCode,
            otherNames: response.data.webClntMiddleName,
            residentialAddress: response.data.webClntPhysicalAddress,
            title: response.data.webClntTitle,
          };
          
          setExistingClientData(clientDataToUpdate);
          //console.log("clientDataToUpdate", existingClientData);

          const updatedMatchingApplication = {
            ...matchingApplication,
            hasPolicy: values.hasPolicy,
            phoneNumber: values.phoneNumber,
            clientData: clientDataToUpdate,
          };

          // Update the local storage data
          const updatedApplications = storedOngoingApplications.map(app => {
            if (app.id === applicationID) {
              return updatedMatchingApplication;
            }
            return app;
          });

          localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));

          toast.success('Client verified successfully. Please click NEXT to proceed.', {
            duration: 7000,
            pauseOnHover: true,
          });

        } else {
          toast.error('Client does not have a policy with StarLife');
          updateStepCompletionStatus(0, false);
        }
      } catch (error) {
        console.log('Error in client verification: ', error);
        toast.error('An error occurred while verifying client');
        updateStepCompletionStatus(0, false);
      } finally {
        setLoading(false);
      }
      
    },
  });


 const updateLocalStorage = (appId, updatedData) => {
  const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const updatedApplications = ongoingApplications.map(application => 
    application.id === appId ? { ...application, ...updatedData } : application
  );

  localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));
};


    useEffect(() => {
    if (applicationID) {
      const updatedData = {
        ...matchingApplication,
        referredByAgent: formik.values.referredByAgent,
        
        agentDetails: agentDetails
      };
      updateLocalStorage(applicationID, updatedData);
    }
  }, [formik.values.referredByAgent, agentDetails, applicationID, formik.values.agentCode]);



 useEffect(() => {
  // Directly find the matching application in the updated structure of ongoingApplications
  const matchingApplication = storedOngoingApplications.find(app => app.id === applicationID);

  if (matchingApplication) {
    if (matchingApplication.data.clientData.hasPolicy) {
      formik.setFieldValue('hasPolicy', matchingApplication.data.clientData.hasPolicy);
    }
    if (matchingApplication.data.clientData.phoneNumber) {
      formik.setFieldValue('phoneNumber', matchingApplication.data.clientData.phoneNumber);
    }
  }
}, [applicationID, storedOngoingApplications]);


  useEffect(() => {
    if (matchingApplication) {
      const updatedApplication = {
        ...matchingApplication,
        clientType: formik.values.hasPolicy,
        phoneNumber: formik.values.phoneNumber,
      };

      const updatedApplications = storedOngoingApplications.map(app => {
        if (app.id === applicationID) {
          return updatedApplication;
        }
        return app;
      });

      localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));
    }
  }, [formik.values, applicationID, storedOngoingApplications]);


  useEffect(() => {
  if (isReferredByAgent && agentDetails) {
    setShowAgentAlert(true);
  } else {
    setShowAgentAlert(false);
  }
  }, [isReferredByAgent, agentDetails]);
  

  // useEffect(() => {
  // console.log("Updated existingClientData:", existingClientData);
  // }, [existingClientData]);
  

useEffect(() => {
  if (existingClientData && Object.keys(existingClientData).length > 0) {
    let ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
    
    const applicationIndex = ongoingApplications.findIndex(app => app.id === applicationID);

    if (applicationIndex !== -1) {
      ongoingApplications[applicationIndex].data.clientData = existingClientData;
      
      ongoingApplications[applicationIndex].hasPolicy = formik.values.hasPolicy;
      ongoingApplications[applicationIndex].phoneNumber = formik.values.phoneNumber;

      localStorage.setItem('ongoingApplications', JSON.stringify(ongoingApplications));
      
      console.log("Local storage updated with new client data and application details.");
    }
  }
}, [existingClientData, applicationID]);




   return (
      
    <div className=" py-3 mb-8 flex flex-col mr-10 justify-start mt-10">
       <div className="mx-6 flex-auto  justify-start">
          <OnboardingAlert />
    <SectionTitle textAlign="center" >Client Verification</SectionTitle>
            <form onSubmit={formik.handleSubmit}>

          <div className="grid grid-cols-2 gap-8 mb-8 " >
            
      
             <div className="col-span-1 flex flex-col ">
               
                <Label className="mt-4">
            <span>Do you have a policy with StarLife?</span>
            <Select
              className="mt-1"
              {...formik.getFieldProps('hasPolicy')}
               onChange={(e) => {
                    formik.handleChange(e);
                    
                    if (e.target.value === 'NO') {
                      setIsClientVerified(true);
                      updateStepCompletionStatus(0, true);
                        setExistingClientData({
                          clientPostalAddress: '',
                          dob: '',
                          
                          email: '',
                          firstName: '',
                          gender: '',
                          ghanaCardBackFile: null,
                          ghanaCardFrontFile: null,
                          ghanaCardNumber: '',
                          idType: '',
                          lastName: '',
                          maritalStatus: '',
                          mobileNumber: '',
                          occupation: '',
                          otherNames: '',
                          residentialAddress: '',
                          title: '',
                        });
                    }
                  }}

              valid={formik.touched.hasPolicy && !formik.errors.hasPolicy}
            >
              <option value="">Select an option</option>
              <option value="YES">YES</option>
              <option value="NO">NO</option>
            </Select>
            {formik.touched.hasPolicy && formik.errors.hasPolicy && (
              <HelperText className="text-red-500">{formik.errors.hasPolicy}</HelperText>
            )}
          </Label>

               {formik.values.hasPolicy === 'YES' && (
                 <Label className="mt-4">
  <span>Client Phone Number</span>
 <PhoneInput
inputComponent={Input}
  placeholder="Enter your mobile number"
  defaultCountry="GH"
  international
  countryCallingCodeEditable={false}
                     value={formik.values.phoneNumber}
                     onChange={(value) => {
                      
    formik.setFieldValue('phoneNumber', value);
    formik.setFieldTouched('phoneNumber', true);
  }}
  error={
      (formik.values.phoneNumber &&
        !isValidPhoneNumber(formik.values.phoneNumber)
        ? ['Invalid phone number length']
        : [])
    }
/>

  {(formik.touched.phoneNumber &&
    formik.errors.phoneNumber) ||
    (formik.values.phoneNumber &&
      !isValidPhoneNumber(formik.values.phoneNumber)) ? (
    <div className="text-red-600">
      {formik.errors.phoneNumber ||
        'Invalid phone number length'}
    </div>
  ) : null}
</Label>
               )}
               

               {showReferredField && (
              <Label className="mt-4">
                <span>Were you referred by an agent?</span>
                <Select
                  className="mt-1"
                  {...formik.getFieldProps('referredByAgent')}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (e.target.value === 'YES') {
                      setIsReferredByAgent(true);
                    } else if (e.target.value === 'NO') {
                      setIsReferredByAgent(false);
                    }
                  }}
                >
                  <option value="">Select an option</option>
                  <option value="YES">YES</option>
                  <option value="NO">NO</option>
                </Select>
              </Label>
            )}



{formik.values.referredByAgent === 'YES' && (
  <Label className="mt-4">
    <span>Agent Code</span>
                   <Input
                  
                   className="mt-1"
      {...formik.getFieldProps('agentCode')}
      placeholder="Please enter agent's code"
      onChange={(e) => {
        formik.handleChange(e);
        if (e.target.value) {
          verifyAgentCode(e.target.value);
        }
      }}
    />
  </Label>
)}

{formik.values.referredByAgent === 'NO' && (
  <Label className="mt-4">
    <span>Agent Code</span>
                   <Input
                     className="mt-1"
      value="DIRECT"
      disabled
    />
  </Label>
)}
             </div>
             
           {showAgentAlert && agentDetails && (
  <Alert className="mt-4" color="success">
    <div className="mt-3">
      <strong>Name:</strong> {agentDetails.name}
    </div>
    <div className="mt-3">
      <strong>Phone Number:</strong> {agentDetails.phoneNumber}
    </div>
    <div className="mt-3">
      <strong>Status:</strong> {agentDetails.status}
    </div>
    <div className="mt-3">
      <strong>Email:</strong> {agentDetails.email}
    </div>
  </Alert>
   )}




      
            <div className="col-span-2 flex justify-start mt-10">
             {formik.values.hasPolicy === 'YES' && (
  <Button onClick={formik.handleSubmit}
    className="px-6 py-2 text-white rounded-md"
    style={{ backgroundColor: "#792F7E" }}
  >
    {loading ? (
      <>
        <Spinner size="md" color="" className="mr-2" />
        Verifying...
      </>
    ) : (
      ' Verify Client' 
    )}
  </Button>
)}

 
                
              </div>
               </div>

          </form> 
     
      </div>
        <Toaster />
         

    </div>);
 }; 
