import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toaster } from 'react-hot-toast';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, HelperText, Label, Select } from '@windmill/react-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { differenceInYears } from 'date-fns';
import 'react-phone-number-input/style.css'

const EditBeneficiaryModal = ({ isOpen, onClose, dependentIndex, relationshipOptions, validationSchema, initialValues, saveEditedDependent }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [genderOptions, setGenderOptions] = useState([]);

  
 const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };

  
  const calculateAge = (dob) => {
    return differenceInYears(new Date(), dob);
  };

  const handleRelationshipChange = (event) => {
    const selectedRelationship = event.target.value;
    const selectedOption = relationshipOptions.find((option) => option.Description === selectedRelationship);

    if (selectedOption) {
      formik.setFieldValue('dependent', selectedOption.Description);
      formik.setFieldValue('gender', '');
      setGenderOptions(selectedOption.genderOptions);
    } else {
      formik.setFieldValue('dependent', '');
      formik.setFieldValue('gender', '');
      setGenderOptions([]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveEditedDependent(dependentIndex, values);
    },
  });

  useEffect(() => {
    if (initialValues && initialValues.dob) {
      setSelectedDate(new Date(initialValues.dob));
    } else {
      setSelectedDate(null);
    }
  }, [initialValues]);



 

  return (
    <>
      <div className="max-w-4xl">
        <Toaster />
    <Modal isOpen={isOpen} onClose={onClose} >
      <ModalHeader>Edit Dependent</ModalHeader>
      <ModalBody>
        <div className="flex-1 items-center justify-center">
          <form onSubmit={formik.handleSubmit}>
            <Label className="mt-4">
              <span>Last Name</span>
              <Input
                    className="mt-1"
                    onChange={(e) => handleInputChange('lastName', e)}
                placeholder="Enter your last name"
                {...formik.getFieldProps('lastName')}
                valid={formik.touched.lastName && !formik.errors.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <HelperText className="text-red-600">{formik.errors.lastName}</HelperText>
              )}
            </Label>


            <Label className="mt-4">
              <span>Other Names</span>
              <Input
                className="mt-1"
                    placeholder="Enter your other names"
                  onChange={(e) => handleInputChange('otherNames', e)}
                {...formik.getFieldProps('otherNames')}
                valid={formik.touched.otherNames && !formik.errors.otherNames}
              />
              {formik.touched.otherNames && formik.errors.otherNames && (
                <HelperText className="text-red-600">{formik.errors.otherNames}</HelperText>
              )}
            </Label>


                 <Label className="mt-4">
              <span>Date of Birth</span>
           <div className="mt-1">
             <DatePicker
  id="dob"
  selected={selectedDate}
 onChange={(date) => {
              setSelectedDate(date);
              formik.setFieldValue('dob', date); 
            }}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
  customInput={
    <Input
      type="text"
      placeholder="Select date"
      className={formik.touched.dob && formik.errors.dob ? 'error' : ''}
      
    />
  }
  onBlur={() => formik.setFieldTouched('dob', true)}
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
  placeholderText="Select date"
/>

                    </div>
      {formik.touched.dob && formik.errors.dob && (
      <div className="text-red-500">{formik.errors.dob}</div>
      )}
            </Label>


            {/* Relationship */}
            <Label htmlFor="dependent" className="mt-4">
      <span>Dependent</span>
      <Select
    id="dependent"
    className="mt-1"
    {...formik.getFieldProps('dependent')}
    valid={formik.touched.dependent && !formik.errors.dependent}
    onChange={handleRelationshipChange}
      >
    <option value="">Select dependent</option>
    {relationshipOptions.map((option) => (
      <option key={option.Dep_Code} value={option.Description}>
        {option.Description}
      </option>
    ))}
  </Select>
  {formik.touched.dependent && formik.errors.dependent && (
    <HelperText className="text-red-500">{formik.errors.dependent}</HelperText>
  )}
</Label>

            {/* Gender */}
            {genderOptions.length > 0 && (
              <Label htmlFor="gender" className="mt-4">
                <span>Gender</span>
                <Select
                  id="gender"
                  className="mt-1"
                  {...formik.getFieldProps('gender')}
                  valid={formik.touched.gender && !formik.errors.gender}
                >
                  <option value="">Select Beneficiary's Gender</option>
                  {genderOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {formik.touched.gender && formik.errors.gender && (
                  <HelperText className="text-red-500">{formik.errors.gender}</HelperText>
                )}
              </Label>
            )}

           
            
           <ModalFooter className="flex justify-center">
              <div className="flex justify-center mt-10">
                <Button
                   onClick={formik.handleSubmit}
                  type="button"
                  
                  style={{ backgroundColor: '#792F7E', color: 'white' , padding: "10px 50px"}}
                >
                  Save
                </Button>
                
              </div>
            </ModalFooter>
          </form>
        </div>
      </ModalBody>
      </Modal>
        </div>
</> );
};

export default EditBeneficiaryModal;
