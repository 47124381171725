export const institutions = [
    {
        "Institution_Code": 2018201234813,
        "Institution_Name": "MINISTRY OF LANDS"
    },
    {
        "Institution_Code": 2018201234814,
        "Institution_Name": "MINISTRY OF INTERIOR"
    },
    {
        "Institution_Code": 2018201234815,
        "Institution_Name": "MINISTRY OF WORKS"
    },
    {
        "Institution_Code": 2018201234816,
        "Institution_Name": "MINISTRY OF MANPOWER"
    },
    {
        "Institution_Code": 2018201234817,
        "Institution_Name": "GHANA EDUCATION SERVICE"
    },
    {
        "Institution_Code": 2018201234818,
        "Institution_Name": "GH. FRESH PRODUCE CO. LTD"
    },
    {
        "Institution_Code": 2018201234819,
        "Institution_Name": "GHANA PRISONS SERVICE"
    },
    {
        "Institution_Code": 2018201234820,
        "Institution_Name": "MINISTRY OF HEALTH"
    },
    {
        "Institution_Code": 2018201234821,
        "Institution_Name": "GHANA POLICE SERVICE"
    },
    {
        "Institution_Code": 2018201234822,
        "Institution_Name": "MINISTRY OF DEFENCE"
    },
    {
        "Institution_Code": 2018201234823,
        "Institution_Name": "LIGHT ACADEMY PRIMARY SCH"
    },
    {
        "Institution_Code": 2018201234824,
        "Institution_Name": "GHANA NATIONAL FIRE SERV."
    },
    {
        "Institution_Code": 2018201234825,
        "Institution_Name": "CUSTOMS(CEPS)"
    },
    {
        "Institution_Code": 2018201234826,
        "Institution_Name": "ELECTORAL COMMISSION"
    },
    {
        "Institution_Code": 2018201234827,
        "Institution_Name": "GHANA PORTS & HABOURS"
    },
    {
        "Institution_Code": 2018201234828,
        "Institution_Name": "CAD SOLUTIONS LIMITED"
    },
    {
        "Institution_Code": 2018201234829,
        "Institution_Name": "PRESEC STAFF SCHOOLS"
    },
    {
        "Institution_Code": 2018201234830,
        "Institution_Name": "GHANAIAN DANISH COMM PROG"
    },
    {
        "Institution_Code": 2018201234831,
        "Institution_Name": "INTER-CON SECURITY SYS."
    },
    {
        "Institution_Code": 2018201234832,
        "Institution_Name": "PORT MEDICAL CENTRE"
    },
    {
        "Institution_Code": 2018201234833,
        "Institution_Name": "UNIVERSITY OF GHANA LEGON"
    },
    {
        "Institution_Code": 2018201234834,
        "Institution_Name": "VOLTA LAKE COMPANY"
    },
    {
        "Institution_Code": 2018201234835,
        "Institution_Name": "NURSES & MIDWIVES ASS."
    },
    {
        "Institution_Code": 2018201234781,
        "Institution_Name": "WAEC"
    },
    {
        "Institution_Code": 2018201234782,
        "Institution_Name": "CONSAR"
    },
    {
        "Institution_Code": 2018201234783,
        "Institution_Name": "BRYANT MISSION HOSPITAL"
    },
    {
        "Institution_Code": 2018201234784,
        "Institution_Name": "NAJA DAVID VENEER"
    },
    {
        "Institution_Code": 2018201234785,
        "Institution_Name": "AVIANCE GHANA LTD(AFGO)"
    },
    {
        "Institution_Code": 2018201234786,
        "Institution_Name": "K NKRUMAH UNIVERSITY"
    },
    {
        "Institution_Code": 2018201234787,
        "Institution_Name": "NATIONAL SEC CO-ORDINATOR"
    },
    {
        "Institution_Code": 2018201234788,
        "Institution_Name": "VOLTA RIVER AUTHORITY"
    },
    {
        "Institution_Code": 2018201234789,
        "Institution_Name": "GHANA AIRPORT CO. LTD."
    },
    {
        "Institution_Code": 2018201234790,
        "Institution_Name": "GHANA POST COMPANY LTD"
    },
    {
        "Institution_Code": 2018201234791,
        "Institution_Name": "SAMARTEX"
    },
    {
        "Institution_Code": 2018201234792,
        "Institution_Name": "GHANA COCOA BOARD"
    },
    {
        "Institution_Code": 2018201234793,
        "Institution_Name": "A. G. TIMBERS"
    },
    {
        "Institution_Code": 2018201234794,
        "Institution_Name": "PAUL SAGOE SAWMILLERS"
    },
    {
        "Institution_Code": 2018201234795,
        "Institution_Name": "ST PAUL METH PRI SCH-TEMA"
    },
    {
        "Institution_Code": 2018201234796,
        "Institution_Name": "NAT. VOCATIONAL TRAINING"
    },
    {
        "Institution_Code": 2018201234799,
        "Institution_Name": "PRINCE OF PEACE SCHOOL"
    },
    {
        "Institution_Code": 2018201234800,
        "Institution_Name": "WOODLAND MECHANICAL CO."
    },
    {
        "Institution_Code": 2018201234801,
        "Institution_Name": "NOGUCHI MEMORIAL INST."
    },
    {
        "Institution_Code": 2018201234802,
        "Institution_Name": "INTERNAL REVENUE SERVICE"
    },
    {
        "Institution_Code": 2018201234803,
        "Institution_Name": "GLOW LAMP INT. SCH."
    },
    {
        "Institution_Code": 2018201234804,
        "Institution_Name": "GHANA STATISTICAL SERV."
    },
    {
        "Institution_Code": 2018201234805,
        "Institution_Name": "CONTROLLER"
    },
    {
        "Institution_Code": 2018201234806,
        "Institution_Name": "NATIONAL SPORTS COLLEGE"
    },
    {
        "Institution_Code": 2018201234807,
        "Institution_Name": "ASSEMBLIES OF GOD GHANA"
    },
    {
        "Institution_Code": 2018201234808,
        "Institution_Name": "ASSEMB. OF GOD GT. ACCRA."
    },
    {
        "Institution_Code": 2018201234809,
        "Institution_Name": "ASSEMB. OF GOD LIT. CENT."
    },
    {
        "Institution_Code": 2018201234810,
        "Institution_Name": "S.D.A. NURSERY/PREP. SCH."
    },
    {
        "Institution_Code": 2018201234811,
        "Institution_Name": "MINISTRY OF LOCAL GOV."
    },
    {
        "Institution_Code": 2018201234812,
        "Institution_Name": "MINISTRY OF AGRICULTURE"
    },
    {
        "Institution_Code": 2018201234749,
        "Institution_Name": "LATEX FOAM CO. LTD."
    },
    {
        "Institution_Code": 2018201234750,
        "Institution_Name": "KUMASI VOCATIONAL INST."
    },
    {
        "Institution_Code": 2018201234751,
        "Institution_Name": "GRIMALDI GHANA LIMITED"
    },
    {
        "Institution_Code": 2018201234752,
        "Institution_Name": "CROSS OCEAN AGENCY LTD."
    },
    {
        "Institution_Code": 2018201234754,
        "Institution_Name": "STATESMAN COMM. CO. LTD."
    },
    {
        "Institution_Code": 2018201234755,
        "Institution_Name": "KUMASI ABATTIOR"
    },
    {
        "Institution_Code": 2018201234756,
        "Institution_Name": "GLANDERSON ROOF"
    },
    {
        "Institution_Code": 2018201234757,
        "Institution_Name": "VALLEY VIEW UNIVERSITY"
    },
    {
        "Institution_Code": 2018201234758,
        "Institution_Name": "GHANA WATER -C/R"
    },
    {
        "Institution_Code": 2018201234759,
        "Institution_Name": "INTEGRATED TAMALE FRUIT"
    },
    {
        "Institution_Code": 2018201234760,
        "Institution_Name": "GHANA WATER - TAMALE"
    },
    {
        "Institution_Code": 2018201234761,
        "Institution_Name": "ALLSHIP LOGISTICS LTD."
    },
    {
        "Institution_Code": 2018201234762,
        "Institution_Name": "GHANA LIBRARY BOARD"
    },
    {
        "Institution_Code": 2018201234763,
        "Institution_Name": "VOLTIC GHANA LTD."
    },
    {
        "Institution_Code": 2018201234764,
        "Institution_Name": "TEMA SALVATION ARMY SCH."
    },
    {
        "Institution_Code": 2018201234765,
        "Institution_Name": "L.P.K. SEC. COMPANY LTD."
    },
    {
        "Institution_Code": 2018201234766,
        "Institution_Name": "KG FURNITURE"
    },
    {
        "Institution_Code": 2018201234767,
        "Institution_Name": "GRACE & GLORY"
    },
    {
        "Institution_Code": 2018201234768,
        "Institution_Name": "GHANA HERITAGE CON. TRUST"
    },
    {
        "Institution_Code": 2018201234779,
        "Institution_Name": "STAR ASSURANCE COMP. LTD."
    },
    {
        "Institution_Code": 2018201234780,
        "Institution_Name": "BUREAU OF NATIONAL INV."
    },
    {
        "Institution_Code": 2018201234717,
        "Institution_Name": "GOLDEN MISSION INT. SCH."
    },
    {
        "Institution_Code": 2018201234718,
        "Institution_Name": "ANGLICAN VOC. TECH. SCH"
    },
    {
        "Institution_Code": 2018201234719,
        "Institution_Name": "OPPORTUNITIES IND. CENTRE"
    },
    {
        "Institution_Code": 2018201234720,
        "Institution_Name": "GHANA ALUMINIUM COMPANY"
    },
    {
        "Institution_Code": 2018201234721,
        "Institution_Name": "FOOD AND DRUGS BOARD"
    },
    {
        "Institution_Code": 2018201234722,
        "Institution_Name": "PANDA INVESTMENTS LIMITED"
    },
    {
        "Institution_Code": 2018201234723,
        "Institution_Name": "G.I.M.P.A"
    },
    {
        "Institution_Code": 2018201234724,
        "Institution_Name": "N.C.C.E"
    },
    {
        "Institution_Code": 2018201234725,
        "Institution_Name": "IMPERIAL PEKING"
    },
    {
        "Institution_Code": 2018201234726,
        "Institution_Name": "C.S.I.R."
    },
    {
        "Institution_Code": 2018201234727,
        "Institution_Name": "ACCRA POLYTECHNIC"
    },
    {
        "Institution_Code": 2018201234728,
        "Institution_Name": "COSMOS SCHOOL LTD"
    },
    {
        "Institution_Code": 2018201234729,
        "Institution_Name": "GLORIOUS KIDS NURSERY"
    },
    {
        "Institution_Code": 2018201234730,
        "Institution_Name": "SOIL RESEARCH INSTITUTE"
    },
    {
        "Institution_Code": 2018201234731,
        "Institution_Name": "L'AINE MAERSK(GH) LTD"
    },
    {
        "Institution_Code": 2018201234732,
        "Institution_Name": "INTERCITY STC COACHES"
    },
    {
        "Institution_Code": 2018201234733,
        "Institution_Name": "GHANA WATER-WA"
    },
    {
        "Institution_Code": 2018201234734,
        "Institution_Name": "UNIVERSITY OF CAPECOAST"
    },
    {
        "Institution_Code": 2018201234735,
        "Institution_Name": "SAVANA AGRIC RESEARCH"
    },
    {
        "Institution_Code": 2018201234736,
        "Institution_Name": "GHANA RAILWAY COMPANY"
    },
    {
        "Institution_Code": 2018201234737,
        "Institution_Name": "GOVT TECHNICAL TRAINING"
    },
    {
        "Institution_Code": 2018201234738,
        "Institution_Name": "SOS GHANA"
    },
    {
        "Institution_Code": 2018201234739,
        "Institution_Name": "NICK TC-SCAN"
    },
    {
        "Institution_Code": 2018201234740,
        "Institution_Name": "GHANA ALUMINIUM PROD. LTD"
    },
    {
        "Institution_Code": 2018201234741,
        "Institution_Name": "CHRISTIAN FAITH CHURCH"
    },
    {
        "Institution_Code": 2018201234742,
        "Institution_Name": "TEMA DEVELOPMENT CORP."
    },
    {
        "Institution_Code": 2018201234743,
        "Institution_Name": "HERITAGE COMM. LTD."
    },
    {
        "Institution_Code": 2018201234744,
        "Institution_Name": "FEDERATED COMMODITIES LTD"
    },
    {
        "Institution_Code": 2018201234745,
        "Institution_Name": "TEMA PARENT ASS. SCHOOL"
    },
    {
        "Institution_Code": 2018201234746,
        "Institution_Name": "TEMA OIL REFINERY"
    },
    {
        "Institution_Code": 2018201234747,
        "Institution_Name": "KINGDOM TRANSPORT SERVICE"
    },
    {
        "Institution_Code": 2018201234748,
        "Institution_Name": "SMOOTH CONSTRUCTION"
    },
    {
        "Institution_Code": 2018201234685,
        "Institution_Name": "DOYMA STEEL COMPLEX"
    },
    {
        "Institution_Code": 2018201234686,
        "Institution_Name": "METRO MASS TRANSIT"
    },
    {
        "Institution_Code": 2018201234687,
        "Institution_Name": "GIERRE WOOD TECHNOLOGY"
    },
    {
        "Institution_Code": 2018201234688,
        "Institution_Name": "PARAMOUNT DISTILL. LTD"
    },
    {
        "Institution_Code": 2018201234689,
        "Institution_Name": "G. P. R. T .U"
    },
    {
        "Institution_Code": 2018201234690,
        "Institution_Name": "COCA COLA BOTTLING COM."
    },
    {
        "Institution_Code": 2018201234691,
        "Institution_Name": "AHMADIYA HOSPITAL"
    },
    {
        "Institution_Code": 2018201234692,
        "Institution_Name": "LUCAF INDUSTERIES LTD"
    },
    {
        "Institution_Code": 2018201234693,
        "Institution_Name": "FABI TIMBER LIMITED"
    },
    {
        "Institution_Code": 2018201234694,
        "Institution_Name": "UNIPRESS"
    },
    {
        "Institution_Code": 2018201234695,
        "Institution_Name": "INST OF BUS. MGT & JOURN."
    },
    {
        "Institution_Code": 2018201234696,
        "Institution_Name": "OSONS CHEMISTS"
    },
    {
        "Institution_Code": 2018201234697,
        "Institution_Name": "A. S HALABY COMPANY LTD"
    },
    {
        "Institution_Code": 2018201234698,
        "Institution_Name": "LIGHT FM"
    },
    {
        "Institution_Code": 2018201234699,
        "Institution_Name": "NAGIES ANGELS EDU CENTER"
    },
    {
        "Institution_Code": 2018201234700,
        "Institution_Name": "SCHOOL FOR LIFE"
    },
    {
        "Institution_Code": 2018201234701,
        "Institution_Name": "CEDI MICRO FINANCE"
    },
    {
        "Institution_Code": 2018201234702,
        "Institution_Name": "ELECTRICITY COMPANY OF GHANA"
    },
    {
        "Institution_Code": 2018201234703,
        "Institution_Name": "NAT. COMM. FOR CIVIC EDU."
    },
    {
        "Institution_Code": 2018201234704,
        "Institution_Name": "ALPHA MEDICAL CENTRE"
    },
    {
        "Institution_Code": 2018201234705,
        "Institution_Name": "NATIONAL INSURANCE COMM."
    },
    {
        "Institution_Code": 2018201234706,
        "Institution_Name": "STARLIFE ASSURANCE CO."
    },
    {
        "Institution_Code": 2018201234707,
        "Institution_Name": "NEW TIMES CORPORATION"
    },
    {
        "Institution_Code": 2018201234708,
        "Institution_Name": "STANDARD CHARTERD BANK"
    },
    {
        "Institution_Code": 2018201234709,
        "Institution_Name": "BASEL CLINIC"
    },
    {
        "Institution_Code": 2018201234710,
        "Institution_Name": "FARMAPINE GHANA LIMITED"
    },
    {
        "Institution_Code": 2018201234711,
        "Institution_Name": "PRODUCE BUYING COMPANY"
    },
    {
        "Institution_Code": 2018201234712,
        "Institution_Name": "BERNARD CLIENTS"
    },
    {
        "Institution_Code": 2018201234713,
        "Institution_Name": "CEDECOM"
    },
    {
        "Institution_Code": 2018201234714,
        "Institution_Name": "COMM ON H/RIGHTS & ADMIN."
    },
    {
        "Institution_Code": 2018201234715,
        "Institution_Name": "GHANA WATER-ACCRA WEST"
    },
    {
        "Institution_Code": 2018201234716,
        "Institution_Name": "GHANA WATER-ACCRA NORTH"
    },
    {
        "Institution_Code": 2018201234653,
        "Institution_Name": "FRANK SECURITIES SERVICES"
    },
    {
        "Institution_Code": 2018201234654,
        "Institution_Name": "DELTA SECURITY"
    },
    {
        "Institution_Code": 2018201234655,
        "Institution_Name": "ICECOOL PURE WATER COM"
    },
    {
        "Institution_Code": 2018201234656,
        "Institution_Name": "GEORGE GUNN INT."
    },
    {
        "Institution_Code": 2018201234657,
        "Institution_Name": "AFROTROPIC COCOA PROC."
    },
    {
        "Institution_Code": 2018201234658,
        "Institution_Name": "PAPER CONTAINERS"
    },
    {
        "Institution_Code": 2018201234659,
        "Institution_Name": "BRIANS TOYOTA MOTORS"
    },
    {
        "Institution_Code": 2018201234660,
        "Institution_Name": "N.A.D.M.O"
    },
    {
        "Institution_Code": 2018201234661,
        "Institution_Name": "MONDIAL VENEER LTD"
    },
    {
        "Institution_Code": 2018201234662,
        "Institution_Name": "KANSCO CONSTRUCTION"
    },
    {
        "Institution_Code": 2018201234663,
        "Institution_Name": "HEIRS EVANGELISTIC"
    },
    {
        "Institution_Code": 2018201234664,
        "Institution_Name": "GHANA WATER COMP-SUNYANI"
    },
    {
        "Institution_Code": 2018201234665,
        "Institution_Name": "GHANA ATOMIC ENERGY"
    },
    {
        "Institution_Code": 2018201234666,
        "Institution_Name": "GHANA WATER COMP. -TDI"
    },
    {
        "Institution_Code": 2018201234667,
        "Institution_Name": "PINORA COMPANY LIMITED"
    },
    {
        "Institution_Code": 2018201234668,
        "Institution_Name": "HOPE PREPRATORY SCHOOL"
    },
    {
        "Institution_Code": 2018201234669,
        "Institution_Name": "GOOD SHEPHERD INT SCHOOL"
    },
    {
        "Institution_Code": 2018201234670,
        "Institution_Name": "WA POLYTECNIC"
    },
    {
        "Institution_Code": 2018201234671,
        "Institution_Name": "PENKENDON WOOD PROD. LTD."
    },
    {
        "Institution_Code": 2018201234672,
        "Institution_Name": "WESTEC SECURITY"
    },
    {
        "Institution_Code": 2018201234673,
        "Institution_Name": "BY HIS GRACE BUS. CENTRE"
    },
    {
        "Institution_Code": 2018201234674,
        "Institution_Name": "COCONUT RESERACH PROG."
    },
    {
        "Institution_Code": 2018201234675,
        "Institution_Name": "I N R E S"
    },
    {
        "Institution_Code": 2018201234676,
        "Institution_Name": "UNIPRICISION CO. LTD."
    },
    {
        "Institution_Code": 2018201234677,
        "Institution_Name": "GPRTU"
    },
    {
        "Institution_Code": 2018201234678,
        "Institution_Name": "NAACHIAA CO. LTD"
    },
    {
        "Institution_Code": 2018201234679,
        "Institution_Name": "KUMASI POLYTECHNIC"
    },
    {
        "Institution_Code": 2018201234680,
        "Institution_Name": "KWASI OPPONG CO. LTD."
    },
    {
        "Institution_Code": 2018201234681,
        "Institution_Name": "GHANA WATER CO. LTD -K'SI"
    },
    {
        "Institution_Code": 2018201234682,
        "Institution_Name": "GHANA WATER CO. LTD -HO"
    },
    {
        "Institution_Code": 2018201234683,
        "Institution_Name": "ZOOMLION GHANA LIMITED"
    },
    {
        "Institution_Code": 2018201234684,
        "Institution_Name": "MEDILAB"
    },
    {
        "Institution_Code": 2018201234621,
        "Institution_Name": "PEKENDON WOOD PRODUCT"
    },
    {
        "Institution_Code": 2018201234622,
        "Institution_Name": "G.T.P"
    },
    {
        "Institution_Code": 2018201234623,
        "Institution_Name": "GHANA MANGANESE COMPANY"
    },
    {
        "Institution_Code": 2018201234624,
        "Institution_Name": "APOSTOLIC ACADEMY SCHOOL"
    },
    {
        "Institution_Code": 2018201234625,
        "Institution_Name": "RAPID JUNIOR HIGH SCHOOL"
    },
    {
        "Institution_Code": 2018201234626,
        "Institution_Name": "ADVENTIST PREP. SCHOOL"
    },
    {
        "Institution_Code": 2018201234627,
        "Institution_Name": "GHANA BROADCASTING CORP."
    },
    {
        "Institution_Code": 2018201234628,
        "Institution_Name": "FORESTRY SERVICES DIV."
    },
    {
        "Institution_Code": 2018201234629,
        "Institution_Name": "BENSO OIL PALM PLANTATION"
    },
    {
        "Institution_Code": 2018201234630,
        "Institution_Name": "ACCRA METRO. ASSEMBLY"
    },
    {
        "Institution_Code": 2018201234631,
        "Institution_Name": "INSTITUTE OF PROF. SERV."
    },
    {
        "Institution_Code": 2018201234632,
        "Institution_Name": "KOFORIDUA POLYTECHNIC"
    },
    {
        "Institution_Code": 2018201234633,
        "Institution_Name": "BONDPLEX"
    },
    {
        "Institution_Code": 2018201234634,
        "Institution_Name": "APPOINTED TIME SRN PRINTN"
    },
    {
        "Institution_Code": 2018201234635,
        "Institution_Name": "CARMEUSE LIME"
    },
    {
        "Institution_Code": 2018201234636,
        "Institution_Name": "KUAPA KOKOO CO. LTD."
    },
    {
        "Institution_Code": 2018201234637,
        "Institution_Name": "NORPALM GHANA LTD."
    },
    {
        "Institution_Code": 2018201234638,
        "Institution_Name": "SINAPI ABA TRUST"
    },
    {
        "Institution_Code": 2018201234639,
        "Institution_Name": "COCOA PROCESSING CO. LTD."
    },
    {
        "Institution_Code": 2018201234640,
        "Institution_Name": "UNIVERSITY OF DEVT STDS."
    },
    {
        "Institution_Code": 2018201234641,
        "Institution_Name": "FOUNTAIN VIEW LTD"
    },
    {
        "Institution_Code": 2018201234642,
        "Institution_Name": "UNIVERSITY OF EDUCATION"
    },
    {
        "Institution_Code": 2018201234643,
        "Institution_Name": "MART ACADEMY"
    },
    {
        "Institution_Code": 2018201234644,
        "Institution_Name": "INTEGRAL FREIGHT SERVICES"
    },
    {
        "Institution_Code": 2018201234645,
        "Institution_Name": "PRIME WOOD CO. LTD."
    },
    {
        "Institution_Code": 2018201234646,
        "Institution_Name": "SRG"
    },
    {
        "Institution_Code": 2018201234647,
        "Institution_Name": "VAT SERVICE"
    },
    {
        "Institution_Code": 2018201234648,
        "Institution_Name": "ADVENT REFORM"
    },
    {
        "Institution_Code": 2018201234649,
        "Institution_Name": "UNICREDIT GHANA LTD"
    },
    {
        "Institution_Code": 2018201234650,
        "Institution_Name": "GOLDFIELDS GHANA LIMITED"
    },
    {
        "Institution_Code": 2018201234651,
        "Institution_Name": "ST MARKS HOSPITAL"
    },
    {
        "Institution_Code": 2018201234652,
        "Institution_Name": "GHANA PRIMEWOOD PRODUCTS"
    },
    {
        "Institution_Code": 2018201234589,
        "Institution_Name": "GHANA TELECOM"
    },
    {
        "Institution_Code": 2018201234590,
        "Institution_Name": "MIRACLE PREP SCHOOL"
    },
    {
        "Institution_Code": 2018201234591,
        "Institution_Name": "JEI RIVER FARMS LTD"
    },
    {
        "Institution_Code": 2018201234592,
        "Institution_Name": "HOLY CHILD ACADEMY"
    },
    {
        "Institution_Code": 2018201234593,
        "Institution_Name": "C.R.I-FUMESUA"
    },
    {
        "Institution_Code": 2018201234594,
        "Institution_Name": "FORESTRY SERV. DEPARTMENT"
    },
    {
        "Institution_Code": 2018201234595,
        "Institution_Name": "LIBERTY WOODWORKS"
    },
    {
        "Institution_Code": 2018201234596,
        "Institution_Name": "LAWRENCE DEMONST. SCH"
    },
    {
        "Institution_Code": 2018201234597,
        "Institution_Name": "GUARANTEE LIFELINE SYS."
    },
    {
        "Institution_Code": 2018201234598,
        "Institution_Name": "OLISTAR PREP/J.H SCHOOL"
    },
    {
        "Institution_Code": 2018201234599,
        "Institution_Name": "BATOR CATHOLIC HOSPITAL"
    },
    {
        "Institution_Code": 2018201234600,
        "Institution_Name": "SUNYANI POLYTHECNIC"
    },
    {
        "Institution_Code": 2018201234601,
        "Institution_Name": "MELODY HOTEL"
    },
    {
        "Institution_Code": 2018201234602,
        "Institution_Name": "A.B.F -TAMALE"
    },
    {
        "Institution_Code": 2018201234603,
        "Institution_Name": "WEST AFRICAN DECOR TILES"
    },
    {
        "Institution_Code": 2018201234604,
        "Institution_Name": "VALLEY BEACH HOTEL"
    },
    {
        "Institution_Code": 2018201234605,
        "Institution_Name": "CHRIST INT. PREP. SCH"
    },
    {
        "Institution_Code": 2018201234606,
        "Institution_Name": "OSFORD BUSINESS SEN HIGH"
    },
    {
        "Institution_Code": 2018201234607,
        "Institution_Name": "GOOD FOUNDATION ACADEMY"
    },
    {
        "Institution_Code": 2018201234608,
        "Institution_Name": "P-A CAPITAL SCHOOL"
    },
    {
        "Institution_Code": 2018201234609,
        "Institution_Name": "EDWIN WOOD PROC. CO. LTD"
    },
    {
        "Institution_Code": 2018201234610,
        "Institution_Name": "FIRST STAR PREP SCH"
    },
    {
        "Institution_Code": 2018201234611,
        "Institution_Name": "PENTECOST PREPARATORY SCH"
    },
    {
        "Institution_Code": 2018201234612,
        "Institution_Name": "PROMISE PREPARATORY SCH."
    },
    {
        "Institution_Code": 2018201234613,
        "Institution_Name": "F.N.S"
    },
    {
        "Institution_Code": 2018201234614,
        "Institution_Name": "KWADWO ASANTE QUARRY LTD."
    },
    {
        "Institution_Code": 2018201234615,
        "Institution_Name": "AYUM FOREST PRODUCT LTD"
    },
    {
        "Institution_Code": 2018201234616,
        "Institution_Name": "BENETH SCHOOL"
    },
    {
        "Institution_Code": 2018201234617,
        "Institution_Name": "JUNGHUNG QUARRY"
    },
    {
        "Institution_Code": 2018201234618,
        "Institution_Name": "QUEEN OF PEACE"
    },
    {
        "Institution_Code": 2018201234619,
        "Institution_Name": "MUMMY'S DARL EDUC. CEN."
    },
    {
        "Institution_Code": 2018201234620,
        "Institution_Name": "YEDEN SECURITY SERVICES"
    },
    {
        "Institution_Code": 2018201234557,
        "Institution_Name": "RHEMA ASSEMBLIES OF GOD,"
    },
    {
        "Institution_Code": 2018201234558,
        "Institution_Name": "SURE WORD ASSEMBLIES OF G"
    },
    {
        "Institution_Code": 2018201234559,
        "Institution_Name": "GETHSEMANE WORSHIP CENTRE"
    },
    {
        "Institution_Code": 2018201234560,
        "Institution_Name": "KOTOBABI DIVINE HEALERS"
    },
    {
        "Institution_Code": 2018201234561,
        "Institution_Name": "CHURCH OF PENTE-CHAPEL SQ"
    },
    {
        "Institution_Code": 2018201234562,
        "Institution_Name": "AFRICA COLA COMPANY LTD"
    },
    {
        "Institution_Code": 2018201234563,
        "Institution_Name": "CHURCH OF PENT-ABEHENEASE"
    },
    {
        "Institution_Code": 2018201234564,
        "Institution_Name": "CHURCH OF PENT-AKOTOSHIE2"
    },
    {
        "Institution_Code": 2018201234565,
        "Institution_Name": "TWEAPIASE APOSTOLIC CHURC"
    },
    {
        "Institution_Code": 2018201234566,
        "Institution_Name": "APOSTOLIC CHURCH-MAMOBI A"
    },
    {
        "Institution_Code": 2018201234567,
        "Institution_Name": "RESCUE THE PERISHING CHRI"
    },
    {
        "Institution_Code": 2018201234568,
        "Institution_Name": "CAASIMEN COMPANY LIMITED"
    },
    {
        "Institution_Code": 2018201234569,
        "Institution_Name": "GHANA TELECOM -HO"
    },
    {
        "Institution_Code": 2018201234570,
        "Institution_Name": "CALTECH VENTURES"
    },
    {
        "Institution_Code": 2018201234571,
        "Institution_Name": "EDEN SECURITY"
    },
    {
        "Institution_Code": 2018201234572,
        "Institution_Name": "BOSBELL COMPANY"
    },
    {
        "Institution_Code": 2018201234573,
        "Institution_Name": "KOMFO ANOKYE HOSPITAL"
    },
    {
        "Institution_Code": 2018201234574,
        "Institution_Name": "KOMFO ANOKYE CATERIN DEPT"
    },
    {
        "Institution_Code": 2018201234575,
        "Institution_Name": "BEVERAGE INVESTMT.GH. LTD"
    },
    {
        "Institution_Code": 2018201234576,
        "Institution_Name": "PEPS-C"
    },
    {
        "Institution_Code": 2018201234577,
        "Institution_Name": "N. B. S. S. I"
    },
    {
        "Institution_Code": 2018201234578,
        "Institution_Name": "VILLAGE WATER RESEVIOUR"
    },
    {
        "Institution_Code": 2018201234579,
        "Institution_Name": "INTEGRATED VOC. TRAIN."
    },
    {
        "Institution_Code": 2018201234580,
        "Institution_Name": "OTI-YEBOAH COMPLEX LTD."
    },
    {
        "Institution_Code": 2018201234581,
        "Institution_Name": "YEDENT PROCESSING VENT."
    },
    {
        "Institution_Code": 2018201234582,
        "Institution_Name": "JEI-KODUA HEALTH SERVICE"
    },
    {
        "Institution_Code": 2018201234583,
        "Institution_Name": "NEXANS KABELMETAL"
    },
    {
        "Institution_Code": 2018201234584,
        "Institution_Name": "SUNYANI POLYTECHNIC"
    },
    {
        "Institution_Code": 2018201234585,
        "Institution_Name": "CHRIS ACADEMY"
    },
    {
        "Institution_Code": 2018201234586,
        "Institution_Name": "AMAZING LOVE SCHOOL"
    },
    {
        "Institution_Code": 2018201234587,
        "Institution_Name": "ULTITRUST SAV & CRE UN."
    },
    {
        "Institution_Code": 2018201234588,
        "Institution_Name": "SWAN CLINIC"
    },
    {
        "Institution_Code": 2018201234525,
        "Institution_Name": "BUS. AND FINANCIAL TIMES"
    },
    {
        "Institution_Code": 2018201234526,
        "Institution_Name": "JEWELS OF THE MILLENIUM"
    },
    {
        "Institution_Code": 2018201234527,
        "Institution_Name": "ASUOGYAMAN RURAL BANK"
    },
    {
        "Institution_Code": 2018201234529,
        "Institution_Name": "GHANA WATER CO. LTD-K'DUA"
    },
    {
        "Institution_Code": 2018201234530,
        "Institution_Name": "ANUM RURAL BANK"
    },
    {
        "Institution_Code": 2018201234531,
        "Institution_Name": "EDGRACE RECYCLING"
    },
    {
        "Institution_Code": 2018201234532,
        "Institution_Name": "GHANA HOME LOANS"
    },
    {
        "Institution_Code": 2018201234533,
        "Institution_Name": "GROFIN AFRICA FUND"
    },
    {
        "Institution_Code": 2018201234534,
        "Institution_Name": "PACIFIC SAVINGS AND LOANS"
    },
    {
        "Institution_Code": 2018201234535,
        "Institution_Name": "P.S.C. TEMA SHIPYARD LTD"
    },
    {
        "Institution_Code": 2018201234536,
        "Institution_Name": "IPMC SCHOOL"
    },
    {
        "Institution_Code": 2018201234537,
        "Institution_Name": "NELL STEEL COMPANY LTD"
    },
    {
        "Institution_Code": 2018201234538,
        "Institution_Name": "GOLDEN GATES SERV. LTD"
    },
    {
        "Institution_Code": 2018201234539,
        "Institution_Name": "GHANA-RE COMPANY LTD"
    },
    {
        "Institution_Code": 2018201234540,
        "Institution_Name": "HOLIDAY INN HOTEL"
    },
    {
        "Institution_Code": 2018201234541,
        "Institution_Name": "BUI INVESTMENTS LTD."
    },
    {
        "Institution_Code": 2018201234542,
        "Institution_Name": "REVIVAL ASSEMBLIES OF GOD,AHENTIA"
    },
    {
        "Institution_Code": 2018201234543,
        "Institution_Name": "TAC,NO.4 ASSEMBLY, TESHIE TSUIBLEOO"
    },
    {
        "Institution_Code": 2018201234544,
        "Institution_Name": "THE ORIGINAL WORD MINISTRY"
    },
    {
        "Institution_Code": 2018201234545,
        "Institution_Name": "CHURCH OF PENTECOST, MAYERA ASSEMBLY"
    },
    {
        "Institution_Code": 2018201234546,
        "Institution_Name": "CHURCH OF PENTECOST, ACP POKUASE"
    },
    {
        "Institution_Code": 2018201234547,
        "Institution_Name": "CHRISTIAN GENERATION CHURCH, OFANKOR"
    },
    {
        "Institution_Code": 2018201234548,
        "Institution_Name": "MOUNT CALVARY CROSS MINISTRY, OSU"
    },
    {
        "Institution_Code": 2018201234549,
        "Institution_Name": "LITTLE ROCK ACADEMY, KASOA"
    },
    {
        "Institution_Code": 2018201234550,
        "Institution_Name": "THE RADIANT CHAPEL INTERNATIONAL"
    },
    {
        "Institution_Code": 2018201234551,
        "Institution_Name": "ASSEMBLIES OF GOD, ADUSAH"
    },
    {
        "Institution_Code": 2018201234552,
        "Institution_Name": "CHRIST EVANGELICAL MISSION"
    },
    {
        "Institution_Code": 2018201234553,
        "Institution_Name": "S.D.A. NORTH KANESHIE"
    },
    {
        "Institution_Code": 2018201234554,
        "Institution_Name": "CALVARY REDEMPTION AND POWER MINISTR"
    },
    {
        "Institution_Code": 2018201234555,
        "Institution_Name": "OVERCOMERS FAITH MIN., T-"
    },
    {
        "Institution_Code": 2018201234556,
        "Institution_Name": "S.D.A. MAMOBI"
    },
    {
        "Institution_Code": 2018201234493,
        "Institution_Name": "CALVARY LOVE ASSEMBLIES OF GOD CHURC"
    },
    {
        "Institution_Code": 2018201234494,
        "Institution_Name": "CHARLES WESLEY METHODIST CHURCH"
    },
    {
        "Institution_Code": 2018201234495,
        "Institution_Name": "CHRIST APOSTOLIC CHURCH INT"
    },
    {
        "Institution_Code": 2018201234496,
        "Institution_Name": "CHRIST CASTLE INT CHURCH"
    },
    {
        "Institution_Code": 2018201234497,
        "Institution_Name": "CHRISTIAN DIVINE CHRCH"
    },
    {
        "Institution_Code": 2018201234498,
        "Institution_Name": "CHRISTIAN HOPE MINISTRY"
    },
    {
        "Institution_Code": 2018201234499,
        "Institution_Name": "CHURCH OF HEALING HAND"
    },
    {
        "Institution_Code": 2018201234500,
        "Institution_Name": "CHURCH OF PENTECOST NANANOM DISTRICT"
    },
    {
        "Institution_Code": 2018201234501,
        "Institution_Name": "NAGGESTEN COMPANY LIMITED"
    },
    {
        "Institution_Code": 2018201234502,
        "Institution_Name": "TRINITY TEMPLE ASS."
    },
    {
        "Institution_Code": 2018201234503,
        "Institution_Name": "EMBROSCO SECURITY"
    },
    {
        "Institution_Code": 2018201234504,
        "Institution_Name": "SONG-ZEL"
    },
    {
        "Institution_Code": 2018201234505,
        "Institution_Name": "BLUE ROSE CONST. LTD."
    },
    {
        "Institution_Code": 2018201234506,
        "Institution_Name": "ACCRA MACHINE SHOP"
    },
    {
        "Institution_Code": 2018201234507,
        "Institution_Name": "HO POLYTECHNIC"
    },
    {
        "Institution_Code": 2018201234508,
        "Institution_Name": "ACTION SECONDARY SCHOOL"
    },
    {
        "Institution_Code": 2018201234509,
        "Institution_Name": "GHANA RUBBER EST. LIMITED"
    },
    {
        "Institution_Code": 2018201234510,
        "Institution_Name": "HOMMEY'S PALACE"
    },
    {
        "Institution_Code": 2018201234511,
        "Institution_Name": "G.P.D.P"
    },
    {
        "Institution_Code": 2018201234512,
        "Institution_Name": "ANITA HOTEL LTD"
    },
    {
        "Institution_Code": 2018201234513,
        "Institution_Name": "CALVARY PRESBY COMPLEX"
    },
    {
        "Institution_Code": 2018201234514,
        "Institution_Name": "BEN-KAY LIMITED"
    },
    {
        "Institution_Code": 2018201234515,
        "Institution_Name": "ASAQUAH MOTORS"
    },
    {
        "Institution_Code": 2018201234516,
        "Institution_Name": "GOLDEN GATE HOTEL"
    },
    {
        "Institution_Code": 2018201234517,
        "Institution_Name": "GRACE BAPTIST SCHOOL"
    },
    {
        "Institution_Code": 2018201234518,
        "Institution_Name": "JUABENG RURAL BANK"
    },
    {
        "Institution_Code": 2018201234519,
        "Institution_Name": "ABORS INVESTMENTS LTD."
    },
    {
        "Institution_Code": 2018201234520,
        "Institution_Name": "GROFIN AFRICA FUND"
    },
    {
        "Institution_Code": 2018201234521,
        "Institution_Name": "TOP CLASS EDUCATION CEN."
    },
    {
        "Institution_Code": 2018201234522,
        "Institution_Name": "UNION SAVINGS AND LOANS"
    },
    {
        "Institution_Code": 2018201234523,
        "Institution_Name": "STAR MICRO INSURANCE"
    },
    {
        "Institution_Code": 2018201234524,
        "Institution_Name": "GHANA MEDICAL SCH. CDT UN"
    },
    {
        "Institution_Code": 2018201234461,
        "Institution_Name": "L'AINE SERVICES CO. LTD"
    },
    {
        "Institution_Code": 2018201234462,
        "Institution_Name": "LIFE FORMS CO. LTD"
    },
    {
        "Institution_Code": 2018201234463,
        "Institution_Name": "NASONA OIL COMPANY"
    },
    {
        "Institution_Code": 2018201234464,
        "Institution_Name": "PHOENIX LIFE ASSURANCE"
    },
    {
        "Institution_Code": 2018201234465,
        "Institution_Name": "PKF SCIENTIFIC"
    },
    {
        "Institution_Code": 2018201234466,
        "Institution_Name": "PRIME INSURANCE CO. LTD"
    },
    {
        "Institution_Code": 2018201234467,
        "Institution_Name": "WORLD WIDE LOGISTICS-TEMA"
    },
    {
        "Institution_Code": 2018201234468,
        "Institution_Name": "SSNIT- ACCRA"
    },
    {
        "Institution_Code": 2018201234469,
        "Institution_Name": "CROWNHOUSE MICROFINANCE"
    },
    {
        "Institution_Code": 2018201234470,
        "Institution_Name": "SDC FINANCE"
    },
    {
        "Institution_Code": 2018201234471,
        "Institution_Name": "CONNECT CAPITAL"
    },
    {
        "Institution_Code": 2018201234472,
        "Institution_Name": "BEIGE CAPITAL"
    },
    {
        "Institution_Code": 2018201234473,
        "Institution_Name": "ALBAN LOGISTICS"
    },
    {
        "Institution_Code": 2018201234474,
        "Institution_Name": "MEKO INVESTMENT CO. LTD"
    },
    {
        "Institution_Code": 2018201234475,
        "Institution_Name": "GOLDEN STATE ACADEMY SCH."
    },
    {
        "Institution_Code": 2018201234476,
        "Institution_Name": "GH. CIVIL AVIATION AUTH."
    },
    {
        "Institution_Code": 2018201234477,
        "Institution_Name": "ABODOM/BOBIKUMA ASSEMBLIES"
    },
    {
        "Institution_Code": 2018201234478,
        "Institution_Name": "ACCRA REGIONAL DAY CARE CENTRES ASS."
    },
    {
        "Institution_Code": 2018201234479,
        "Institution_Name": "ACTIVE FAITH ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234480,
        "Institution_Name": "AFORVE FAMILY"
    },
    {
        "Institution_Code": 2018201234481,
        "Institution_Name": "ALIGA INTERNATIONAL DEVELOPMENT NETW"
    },
    {
        "Institution_Code": 2018201234482,
        "Institution_Name": "AMAZING GRACE ASSEMBLIES OF GOD CHUR"
    },
    {
        "Institution_Code": 2018201234483,
        "Institution_Name": "AMEDZO FAMILY"
    },
    {
        "Institution_Code": 2018201234484,
        "Institution_Name": "ASS.METHODIST MEN FELLOWSHIP"
    },
    {
        "Institution_Code": 2018201234485,
        "Institution_Name": "ASSOCIATION OF CARE GIVERS IN BUSINE"
    },
    {
        "Institution_Code": 2018201234486,
        "Institution_Name": "AWO-NKPA ATSWEI WEKU KPEE"
    },
    {
        "Institution_Code": 2018201234487,
        "Institution_Name": "AYIKPA FAMILY"
    },
    {
        "Institution_Code": 2018201234488,
        "Institution_Name": "BELIEVERS BIBLE CHRISTIAN CHURCH"
    },
    {
        "Institution_Code": 2018201234489,
        "Institution_Name": "BETHEL METHODIST SOCIETY,TAIFA"
    },
    {
        "Institution_Code": 2018201234490,
        "Institution_Name": "BLESSED GENERATION ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234491,
        "Institution_Name": "BOAHEN FAMILY"
    },
    {
        "Institution_Code": 2018201234492,
        "Institution_Name": "CALVARY CRYSTAL CHURCH WELFARE"
    },
    {
        "Institution_Code": 2018201234429,
        "Institution_Name": "SOUL REDEEMING CENTRE ASSEMBLIES OF"
    },
    {
        "Institution_Code": 2018201234430,
        "Institution_Name": "ST.PETERS METHODIST CHURCH"
    },
    {
        "Institution_Code": 2018201234431,
        "Institution_Name": "THE CHURCH OF PENTECOST, EDUMASE"
    },
    {
        "Institution_Code": 2018201234432,
        "Institution_Name": "THE GLORY ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234433,
        "Institution_Name": "TONGU DZIGBORDI HABORBOR"
    },
    {
        "Institution_Code": 2018201234434,
        "Institution_Name": "JUBILEE AG, OBOM II"
    },
    {
        "Institution_Code": 2018201234435,
        "Institution_Name": "VICTORY ARENA, AG"
    },
    {
        "Institution_Code": 2018201234436,
        "Institution_Name": "FISH POND DEV'MENT ASS."
    },
    {
        "Institution_Code": 2018201234437,
        "Institution_Name": "THE APOSTOLIC CH,ACHIAMAN"
    },
    {
        "Institution_Code": 2018201234438,
        "Institution_Name": "TRUE VINE ASSEM. OF GOD"
    },
    {
        "Institution_Code": 2018201234439,
        "Institution_Name": "PREMIER BETTING-SOLIDERE"
    },
    {
        "Institution_Code": 2018201234440,
        "Institution_Name": "VICTORY BIBLE CHURCH INT."
    },
    {
        "Institution_Code": 2018201234441,
        "Institution_Name": "KRISTO ASAFO MISN.NEWTOWN"
    },
    {
        "Institution_Code": 2018201234442,
        "Institution_Name": "INSPIRATION ASSEMB OF GOD"
    },
    {
        "Institution_Code": 2018201234443,
        "Institution_Name": "GOSPEL LIGHT ASSEM OF GOD"
    },
    {
        "Institution_Code": 2018201234444,
        "Institution_Name": "CIC INVESTMENT"
    },
    {
        "Institution_Code": 2018201234445,
        "Institution_Name": "CAPITAL BANK"
    },
    {
        "Institution_Code": 2018201234446,
        "Institution_Name": "ICB"
    },
    {
        "Institution_Code": 2018201234447,
        "Institution_Name": "NATIONWIDE MICROFINANCE"
    },
    {
        "Institution_Code": 2018201234448,
        "Institution_Name": "INT. NEEDS NETWORK"
    },
    {
        "Institution_Code": 2018201234449,
        "Institution_Name": "PWF MONEY LENDING"
    },
    {
        "Institution_Code": 2018201234450,
        "Institution_Name": "SILBANC CAPITAL"
    },
    {
        "Institution_Code": 2018201234451,
        "Institution_Name": "S.D.A. MADINA CENTRAL"
    },
    {
        "Institution_Code": 2018201234452,
        "Institution_Name": "FAITH COMMUNITY CH, LA"
    },
    {
        "Institution_Code": 2018201234453,
        "Institution_Name": "AKOTO RISK"
    },
    {
        "Institution_Code": 2018201234454,
        "Institution_Name": "MARKET & SOC. RESEARCH"
    },
    {
        "Institution_Code": 2018201234455,
        "Institution_Name": "ENTERPRISE COMPUTING"
    },
    {
        "Institution_Code": 2018201234456,
        "Institution_Name": "P.C.G. PRAMPRAM"
    },
    {
        "Institution_Code": 2018201234457,
        "Institution_Name": "IDE GHANA LTD"
    },
    {
        "Institution_Code": 2018201234458,
        "Institution_Name": "IMPERIAL ASSURANCE"
    },
    {
        "Institution_Code": 2018201234459,
        "Institution_Name": "JELCEM CO. LTD"
    },
    {
        "Institution_Code": 2018201234460,
        "Institution_Name": "KF'94 CO. LTD"
    },
    {
        "Institution_Code": 2018201234397,
        "Institution_Name": "KORMANTSE HOPE ASSMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234398,
        "Institution_Name": "LIBERATION WORSHIP CENTRE"
    },
    {
        "Institution_Code": 2018201234399,
        "Institution_Name": "LIFE GATE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234400,
        "Institution_Name": "LOVE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234401,
        "Institution_Name": "LOVE COMMUNITY ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234402,
        "Institution_Name": "MACEDONIA WORSHIP CENTRE"
    },
    {
        "Institution_Code": 2018201234403,
        "Institution_Name": "MARANATHA METHODIST CHURCH"
    },
    {
        "Institution_Code": 2018201234404,
        "Institution_Name": "MERIDIAN LADIES ASSOCIATION"
    },
    {
        "Institution_Code": 2018201234405,
        "Institution_Name": "MT. CALVARY CROSS WOMEN FELLOWSHIP"
    },
    {
        "Institution_Code": 2018201234406,
        "Institution_Name": "MT.CALVARY CROSS MINISTRY MEN FELLOW"
    },
    {
        "Institution_Code": 2018201234407,
        "Institution_Name": "NII DOKU WE"
    },
    {
        "Institution_Code": 2018201234408,
        "Institution_Name": "NYAME YE ODO SOCIETY"
    },
    {
        "Institution_Code": 2018201234409,
        "Institution_Name": "OPEN DOOR ASSEMBLIES OF GOD CHURCH"
    },
    {
        "Institution_Code": 2018201234410,
        "Institution_Name": "PARADISE ASSEMBLIES OF GOD CHURCH"
    },
    {
        "Institution_Code": 2018201234411,
        "Institution_Name": "PEACE ASSEMBLIES OF GOD WELFARE"
    },
    {
        "Institution_Code": 2018201234412,
        "Institution_Name": "PEACE TEMPLE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234413,
        "Institution_Name": "PENTECOSTAL FIRE MINISTRY"
    },
    {
        "Institution_Code": 2018201234414,
        "Institution_Name": "PENTECOSTAL REVIVAL MINISTRIES INT"
    },
    {
        "Institution_Code": 2018201234415,
        "Institution_Name": "PISTIS PRESBY CHURCH"
    },
    {
        "Institution_Code": 2018201234416,
        "Institution_Name": "POKUASE BAPTIST CHURCH"
    },
    {
        "Institution_Code": 2018201234417,
        "Institution_Name": "POWER CENTRE ASSEMBLIES OF GOD CHURC"
    },
    {
        "Institution_Code": 2018201234418,
        "Institution_Name": "PROGRESSIVE ASSEMBLIES OF GOD CHURCH"
    },
    {
        "Institution_Code": 2018201234419,
        "Institution_Name": "Q-T RESIDENTS' WELFARE ASSOCIATION"
    },
    {
        "Institution_Code": 2018201234420,
        "Institution_Name": "REACH OUT BAPTIST CHURCH"
    },
    {
        "Institution_Code": 2018201234421,
        "Institution_Name": "RESURRECTION HOUR ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234422,
        "Institution_Name": "RHENA ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234423,
        "Institution_Name": "S.D. A CHURCH,OFANKOR (A)"
    },
    {
        "Institution_Code": 2018201234424,
        "Institution_Name": "S.D.A CHURCH - AMAMOLEY"
    },
    {
        "Institution_Code": 2018201234425,
        "Institution_Name": "S.D.A. CHURCH - POKUASE (B)"
    },
    {
        "Institution_Code": 2018201234426,
        "Institution_Name": "S.D.A., ASOFAN"
    },
    {
        "Institution_Code": 2018201234427,
        "Institution_Name": "SALTPOND ASSEMBLIES OF GOD CHURCH"
    },
    {
        "Institution_Code": 2018201234428,
        "Institution_Name": "SALVATION OUTREACH MINISTRY"
    },
    {
        "Institution_Code": 2018201234365,
        "Institution_Name": "THE APOSTOLIC CH, MANHEAN"
    },
    {
        "Institution_Code": 2018201234366,
        "Institution_Name": "NEW LIFE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234367,
        "Institution_Name": "COMMUNITY 5 ZONE 7 WELFARE ASSOCIATI"
    },
    {
        "Institution_Code": 2018201234368,
        "Institution_Name": "COMMUNITY PASTORS ASSOCIATION"
    },
    {
        "Institution_Code": 2018201234369,
        "Institution_Name": "DELIVERANCE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234370,
        "Institution_Name": "DIVINE HEALERS CHURCH"
    },
    {
        "Institution_Code": 2018201234371,
        "Institution_Name": "DZIKUNU FAMILY"
    },
    {
        "Institution_Code": 2018201234372,
        "Institution_Name": "EVANGELICAL ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234373,
        "Institution_Name": "FAITHFULL GOD MINISTRY"
    },
    {
        "Institution_Code": 2018201234374,
        "Institution_Name": "FREEDOM AND JUSTICE CLUB"
    },
    {
        "Institution_Code": 2018201234375,
        "Institution_Name": "G.P.R.T.U. TESHIE"
    },
    {
        "Institution_Code": 2018201234376,
        "Institution_Name": "GHANA SOCIETY FOR PHYSICALLY DISABLE"
    },
    {
        "Institution_Code": 2018201234377,
        "Institution_Name": "GLOBAL EVANGELICAL CHURCH"
    },
    {
        "Institution_Code": 2018201234378,
        "Institution_Name": "GLOBAL REVIVAL MINISTRY"
    },
    {
        "Institution_Code": 2018201234379,
        "Institution_Name": "GODSWILL BIBLE CHURCH"
    },
    {
        "Institution_Code": 2018201234380,
        "Institution_Name": "GOMOA OBUASI ASSEMBLIES OF GOD CHURC"
    },
    {
        "Institution_Code": 2018201234381,
        "Institution_Name": "GOOD SAMARITAN CHURCH"
    },
    {
        "Institution_Code": 2018201234382,
        "Institution_Name": "GOODNEWS FOR THE NATION MINISTRY"
    },
    {
        "Institution_Code": 2018201234383,
        "Institution_Name": "GREATER GRACE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234384,
        "Institution_Name": "HOLY FIRE PROPHETIC MINISTRY"
    },
    {
        "Institution_Code": 2018201234385,
        "Institution_Name": "HOLY GHOST MIRACLE CHURCH INTERNATIO"
    },
    {
        "Institution_Code": 2018201234386,
        "Institution_Name": "HOLY GROUND ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234387,
        "Institution_Name": "HOUSE OF GRACE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234388,
        "Institution_Name": "HOUSE OF GRACE CHURCH WELFARE"
    },
    {
        "Institution_Code": 2018201234389,
        "Institution_Name": "I. C. G. C. (A), SHILOH TEMPLE"
    },
    {
        "Institution_Code": 2018201234390,
        "Institution_Name": "INTERNATIONAL PRAYER PALACE CHURCH"
    },
    {
        "Institution_Code": 2018201234391,
        "Institution_Name": "JEHOVAH NISSI MINISTRY"
    },
    {
        "Institution_Code": 2018201234392,
        "Institution_Name": "JERUSALEM MIRACLE CHURCH"
    },
    {
        "Institution_Code": 2018201234393,
        "Institution_Name": "JESUS CARES CENTRAL CHURCH"
    },
    {
        "Institution_Code": 2018201234394,
        "Institution_Name": "KETU MINISTERS FELLOWSHIP"
    },
    {
        "Institution_Code": 2018201234395,
        "Institution_Name": "CHURCH PLANTERS FELLOWSHIP"
    },
    {
        "Institution_Code": 2018201234396,
        "Institution_Name": "KLU-KLAYE-NUTSUAKOR"
    },
    {
        "Institution_Code": 2018201234333,
        "Institution_Name": "GRIDCO"
    },
    {
        "Institution_Code": 2018201234334,
        "Institution_Name": "MADAMFO GH CHILDREN SHELT"
    },
    {
        "Institution_Code": 2018201234335,
        "Institution_Name": "WA COMMUNITY CREDIT UNION"
    },
    {
        "Institution_Code": 2018201234336,
        "Institution_Name": "TV3"
    },
    {
        "Institution_Code": 2018201234337,
        "Institution_Name": "AMANTIN & KASEI COM BANK"
    },
    {
        "Institution_Code": 2018201234338,
        "Institution_Name": "CRYSTAL LAKE FISH LTD"
    },
    {
        "Institution_Code": 2018201234339,
        "Institution_Name": "GHANA WATER - ACCRA EAST"
    },
    {
        "Institution_Code": 2018201234340,
        "Institution_Name": "EXCELLENCE IN BROADCASTIN"
    },
    {
        "Institution_Code": 2018201234341,
        "Institution_Name": "FUNDAMENTAL BAPTIST CH."
    },
    {
        "Institution_Code": 2018201234342,
        "Institution_Name": "MOUNT CALVARY CROSS, KUMA"
    },
    {
        "Institution_Code": 2018201234343,
        "Institution_Name": "GHANA TEXTILES PRINTING"
    },
    {
        "Institution_Code": 2018201234344,
        "Institution_Name": "SAFEBOND GHANA LTD."
    },
    {
        "Institution_Code": 2018201234345,
        "Institution_Name": "PENT PRIM SCH BUBUASHIE"
    },
    {
        "Institution_Code": 2018201234346,
        "Institution_Name": "KRISTO ASAFO CH - AGOGO"
    },
    {
        "Institution_Code": 2018201234347,
        "Institution_Name": "SONGOR SALT PROJECT, TEMA"
    },
    {
        "Institution_Code": 2018201234348,
        "Institution_Name": "BONZALI RURAL BANK"
    },
    {
        "Institution_Code": 2018201234349,
        "Institution_Name": "KRISTO ASAFO MIS AKRO-EJI"
    },
    {
        "Institution_Code": 2018201234350,
        "Institution_Name": "GH NAT'L DRESSMAKERS ASS"
    },
    {
        "Institution_Code": 2018201234351,
        "Institution_Name": "LIVING ANCHOR CHAPEL"
    },
    {
        "Institution_Code": 2018201234352,
        "Institution_Name": "DRIVER &VEHICLE LICEN AUT"
    },
    {
        "Institution_Code": 2018201234353,
        "Institution_Name": "TISUNGTAABA COMM. BANK"
    },
    {
        "Institution_Code": 2018201234354,
        "Institution_Name": "CHRIST APO CH INT AMPABAM"
    },
    {
        "Institution_Code": 2018201234355,
        "Institution_Name": "GHANA WATER - PRODUCTION"
    },
    {
        "Institution_Code": 2018201234356,
        "Institution_Name": "VOICE OF THE LORD, K'DUA"
    },
    {
        "Institution_Code": 2018201234357,
        "Institution_Name": "VALCO"
    },
    {
        "Institution_Code": 2018201234358,
        "Institution_Name": "WORD REVIVAL AOG"
    },
    {
        "Institution_Code": 2018201234359,
        "Institution_Name": "PCG - EPIPHANY MEN'S FELL"
    },
    {
        "Institution_Code": 2018201234360,
        "Institution_Name": "JESUS LIVETH MINISTRY"
    },
    {
        "Institution_Code": 2018201234361,
        "Institution_Name": "S. D. A. NSAKINA"
    },
    {
        "Institution_Code": 2018201234362,
        "Institution_Name": "S. D. A. ODUMAN"
    },
    {
        "Institution_Code": 2018201234363,
        "Institution_Name": "THE APOSTOLIC CH. ODUMAN"
    },
    {
        "Institution_Code": 2018201234364,
        "Institution_Name": "UPPER ROOM APOSTOLIC FIRE"
    },
    {
        "Institution_Code": 2018201234301,
        "Institution_Name": "UNITY BAPTIST CHURCH"
    },
    {
        "Institution_Code": 2018201234302,
        "Institution_Name": "VALCO PARK LADIES ASSOCIATION"
    },
    {
        "Institution_Code": 2018201234303,
        "Institution_Name": "VICTORY A.G. CHURCH"
    },
    {
        "Institution_Code": 2018201234304,
        "Institution_Name": "VOICE OF THE HOLY SPIRIT MINISTRIES"
    },
    {
        "Institution_Code": 2018201234305,
        "Institution_Name": "VOICE OF THE LORD EVANGELICAL CHURCH"
    },
    {
        "Institution_Code": 2018201234306,
        "Institution_Name": "VOICE OF THE LORD,WEIJA"
    },
    {
        "Institution_Code": 2018201234307,
        "Institution_Name": "WISDOM TREE CHAPEL"
    },
    {
        "Institution_Code": 2018201234308,
        "Institution_Name": "YESU TE ASE ASSOCIATION"
    },
    {
        "Institution_Code": 2018201234309,
        "Institution_Name": "E-TRANSACT GHANA LIMITED"
    },
    {
        "Institution_Code": 2018201234310,
        "Institution_Name": "GRA-VALUE ADDED TAX (VAT)"
    },
    {
        "Institution_Code": 2018201234311,
        "Institution_Name": "CHURCH OF PENTECOST POKUASE CENTRAL"
    },
    {
        "Institution_Code": 2018201234312,
        "Institution_Name": "DADETSUNYA APOSTOLIC CHUR"
    },
    {
        "Institution_Code": 2018201234313,
        "Institution_Name": "SAMLESI APOSTOLIC CHURCH - GHANA"
    },
    {
        "Institution_Code": 2018201234314,
        "Institution_Name": "UNITED PROFESSIONAL PHOTOGRAPHERS AS"
    },
    {
        "Institution_Code": 2018201234315,
        "Institution_Name": "UNIQUE EXECUTIVES ASS, ASHAIMAN NEW"
    },
    {
        "Institution_Code": 2018201234316,
        "Institution_Name": "GRACE PRESBY CHURCH, ASHAIMAN NEW TO"
    },
    {
        "Institution_Code": 2018201234317,
        "Institution_Name": "CHURCH OF CHRIST, ASHAIMAN OFFICIAL"
    },
    {
        "Institution_Code": 2018201234318,
        "Institution_Name": "SDA NORTH / ZENU -ASHAIMAN"
    },
    {
        "Institution_Code": 2018201234319,
        "Institution_Name": "ACTION CHAPEL INT ASHAIMAN CENTRAL"
    },
    {
        "Institution_Code": 2018201234320,
        "Institution_Name": "LIVING STREAMS, TESHIE"
    },
    {
        "Institution_Code": 2018201234321,
        "Institution_Name": "SDA CHURCH, SAKI"
    },
    {
        "Institution_Code": 2018201234322,
        "Institution_Name": "RIVERSIDE RESIDENCE ASS."
    },
    {
        "Institution_Code": 2018201234323,
        "Institution_Name": "GRACE BAPTIST CHURCH,SAKUMONO"
    },
    {
        "Institution_Code": 2018201234324,
        "Institution_Name": "ASOFAN ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234325,
        "Institution_Name": "TAC, ROYAL ASSEMBLY, MAYERA"
    },
    {
        "Institution_Code": 2018201234326,
        "Institution_Name": "SONITRA GHANA LTD."
    },
    {
        "Institution_Code": 2018201234327,
        "Institution_Name": "TAYSEC GROUP LTD"
    },
    {
        "Institution_Code": 2018201234328,
        "Institution_Name": "TRINITY METHODIST WELFARE SCHEME"
    },
    {
        "Institution_Code": 2018201234329,
        "Institution_Name": "NYANKUMASI AHENKRO RURAL"
    },
    {
        "Institution_Code": 2018201234330,
        "Institution_Name": "ODOTOBRI RURAL BANK"
    },
    {
        "Institution_Code": 2018201234331,
        "Institution_Name": "VOLTA STAR TEXTILE"
    },
    {
        "Institution_Code": 2018201234332,
        "Institution_Name": "NATIONAL BANKING COLLEGE"
    },
    {
        "Institution_Code": 2018201234269,
        "Institution_Name": "BREMAN SDA CHURCH, KUMASI"
    },
    {
        "Institution_Code": 2018201234270,
        "Institution_Name": "NEW TOWN DEVINE HEALERS"
    },
    {
        "Institution_Code": 2018201234271,
        "Institution_Name": "KRISTO ASAFO, TAIFA"
    },
    {
        "Institution_Code": 2018201234272,
        "Institution_Name": "CHRIST UNIVERSAL CHURCH,M"
    },
    {
        "Institution_Code": 2018201234273,
        "Institution_Name": "JUBILEE ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234274,
        "Institution_Name": "SCHOOL OF TRADE TRAINING"
    },
    {
        "Institution_Code": 2018201234275,
        "Institution_Name": "SDA MPATASIE CHURCH"
    },
    {
        "Institution_Code": 2018201234276,
        "Institution_Name": "FOREVER YOUNG INT. SCHOOL"
    },
    {
        "Institution_Code": 2018201234277,
        "Institution_Name": "ALDERGATE METHODIST CH."
    },
    {
        "Institution_Code": 2018201234278,
        "Institution_Name": "GOSPEL REVIVAL ASSEMBLIES"
    },
    {
        "Institution_Code": 2018201234279,
        "Institution_Name": "HEBRON ASSEMBLIES OF GOD"
    },
    {
        "Institution_Code": 2018201234280,
        "Institution_Name": "TRUE POWER MIRACLE CHAPEL"
    },
    {
        "Institution_Code": 2018201234281,
        "Institution_Name": "DONEWELL LIFE CO. LTD"
    },
    {
        "Institution_Code": 2018201234282,
        "Institution_Name": "CROWN INSURANCE BROKERS"
    },
    {
        "Institution_Code": 2018201234283,
        "Institution_Name": "MEC ELLIS INVESTMENTS"
    },
    {
        "Institution_Code": 2018201234284,
        "Institution_Name": "LEARNING ORGANIZATION"
    },
    {
        "Institution_Code": 2018201234285,
        "Institution_Name": "LAURUS DEVT PARTNERS"
    },
    {
        "Institution_Code": 2018201234286,
        "Institution_Name": "LEGACY AND LEGACY"
    },
    {
        "Institution_Code": 2018201234287,
        "Institution_Name": "WAHAB ESTATES"
    },
    {
        "Institution_Code": 2018201234288,
        "Institution_Name": "IN-SERVICE TRAINING CENT."
    },
    {
        "Institution_Code": 2018201234289,
        "Institution_Name": "KONA S D A CHURCH"
    },
    {
        "Institution_Code": 2018201234290,
        "Institution_Name": "KRISTO ASAFO MIS NEW TAFO"
    },
    {
        "Institution_Code": 2018201234291,
        "Institution_Name": "GHANA BAPTIST CONVENTION"
    },
    {
        "Institution_Code": 2018201234292,
        "Institution_Name": "PENTECOSTAL INT'L CHURCH"
    },
    {
        "Institution_Code": 2018201234293,
        "Institution_Name": "MOUNT HERMON AOG, TANOSO"
    },
    {
        "Institution_Code": 2018201234294,
        "Institution_Name": "GH NAT TAILORS&DRESS ASS."
    },
    {
        "Institution_Code": 2018201234295,
        "Institution_Name": "KRISTO ASAFO CHUR, JUABEN"
    },
    {
        "Institution_Code": 2018201234296,
        "Institution_Name": "BELIEVERS PRAYER CENT A/G"
    },
    {
        "Institution_Code": 2018201234297,
        "Institution_Name": "RENEWED LIFE CHRIS. MIN."
    },
    {
        "Institution_Code": 2018201234298,
        "Institution_Name": "LIFE INT'L CHURCH"
    },
    {
        "Institution_Code": 2018201234299,
        "Institution_Name": "ENVIRON. PROTECT. AGENCY"
    },
    {
        "Institution_Code": 2018201234300,
        "Institution_Name": "TRUE BELIEVERS MINISTRY"
    },
    {
        "Institution_Code": 2018201234237,
        "Institution_Name": "PROVIDENT HAIRDRESSERS ASSO."
    },
    {
        "Institution_Code": 2018201234238,
        "Institution_Name": "APOSTOLIC CHURCH,AHINKWA-AGOGO"
    },
    {
        "Institution_Code": 2018201234239,
        "Institution_Name": "APOSTOLIC CHURCH-AMASAMAN"
    },
    {
        "Institution_Code": 2018201234240,
        "Institution_Name": "CHURCH OF CHRIST-OFFICIAL TOWN ASH"
    },
    {
        "Institution_Code": 2018201234241,
        "Institution_Name": "PRESBY CHURCH GRACE, ASHAIMAN NT"
    },
    {
        "Institution_Code": 2018201234242,
        "Institution_Name": "SDA NORTH ZENU"
    },
    {
        "Institution_Code": 2018201234243,
        "Institution_Name": "TAC, POKUASE CENTRAL ASSEMBLIES"
    },
    {
        "Institution_Code": 2018201234244,
        "Institution_Name": "TAC, AFUAMAN ASSEMBLIES"
    },
    {
        "Institution_Code": 2018201234245,
        "Institution_Name": "ATML LTD"
    },
    {
        "Institution_Code": 2018201234246,
        "Institution_Name": "DEBBIES PRODUCTS"
    },
    {
        "Institution_Code": 2018201234247,
        "Institution_Name": "DONEWELL INSURANCE CO LTD"
    },
    {
        "Institution_Code": 2018201234248,
        "Institution_Name": "GHANA GROWTH FUND LTD"
    },
    {
        "Institution_Code": 2018201234249,
        "Institution_Name": "GHANA INSURANCE COLLEGE STAFF"
    },
    {
        "Institution_Code": 2018201234250,
        "Institution_Name": "GHIPPS"
    },
    {
        "Institution_Code": 2018201234251,
        "Institution_Name": "INTEGRATED PROPERTIES LTD"
    },
    {
        "Institution_Code": 2018201234252,
        "Institution_Name": "MAIN ONE CABLE & ASSOCIATES LTD"
    },
    {
        "Institution_Code": 2018201234253,
        "Institution_Name": "PROCESS & PLANTS AUTOMATION LTD"
    },
    {
        "Institution_Code": 2018201234254,
        "Institution_Name": "RST CO LTD"
    },
    {
        "Institution_Code": 2018201234255,
        "Institution_Name": "TELEMEDIA COMMUNICATIONS LTD"
    },
    {
        "Institution_Code": 2018201234256,
        "Institution_Name": "TOPP RECRUITMENT"
    },
    {
        "Institution_Code": 2018201234257,
        "Institution_Name": "UNISECURITIES LTD"
    },
    {
        "Institution_Code": 2018201234258,
        "Institution_Name": "SUREWAY ASSEMB.OF GOD"
    },
    {
        "Institution_Code": 2018201234259,
        "Institution_Name": "BREAK THROUGH ASSEM."
    },
    {
        "Institution_Code": 2018201234260,
        "Institution_Name": "EQUITY ASSURANCE SCHEME"
    },
    {
        "Institution_Code": 2018201234261,
        "Institution_Name": "MILLENNIUM PROMISE"
    },
    {
        "Institution_Code": 2018201234262,
        "Institution_Name": "EDC GHANA"
    },
    {
        "Institution_Code": 2018201234263,
        "Institution_Name": "SUNFLOWER INT. SCHOOL"
    },
    {
        "Institution_Code": 2018201234264,
        "Institution_Name": "ABENSU ASSEMBLY, CHURCH O"
    },
    {
        "Institution_Code": 2018201234265,
        "Institution_Name": "HOLY GABRIEL ANGLICAN CHU"
    },
    {
        "Institution_Code": 2018201234266,
        "Institution_Name": "CHURCH OF PENT-AKOTOSHIE"
    },
    {
        "Institution_Code": 2018201234267,
        "Institution_Name": "KRISTO ASAFO, LA"
    },
    {
        "Institution_Code": 2018201234268,
        "Institution_Name": "CHRIST CENTERED ASSEMBLIE"
    },
    {
        "Institution_Code": 2018201234230,
        "Institution_Name": "NAARA RURAL BANK"
    },
    {
        "Institution_Code": 2018201234233,
        "Institution_Name": "S.D.A. ASHAIMAN NORTH"
    },
    {
        "Institution_Code": 2018201234234,
        "Institution_Name": "GHABA BETHLEHEM, ASHAIMAN"
    },
    {
        "Institution_Code": 2018201234235,
        "Institution_Name": "APOSTOLIC CHURCH-OFANKOR"
    },
    {
        "Institution_Code": 2018201234236,
        "Institution_Name": "APOSTOLIC CHURCH-ANYAA"
    },
    {
        "Institution_Code": 2018201234203,
        "Institution_Name": "GHANA BAPTIST CONVENTION"
    },
    {
        "Institution_Code": 2018201234204,
        "Institution_Name": "RAANAN FISH-FEED"
    },
    {
        "Institution_Code": 2018201234205,
        "Institution_Name": "CLEARFREIGHT SHIP. AGENCY"
    },
    {
        "Institution_Code": 2018201240713,
        "Institution_Name": "GHANA WATER - HEAD OFFICE"
    },
    {
        "Institution_Code": 2018201240714,
        "Institution_Name": "GHANA WATER -KUMASI SOUTH"
    },
    {
        "Institution_Code": 2018201240715,
        "Institution_Name": "R & J GATEWAY MICRO FINAN"
    },
    {
        "Institution_Code": 2018201240716,
        "Institution_Name": "SEWERAGE SYSTEMS GHANA LT"
    },
    {
        "Institution_Code": 2018201241012,
        "Institution_Name": "AKOTI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241013,
        "Institution_Name": "EJURAMAN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241014,
        "Institution_Name": "ABOKOBI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241015,
        "Institution_Name": "ADA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241016,
        "Institution_Name": "ADEHERNAN SAVINGS & LOANS"
    },
    {
        "Institution_Code": 2018201241017,
        "Institution_Name": "AFRAM RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241018,
        "Institution_Name": "AHAFO ANO PREMIER RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241019,
        "Institution_Name": "AKROFUOM AREA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241020,
        "Institution_Name": "AKYEM MANSA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241021,
        "Institution_Name": "AKYEPIM RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241022,
        "Institution_Name": "AMASIE WEST RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241023,
        "Institution_Name": "APEX BANK"
    },
    {
        "Institution_Code": 2018201241024,
        "Institution_Name": "ASAWINSO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241025,
        "Institution_Name": "ATOBIASE AREA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241026,
        "Institution_Name": "ATWEABAN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241027,
        "Institution_Name": "ATWIMA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241028,
        "Institution_Name": "AVENOR RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241029,
        "Institution_Name": "BATAWU RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241030,
        "Institution_Name": "BAWJIASE AREA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241031,
        "Institution_Name": "BESSFA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241032,
        "Institution_Name": "BIA - TORYA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241033,
        "Institution_Name": "BONGO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241034,
        "Institution_Name": "BORIMANGA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241035,
        "Institution_Name": "BOSOME FREHO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241036,
        "Institution_Name": "BOSOMTWI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241037,
        "Institution_Name": "BRAKWA BRAMAN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241038,
        "Institution_Name": "BUILSA COMMUNITY BANK LTD"
    },
    {
        "Institution_Code": 2018201241039,
        "Institution_Name": "BUWUULONSO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241040,
        "Institution_Name": "CITIZEN RURAL BANK LTD."
    },
    {
        "Institution_Code": 2018201241041,
        "Institution_Name": "DANGME RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241042,
        "Institution_Name": "DUMPONG RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241043,
        "Institution_Name": "EASTERN GOMOA ASSIN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241044,
        "Institution_Name": "ENYAN DENKYIRA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241045,
        "Institution_Name": "FANTEAKWA RURAL BANK LTD."
    },
    {
        "Institution_Code": 2018201241046,
        "Institution_Name": "FIAGYA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241047,
        "Institution_Name": "GBI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241048,
        "Institution_Name": "GOMOA AJUMAKO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241049,
        "Institution_Name": "JOMORO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241050,
        "Institution_Name": "KPASA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241051,
        "Institution_Name": "KWAEBIBIREM RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241052,
        "Institution_Name": "KWAMANMAN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241053,
        "Institution_Name": "MANYA KROBO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241054,
        "Institution_Name": "MFANTSIMAN COMMUNITY RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241055,
        "Institution_Name": "MPONUA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241056,
        "Institution_Name": "NAFANA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241057,
        "Institution_Name": "NANDOM RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241058,
        "Institution_Name": "NKORANZA KWABRE RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241059,
        "Institution_Name": "NKRANKWANTA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241060,
        "Institution_Name": "NSUTAMAN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241061,
        "Institution_Name": "NYAKROM RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241062,
        "Institution_Name": "NYANKUMASE AHENKRO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241063,
        "Institution_Name": "NZEMA MANLE RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241064,
        "Institution_Name": "ODUPONGKPEHE RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241065,
        "Institution_Name": "ODWEN-ANOMA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241066,
        "Institution_Name": "OFFINSO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241067,
        "Institution_Name": "OTUASEKAN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241068,
        "Institution_Name": "SEFWIMAN RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241069,
        "Institution_Name": "SEKYEDUMASI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241070,
        "Institution_Name": "SHAI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241071,
        "Institution_Name": "SISSALA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241072,
        "Institution_Name": "SONZELE RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241073,
        "Institution_Name": "SOUTH BIRIM RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241074,
        "Institution_Name": "TANO AGYA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241075,
        "Institution_Name": "TANO RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241076,
        "Institution_Name": "TIZAA RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241077,
        "Institution_Name": "TOENDE RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241078,
        "Institution_Name": "UPPER AMENFI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241079,
        "Institution_Name": "WENCHI RURAL BANK LTD"
    },
    {
        "Institution_Code": 2018201241080,
        "Institution_Name": "ZABZUGU RURAL BANK LTD"
    },
    {
        "Institution_Code": 2019201241305,
        "Institution_Name": "UTRACK SAVINGS AND LOANS"
    },
    {
        "Institution_Code": 2019201241421,
        "Institution_Name": "PARLIAMENT HOUSE GHANA"
    },
    {
        "Institution_Code": 2019201241586,
        "Institution_Name": "UNIVERSITY OF ENERGY AND NATURAL RESOURCES"
    },
    {
        "Institution_Code": 2019201241597,
        "Institution_Name": "FIESTA ROYALE HOTEL"
    },
    {
        "Institution_Code": 2019201241876,
        "Institution_Name": "SEED PRODUCTION DIVISION"
    },
    {
        "Institution_Code": 2019201241877,
        "Institution_Name": "MOTHER OF GOD HOSPITAL"
    },
    {
        "Institution_Code": 2019201242059,
        "Institution_Name": "GHANA REVENUE AUTHORITY"
    },
    {
        "Institution_Code": 2019201242168,
        "Institution_Name": "OPPORTUNITY SAVINGS AND LOANS (TAMALE)"
    },
    {
        "Institution_Code": 2019201242270,
        "Institution_Name": "PBC SHEA LIMITED"
    },
    {
        "Institution_Code": 2020201242288,
        "Institution_Name": "COMPASSION GHANA-SUNYANI"
    },
    {
        "Institution_Code": 2020201242538,
        "Institution_Name": "OPPORTUNITY S&L STAFF"
    },
    {
        "Institution_Code": 2020201242667,
        "Institution_Name": "GHANA WATER-UPPER EAST"
    },
    {
        "Institution_Code": 2020201242719,
        "Institution_Name": "GHANA WATER - BOTTLING PLANT"
    },
    {
        "Institution_Code": 2020201242751,
        "Institution_Name": "GHANA WATER-TEMA"
    },
    {
        "Institution_Code": 2022201244453,
        "Institution_Name": "COMMUNITY WATER AND SANITATION AGENCY"
    },
    {
        "Institution_Code": 2022201244583,
        "Institution_Name": "GHANA OIL PLANTATION DEV'T LTD"
    },
    {
        "Institution_Code": 2022201244679,
        "Institution_Name": "BAOBAB MICROFINANCE"
    }
]