export const paymentModes = [
    {
    "mode": 'Bank',
    "code": 'DD',
    },
   {
    "mode": 'Institution',
    "code": 'K',
  },
    {
    "mode": 'Mobile Money',
    "code": 'C',
  },
    {
    "mode": 'Cash',
    "code": 'C',
    }
]

