import axios from 'axios';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber, isValidPhoneNumber  } from 'react-phone-number-input'
  
const agentApiUrl = `${process.env.REACT_APP_API_AUTH_BASE_URL}`;
const clientApiUrl = `${process.env.REACT_APP_CLIENT_API}`;


export async function register(values) {
  const payload = {
    agent_code: values.agentCode,
    password: values.password,
    confirm_password: values.confirm_password,
  };

  try {
    const url = `${agentApiUrl}/signup`;
    const response = await axios.post(url, payload);
    const responseData = response.data;

    if (responseData.data.access_token) {
      localStorage.setItem('agentData', JSON.stringify(responseData.data));
      return {
        success: true,
        message: responseData.data.message,
      };
    } else {
      return {
        success: false,
        message: responseData.message,
      };
    }
  } catch (error) {
    if (error.response && error.response.status === 409) {
      return {
        success: false,
        message: 'Sales agent already registered! Please login',
      };
    } else {
      return {
        success: false,
        message: error.message,
      };
    }
  }
}






/** login request */
export async function login(values) {
  try {
    const url = `${agentApiUrl}/login`;
    const payload = {
      agent_code: values.agentCode,
      password: values.password,
    }

    if (payload.agent_code && payload.password) {
      const response = await axios.post(url, payload);
      console.log('login response', response)
      const responseData = response.data;

      if (response.status === 200 && responseData.data.access_token) {
        localStorage.setItem('agentData', JSON.stringify(responseData.data));
        
        return {
          success: true,
          message: responseData.data.message,
          otp: responseData.data.otp,
          access_token: responseData.data.access_token,
        };
      } else {
        // Invalid credentials or other error
        return {
          success: false,
          message: responseData.data.message,
          status: response.status,
          data: responseData,
        };
      }
    } else {
      // Agent code is missing
      return {
        success: false,
        message: "Agent code is required.",
      };
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        // Unauthorized, invalid credentials
        const responseData = error.response.data;
        return {
          success: false,
          message: responseData.message || "Invalid credentials. Please check your agent code and password.",
          status: "Unauthorized",
          code: error.response.status,
          data: responseData,
        };
      } else if (error.response.status === 404) {
        // User not found
        const responseData = error.response.data;
        return {
          success: false,
          message: responseData.message || "Account not found. Please register to continue.",
          status: "Not Found",
          code: error.response.status,
          data: responseData,
        };
      }
    }

    // Other errors
    return {
      success: false,
      message: error.message,
    };
  }
}




let autoLogoutTimer;

export function setAutoLogout() {
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    if (!loginTimestamp) return;

    const currentTime = Date.now();
    const timePassedSinceLogin = currentTime - parseInt(loginTimestamp, 10);
    const twentyFourHours = 24 * 60 * 60 * 1000; 
    if (timePassedSinceLogin >= twentyFourHours) {
    logout(); 
    } else {
        const timeUntilAutoLogout = twentyFourHours - timePassedSinceLogin;
        autoLogoutTimer = setTimeout(logout, timeUntilAutoLogout);
    }
}

export function clearAutoLogoutTimer() {
    if (autoLogoutTimer) {
        clearTimeout(autoLogoutTimer);
    }
}



export async function logout() {
  try {
    
    const url = `${agentApiUrl}/logout`;

    const token = localStorage.getItem('agentData') ? JSON.parse(localStorage.getItem('agentData')).access_token : null;

    if (!token) {
      return {
        success: false,
        message: 'No access token found',
      };
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.post(url, null, { headers });

    if (response.status === 200) {
      localStorage.removeItem('agentData'); 
    localStorage.removeItem('loginTimestamp');
      return {
        success: true,
        message: response.data.message || 'Logout successful',
      };
    } else {
      return {
        success: false,
        message: response.data.message || 'An error occurred during logout',
      };
    }
  } catch (error) {
    return {
      success: false,
      message: 'An error occurred during logout',
    };
  }
}




/** Activate Account with OTP by retrieving the access token from local storage */
export async function activateAccount(values) {
  const agentData = JSON.parse(localStorage.getItem('agentData'));
  const agent_code = (agentData && agentData.agent_code) || localStorage.getItem('agentCode');
  
    const token = localStorage.getItem('agentData') ? JSON.parse(localStorage.getItem('agentData')).access_token : null;

 try {
    const payload = {
      otp: values.otp,
      agent_code: agent_code,
    };

    const url = `${agentApiUrl}/activate-account`;
    const response = await axios.post(url, payload);
  

   if (response.status === 200) {
      return {
        success: true,
        message: response.data.message
      };
    } else {
      return {
        success: false,
        message: 'An error occurred while activating the account'
      };
    }
  } catch (error) {
    if (error.response) {
      const responseData = error.response.data;
      
      if (responseData.message === 'Both OTP and agent_code are required') {
        return {
          success: false,
          message: 'Both OTP and agent_code are required'
        };
      } else if (responseData.message === 'Sales executive not found') {
        return {
          success: false,
          message: 'Sales executive not found'
        };
      } else if (responseData.message === 'OTP has already been used') {
        return {
          success: false,
          message: 'OTP has already been used'
        };
      } else if (responseData.message === 'OTP has expired') {
        return {
          success: false,
          message: 'OTP has expired'
        };
      } else if (responseData.message === 'Invalid OTP') {
        return {
          success: false,
          message: 'Invalid OTP'
        };
      } else {
        return {
          success: false,
          message: 'An error occurred while activating the account'
        };
      }
    } else {
      return {
        success: false,
        message: 'An error occurred while making the request'
      };
    }
  }
}



export async function otpVerification(values) {
  const agentData = JSON.parse(localStorage.getItem('agentData'));
 const agentCode = agentData && agentData.agent_code ? agentData.agent_code : localStorage.getItem('agentCode');

   if (!agentCode) {
    return {
      success: false,
      message: 'Agent code is missing. Please log in again.',
    };
  }

 const payload = {
    otp: values.otp,
    agent_code: agentCode,
  };

  console.log('otp payload:', payload)


  try {
    const url = `${agentApiUrl}/verify-otp`;
    const response = await axios.post(url, payload);
    console.log('response:', response);
    const responseData = response.data;

    if (responseData && responseData.message === "OTP verification successful") {
      return {
        success: true,
        message: responseData.message,
      };
    } else {
      return {
        success: false,
        message: responseData.message,
      };
    }
  } catch (error) {
    const message = error.response?.data?.message || 'An error occurred during OTP verification. Please try again.';
    return {
      success: false,
      message,
    };
  }
}

//   try {
//     //const token = localStorage.getItem('access_token');
//     const url = `${agentApiUrl}/verify-otp`;
    
//     const response = await axios.post(url, payload);
//     console.log('response:', response)
//     const responseData = response.data.data;

//     if (responseData && responseData.message === "OTP verification successful") {
//       const message = responseData.message;

//       return {
//         success: true,
//         message: message,
//       };
//     } else {
//       return {
//         success: false,
//         message: responseData.message,
//       };
//     }
//   } catch (error) {
//     if (error.response && error.response.data && error.response.data.message) {
//       return {
//         success: false,
//         message: error.response.data.message,
//       };
//     } else {
//       return {
//         success: false,
//         message: 'An error occurred during OTP verification. Please try again.',
//       };
//     }
//   }
// }







export async function clientOtpGenerate(clientPhoneNumber, productInitials) {
  const payload = {
    phone_number: clientPhoneNumber,
    product: productInitials
  };

  try {
    const url = `${clientApiUrl}/generate-otp`;
    const response = await axios.post(url, payload);

    if (response.status === 201) {
      return {
        success: true,
        otpCode: response.data.data?.otp_code
      };
    } else if (response.status === 200) {
      return {
        success: true,
        message: response.data.message 
      };
    } else {
      return {
        success: false,
        message: "Unexpected response from server."
      };
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return {
        success: false,
        message: error.response.data.message
      };
    } else {
      return {
        success: false,
        message: "An error occurred while generating OTP. Please try again."
      };
    }
  }
}



let applicationID = localStorage.getItem('currentApplicationID');

  const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const matchingApplication = ongoingApplications.find((application) => application.id === applicationID);





export async function clientOtpVerify(values, productInitials) {
  const payload = {
    otp: values.otp,
    product:productInitials
  };
  try {
    const url = `${clientApiUrl}/verify-otp`;
    const response = await axios.post(url, payload);
    const responseData = response.data;

    if (responseData && responseData.message === "OTP verification successful") {
      return {
        success: true,
        message: responseData.message
      };
    } else {
      return {
        success: false,
        message: responseData.message
      };
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      return {
        success: false,
        message: error.response.data.message
      };
    } else {
      return {
        success: false,
        message: 'An error occurred during OTP verification. Please try again.'
      };
    }
  }
}



/** Reset password */


export async function passwordReset(values) {
  const agent_code = ((localStorage.getItem('agentData') ? JSON.parse(localStorage.getItem('agentData')) : null)?.agent_code) || localStorage.getItem('agentCode');


  const payload = {
    password: values.password,
    agent_code: agent_code,
    confirm_password: values.confirm_password,
  };

  

  try {
    const url = `${agentApiUrl}/password_reset`;
    const response = await axios.post(url, payload);
    const responseData = response.data;

    if (response.status === 200) {
      return {
        success: true,
        message: responseData.message,
      };
    } else {
      return {
        success: false,
        message: responseData.message,
      };
    }
  } catch (error) {
    if (error.response) {
      const responseData = error.response.data;
      return {
        success: false,
        message: responseData.message,
      };
    } else {
      return {
        success: false,
        message: 'An error occurred during password reset. Please try again.',
      };
    }
  }
}



/** send OTP */

export async function sendOTP(agent_code) {
  console.log('forgot password values', agent_code )

  try {
    const url = `${agentApiUrl}/generate-otp`;
    const response = await axios.post(url, { agent_code: agent_code });
    console.log(response)
    const responseData = response.data.data;

    return {
      success: true,
      otp: responseData.otp_code,
      message: 'OTP sent to your email/phone number successfully.',
    };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred while sending OTP. Please try again.',
    };
  }
}



