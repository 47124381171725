import React from 'react';

const PolicyTermsWPP = () => {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-semibold mb-4">Conditions</h1>
      <h2 className="text-xl font-semibold mb-2">WAELTH MASTER PLUS</h2>
      <p className="mb-4">You are under obligation to read this policy document.</p>
      
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">The Wealthmaster Plus Product</h3>
        <p className="mb-4">
          The Wealthmaster Plus product is a conventional endowment product which has the maturity benefit being the same as the death benefit for the policyholder.
          It offers policy holders a unique opportunity to create wealth to meet their short to medium term goals while protecting their family income when they are called home or permanently disabled.
        </p>
        <p className="mb-4">The policy features include:</p>
        <ul className="list-disc ml-6 mb-4">
          <li>It is hedged against inflation through the benefit increase option.</li>
          <li>It has attractive rate of return and high investment returns.</li>
          <li>A policy loan is accessible when the policy has been in force for at least twenty four (24) months.</li>
        </ul>
      </div>
      
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">Main Conditions of the Product</h3>
        <section className="mb-4">
          <h4 className="text-md font-semibold mb-2">SECTION 1</h4>
          <p>Type of Policy</p>
          <p>This is a Conventional Endowment Policy.</p>
        </section>
        <section className="mb-4">
          <h4 className="text-md font-semibold mb-2">SECTION 2</h4>
          {/* Content for SECTION 2 */}
        </section>
        {/* Repeat the pattern for other sections (3 to 8) */}
      </div>
    </div>
  );
};

export default PolicyTermsWPP;
