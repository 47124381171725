import React, { lazy } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ClientDetailsStepper from './pages/ClientDetailsStepper';
import MandateForm from './pages/MandateForm';
import PolicyTermsWPP from './policies/PolicyTermsWPP';
import { AuthContextProvider } from './context/AuthContext'; // Import the AuthContextProvider

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const VerifyOTP = lazy(() => import('./pages/VerifyOTP'));
const ActivateAccount = lazy(() => import('./pages/ActivateAccount'));
const AuthLayout = lazy(() => import('./components/AuthLayout'));

function App() {
  return (
    <>
      <AuthContextProvider> 
        <Router>
          {/* <AccessibleNavigationAnnouncer /> */}
          <Switch>
            <Route path="/onboarding/client/:initials" component={ClientDetailsStepper} />

            <Route path="/agent/auth/login" render={() => (
              <AuthLayout>
                <Login />
              </AuthLayout>
            )} /> 
            <Route path="/agent/auth/register" render={() => (
              <AuthLayout>
                <Register />
              </AuthLayout>
            )} />
            <Route path="/agent/auth/forgot-password" render={() => (
              <AuthLayout>
                <ForgotPassword />
              </AuthLayout>
            )} />
            <Route path="/agent/auth/verify-otp" render={() => (
              <AuthLayout>
                <VerifyOTP />
              </AuthLayout>
            )} />
            <Route path="/agent/auth/password-reset" render={() => (
              <AuthLayout>
                <ResetPassword />
              </AuthLayout>
            )} />
            <Route path="/agent/auth/activate-account" render={() => (
              <AuthLayout>
                <ActivateAccount />
              </AuthLayout>
            )} />

           
            
            <Route path="/wpp-terms-and-conditions" component={PolicyTermsWPP} />
            <Route path="/onboarding/:initials" component={ClientDetailsStepper} />
            <Route path="/app/mandate" component={MandateForm} />
            <Route path="/app" component={Layout} />
            
            <Redirect exact from="/" to="/agent/auth/login" />
          </Switch>
        </Router>
      </AuthContextProvider> 
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
