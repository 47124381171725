import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import './assets/css/tailwind.output.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext';
import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';
import customTheme from './theme/customTheme';
import { ClientContextProvider } from './context/ClientContext';
import { BeneficiaryContextProvider } from './context/BeneficiaryContext';
import {TrusteeContextProvider} from './context/TrusteeContext';
import { CheckboxProvider } from './context/CheckboxContext';
import { ProposalDetailsProvider } from './context/ProposalDetailsContext';
import { PaymentDataContextProvider } from './context/PaymentDataContext';
import { SignatureContextProvider } from './context/SignatureContext';
import { AgentContextProvider } from './context/AgentContext';
import {AuthContextProvider} from './context/AuthContext';
// import { ApplicationModeContextProvider } from './context/ApplicationModeContext';
import { ProductDetailsContextProvider } from './context/ProductDetailsContext';
// import * as serviceWorker from './serviceWorker';
import {ApplicationContextProvider } from './context/ApplicationContext';

ReactDOM.render(
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill theme={customTheme}>
        <ApplicationContextProvider>
           <ClientContextProvider>
        <AgentContextProvider>
        <AuthContextProvider>
        <ProposalDetailsProvider>
          <PaymentDataContextProvider>
            <SignatureContextProvider>
       
            {/* Wrap BeneficiaryContextProvider within ClientContextProvider */}
            <TrusteeContextProvider>
            <BeneficiaryContextProvider>
              
                <CheckboxProvider>
                  <ProductDetailsContextProvider>
                    <App />
                  </ProductDetailsContextProvider>
              </CheckboxProvider>
              </BeneficiaryContextProvider>
              </TrusteeContextProvider>
              
              </SignatureContextProvider>
            </PaymentDataContextProvider>
            </ProposalDetailsProvider>
            </AuthContextProvider>
            </AgentContextProvider>
            </ClientContextProvider>
          </ApplicationContextProvider>
      </Windmill>
    </Suspense>
  </SidebarProvider>,
  document.getElementById('root')
);

//serviceWorker.register();
