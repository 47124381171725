import React from 'react';
import logo from '../assets/img/starlife-logo.png';
import { format} from 'date-fns';
import { Label } from '@windmill/react-ui'
import {Checkbox } from 'flowbite-react';
import { institutions } from '../constants/institutions';
import { banks } from '../constants/banks';
import { paymentModes } from '../constants/paymentModes';


const MandateForm = () => {
let applicationID = localStorage.getItem('currentApplicationID');
  const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));

  const matchingApplication = ongoingApplications.find(application => application.id === applicationID);


  const getBankName = (code) => {
  const bank = banks.find((item) => item.bnkCode === code);
  return bank ? bank.bnkBankName : 'Unknown Bank';
  }; 

   const getInstitutionName = (code) => {
  const institution = institutions.find((item) => item.Institution_Code === parseInt(code));
  return institution ? institution.Institution_Name : 'Unknown Institution';
  };

  const signature = localStorage.getItem('client_signature');
  const currentDate = format(new Date(), 'dd-MM-yyyy');
  
  return (
     <div id="mandate" className=" text-lg bg-white rounded-lg text-center p-4 mb-4 mt-4 mx-auto max-w-screen-lg">
      <div className="flex flex-col items-center justify-center h-full">
      <img src={logo} alt="Starlife Logo" className="w-36 h-20" />
      <div className="mb-10 font-serif underline text-4xl md:text-xl font-bold" style={{ color: '#792F7e'}}>BANK DEBIT MANDATE</div>
      <div>
        <dl>
          <div className="flex items-center text-lg">
            <Label className="font-lg text-left text-lg" style={{ color: '#792F7e'}}>POLICY HOLDER'S NAME:</Label>
            <div className="rounded-md px-2 py-1 underline">
  {`${matchingApplication.data.clientData.firstName || ''} ${matchingApplication.data.clientData.otherNames || ''} ${matchingApplication.data.clientData.lastName || ''}`.trim()}
</div>

          </div>
          <div className="flex items-center">
            <Label className="font-lg font-medium text-left text-lg " style={{ color: '#792F7e'}}>BANK:</Label>
            <div className="rounded-md px-2 py-1 underline">{getBankName(matchingApplication?.data?.paymentData?.bank) || 'Unknown Bank'}</div>
          </div>
          <div className="flex items-center">
            <Label className="font-lg font-medium text-left text-lg" style={{ color: '#792F7e'}}>BRANCH:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.paymentData?.bankBranch}</div>
          </div>
          <div className="flex items-center">
            <Label className="font-lg font-medium text-left text-lg " style={{ color: '#792F7e'}}>CUSTOMER'S ACCOUNT NO:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.paymentData?.accountNumber}</div>
          </div>
          <div className="flex items-center">
            <Label className="font-lg font-medium text-left text-lg " style={{ color: '#792F7e'}}>DEDUCTION AMOUNT:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data.productDetailsData.quoteDetails.basicPremium ? (
        <>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GHC',
          }).format(matchingApplication?.data.productDetailsData.quoteDetails.basicPremium)}
        </>
      ) : (
        ''
      )}</div>
          </div>
          <div className="flex items-center">
            <Label className="font-lg font-medium text-left text-lg" style={{ color: '#792F7e'}}>DATE OF FIRST DEDUCTION:</Label>
            <div className="rounded-md px-2 py-1 underline">{format(new Date(matchingApplication.data.paymentData.firstPaymentDate), 'dd-MM-yyyy')}</div>
          </div>
          <div className="flex items-center">
            <Label className="font-lg font-medium text-left text-lg" style={{ color: '#792F7e'}}>FREQUENCY OF PAYMENT:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.productDetailsData?.quoteDetails?.paymentFrequency}</div>
          </div>
          <div className="grid grid-cols-2 gap-4 items-center">
  <Label className="font-medium text-left text-lg" style={{ color: '#792F7e'}}>ANNUAL BENEFITS INCREASE OPTIONS?</Label>
  <div className="flex space-x-4">
    <div style={{ color: '#792F7e'}}>
      <Checkbox 
        checked={matchingApplication?.data?.productDetailsData?.quoteDetails?.benefitsOptionQuestion?.toLowerCase() === 'yes'} 
        readOnly
      /> YES
    </div>
    <div style={{ color: '#792F7e'}}>
      <Checkbox 
        checked={matchingApplication?.data?.productDetailsData?.quoteDetails?.benefitsOptionQuestion?.toLowerCase() === 'no'} 
        readOnly
      /> NO
    </div>
  </div>
</div>


          <div className="flex items-center">
            <Label className="font-lg font-medium text-left text-lg" style={{ color: '#792F7e'}}>STATE RATE OF INCREASE (%):</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.productDetailsData?.quoteDetails?.incrementalOption?.split('%')[0]}</div>
            </div>
            
            <div className="my-5 font-serif underline text-lg md:text-xl font-bold text-left" style={{ color: '#792F7e'}}>DECLARATION</div>
<p className="text-lg text-left my-2 w-full" style={{ color: '#792F7e'}}>I have made a proposal for the bancassurance product type and authorize that my account be debited with the amount stated above and credited to GCB Bank Ltd.</p>
<p className="text-lg text-left mb-2 w-full" style={{ color: '#792F7e'}}>This authorization shall be effective, until a written notice by me to cancel it has been issued and received stating when cancellation shall be effective.</p>


         
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
  <div className="flex items-center">
    <Label className="text-lg font-medium text-left" style={{ color: '#792F7e'}}>CUSTOMER'S SIGNATURE / THUMBPRINT:</Label>
    <div className="rounded-md py-1 underline ml-6"><img src={matchingApplication?.data?.paymentData?.signature || matchingApplication?.data?.paymentData?.signatureImage} alt="Signature" className="w-20 h-20" /></div>
</div>
<div className="flex items-center">
    <Label className="text-lg font-medium text-left" style={{ color: '#792F7e'}}>DATE:</Label>
    <div className="rounded-md px-2 py-1 underline">{currentDate}</div>
</div>

</div>



          <div className="flex items-center mt-2">
            <Label className="text-lg font-medium text-left " style={{ color: '#792F7e'}} >CONTACT NO.:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication.data.clientData.mobileNumber || ''}</div>
          </div>

          <div className="mt-4 font-serif underline text-lg md:text-xl font-bold items-center text-center" style={{ color: '#792F7e'}}>FOR OFFICIAL USE ONLY</div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
  <div className="flex items-center mt-4">
    <Label className="text-lg font-medium text-left" style={{ color: '#792F7e'}}>POLICY NO:</Label>
    <div className="rounded-md py-1 underline">{localStorage.getItem('proposal_number')}</div>
</div>
<div className="flex items-center mt-4">
    <Label className="text-lg font-medium text-left" style={{ color: '#792F7e'}}>DATE:</Label>
    <div className="rounded-md px-2 py-1 underline">{currentDate}</div>
</div>

</div>

        </dl>
      </div>
      </div>
    </div>
  );
};

export default MandateForm;
