import axios from "axios";
import { productMappings } from "../../constants/productMappings";



export const restructureOngoingApplications = () => {
  let ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

  // Check if the first element is an array, indicating an array of arrays structure
  if (ongoingApplications.length > 0 && Array.isArray(ongoingApplications[0])) {
    // Flatten the array of arrays to a single-level array of objects
    ongoingApplications = ongoingApplications.flat();
    localStorage.setItem('ongoingApplications', JSON.stringify(ongoingApplications));
    console.log('Ongoing applications have been successfully restructured.');
  } else {
    console.log('Ongoing applications are already in the correct format.');
  }
};



export const determineStepsCount = (productInitials) => {
  const stepsMapping = {
    'WPP': 7,
    'CLP': 7,
    'UPP': 7,
    'EHP': 8,
    'CBP': 7,
    'FPP': 8,
    'FCL': 7,
    'FWP': 7,
    'FUP': 7,
    'FEH': 8,
    'PCL': 7,
    'PUP': 7,
    'PWP': 7,
    'PEH': 8,
  };
  return stepsMapping[productInitials] || 0;
};


export const updateFormSubmissionStatus = (applicationId, stepIndex, success) => {
  //console.log('updateFormSubmissionStatus', applicationId, stepIndex, success);
  let ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  
  // Update to iterate over each group and then each application within that group
  ongoingApplications = ongoingApplications.map(group => 
    group.map(application => {
      if (application.id === applicationId) {
        // Ensure formSubmissionStatus object exists
        if (!application.formSubmissionStatus) {
          application.formSubmissionStatus = {};
        }
        // Update the specific step status
        application.formSubmissionStatus[stepIndex] = success;
      }
      return application;
    })
  );
  
  localStorage.setItem('ongoingApplications', JSON.stringify(ongoingApplications));
};






export function generateApplicationID(productName) {
  let initials;
  const match = productName.match(/\(([^)]+)\)/);

  if (match) {
    initials = match[1];
  } else {
  
    const mapping = productMappings.find(entry => entry.productName === productName);
    initials = mapping ? mapping.initials : '';
  }

  const timestamp = Date.now();
  return initials + timestamp;
}

export const updateCurrentApplicationID = (newApplicationID) => {
  localStorage.removeItem('currentApplicationID');
  
  localStorage.setItem('currentApplicationID', newApplicationID);
}


