import React, { useState, createContext } from 'react';

export const ProposalDetailsContext = createContext();


export const ProposalDetailsProvider = ({ children }) => {
  // State to store the proposal details cache
  const [proposalDetails, setProposalDetails] = useState([]);

  return (
    <ProposalDetailsContext.Provider value={{ proposalDetails, setProposalDetails }}>
      {children}
    </ProposalDetailsContext.Provider>
  );
};
