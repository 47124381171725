export const banks = [
    {
        "bnkCode": 28,
        "bnkBankName": "ACCESS BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "28",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 8,
        "bnkBankName": "AGRICULTURAL DEVELOPMENT  BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "08",
        "bnkMinLength": 16,
        "bnkMaxLength": 16
    },
    {
        "bnkCode": 21,
        "bnkBankName": "BANK OF AFRICA",
        "bnkRemarks": null,
        "bnkShtDesc": "21",
        "bnkMinLength": 9,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 3,
        "bnkBankName": "BARCLAYS BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "03",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 14,
        "bnkBankName": "CAL BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "14",
        "bnkMinLength": 12,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 22,
        "bnkBankName": "CONSOLIDATED BANK GHANA",
        "bnkRemarks": null,
        "bnkShtDesc": "22",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 13,
        "bnkBankName": "ECOBANK",
        "bnkRemarks": null,
        "bnkShtDesc": "13",
        "bnkMinLength": 13,
        "bnkMaxLength": 16
    },
    {
        "bnkCode": 24,
        "bnkBankName": "FIDELITY BANK GHANA",
        "bnkRemarks": null,
        "bnkShtDesc": "24",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 17,
        "bnkBankName": "FIRST ATLANTIC BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "17",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 20,
        "bnkBankName": "FIRST BANK OF NIGERIA",
        "bnkRemarks": null,
        "bnkShtDesc": "20",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 33,
        "bnkBankName": "FIRST NATIONAL BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "33",
        "bnkMinLength": 11,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 4,
        "bnkBankName": "GHANA COMMERCIAL BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "04",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 23,
        "bnkBankName": "GUARANTY TRUST",
        "bnkRemarks": null,
        "bnkShtDesc": "23",
        "bnkMinLength": 12,
        "bnkMaxLength": 14
    },
    {
        "bnkCode": 5,
        "bnkBankName": "NATIONAL INVESTMENT BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "05",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 27,
        "bnkBankName": "OMNIBSIC BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "27",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 18,
        "bnkBankName": "PRUDENTIAL BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "18",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 11,
        "bnkBankName": "REPUBLIC BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "11",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 9,
        "bnkBankName": "SOCIETE GENERALE",
        "bnkRemarks": null,
        "bnkShtDesc": "09",
        "bnkMinLength": 12,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 19,
        "bnkBankName": "STANBIC BANK GHANA",
        "bnkRemarks": null,
        "bnkShtDesc": "19",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 2,
        "bnkBankName": "STANDARD CHARTERED BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "02",
        "bnkMinLength": 13,
        "bnkMaxLength": 13
    },
    {
        "bnkCode": 6,
        "bnkBankName": "UNITED BANK FOR AFRICA",
        "bnkRemarks": null,
        "bnkShtDesc": "06",
        "bnkMinLength": 14,
        "bnkMaxLength": 14
    },
    {
        "bnkCode": 10,
        "bnkBankName": "UNIVERSAL MERCHANT BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "10",
        "bnkMinLength": 13,
        "bnkMaxLength": 15
    },
    {
        "bnkCode": 12,
        "bnkBankName": "ZENITH BANK",
        "bnkRemarks": null,
        "bnkShtDesc": "12",
        "bnkMinLength": 10,
        "bnkMaxLength": 13
    }
]