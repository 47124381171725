import React, {  useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { toast, Toaster } from 'react-hot-toast';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  HelperText,
  Label,
  Select,
} from '@windmill/react-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {  differenceInYears } from 'date-fns';

const EditTrusteeModal = ({
  isOpen,
  onClose,
  trusteeIndex,
  relationshipOptions,
  validationSchema,
  initialValues,
  saveEditedTrustee,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
   const [genderOptions, setGenderOptions] = useState([]);

  console.log('initialValues', initialValues);

  const calculateAge = (dob) => {
    return differenceInYears(new Date(), dob);
  };


   const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };


  const handleRelationshipChange = (event) => {
  const selectedRelationship = event.target.value;
  const selectedOption = relationshipOptions.find((option) => option.description === selectedRelationship);

  if (selectedOption) {
    formik.setFieldValue('relationship', selectedOption.description);
    formik.setFieldValue('gender', ''); 
    setGenderOptions(selectedOption.genderOptions);
  } else {
    formik.setFieldValue('relationship', '');
    formik.setFieldValue('gender', '');
    setGenderOptions([]);
  }
  };

  

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const trustee_Age = calculateAge(values.dob);
      if (trustee_Age < 18) {
        toast.error('The trustee cannot be less than 18 years.');
        return;
      } else {
        saveEditedTrustee(trusteeIndex, values);
        toast.success('Trustee has been updated successfully.');
      }
    },
  });

  useEffect(() => {
    if (initialValues && initialValues.dob) {
      setSelectedDate(new Date(initialValues.dob));
    } else {
      setSelectedDate(null);
    }
  }, [initialValues]);

  return (
    <div className="max-w-2xl">
      <Toaster />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader>Edit Trustee</ModalHeader>
        <ModalBody>
          <div className="flex-1 items-center justify-center">
            <form onSubmit={formik.handleSubmit}>
              {/* First Name */}
              <Label className="mt-4">
              <span>First Name</span>
              <Input
                className="mt-1"
                placeholder="Enter your first name"
                {...formik.getFieldProps('firstName')}
                  valid={formik.touched.firstNames && !formik.errors.firstName}
                  onChange={(e) => handleInputChange('firstName', e)}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <HelperText className="text-red-600">{formik.errors.firstName}</HelperText>
              )}
            </Label>

              {/* Other Names */}
              <Label className="mt-4">
              <span>Other Name</span>
              <Input
                className="mt-1"
                placeholder="Enter your other names"
                {...formik.getFieldProps('otherName')}
                valid={formik.touched.otherName && !formik.errors.otherName}
              />
              {formik.touched.otherName && formik.errors.otherName && (
                <HelperText className="text-red-600">{formik.errors.otherName}</HelperText>
              )}
            </Label>

              {/* Last Name */}
              <Label className="mt-4">
              <span>Last Name</span>
              <Input
                className="mt-1"
                placeholder="Enter your last name"
                {...formik.getFieldProps('lastName')}
                valid={formik.touched.lastName && !formik.errors.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <HelperText className="text-red-500">{formik.errors.lastName}</HelperText>
              )}
            </Label>

              {/* Date of Birth */}
              <Label htmlFor="dob" className="mt-4">
                <span>Date of Birth</span>
                <div className="mt-1">
                  <DatePicker
                    id="dob"
                    selected={selectedDate}
                    maxDate={new Date()}
                   onChange={(date) => {
              setSelectedDate(date);
              formik.setFieldValue('dob', date); 
            }}
                    dateFormat="dd-MM-yyyy"
                    customInput={
                      <Input
                        type="text"
                        placeholder="Select date"
                        className={formik.touched.dob && formik.errors.dob ? 'error' : ''}
                       
                      />
                    }
                    onBlur={() => formik.setFieldTouched('dob', true)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select date"
                  />
                </div>
                {formik.touched.dob && formik.errors.dob && (
                  <div className="text-red-500">{formik.errors.dob}</div>
                )}
              </Label>

              <Label className="mt-4">
              <span>Email</span>
              <Input
                className="mt-1"
                placeholder="Enter your Email"
                {...formik.getFieldProps('email')}
                valid={formik.touched.lastName && !formik.errors.email}
              />
              {formik.touched.email && formik.errors.email && (
                <HelperText className="text-red-500">{formik.errors.email}</HelperText>
              )}
            </Label>
              
              <Label htmlFor="relationship" className="mt-4">
      <span>Relationship</span>
      <Select
    id="relationship"
    className="mt-1"
    {...formik.getFieldProps('relationship')}
    valid={formik.touched.relationship && !formik.errors.relationship}
    onChange={handleRelationshipChange}
      >
    <option value="">Select Trustee relationship</option>
    {relationshipOptions.map((option) => (
      <option key={option.code} value={option.description}>
        {option.description}
      </option>
    ))}
  </Select>
  {formik.touched.relationship && formik.errors.relationship && (
    <HelperText className="text-red-500">{formik.errors.relationship}</HelperText>
  )}
  </Label>


        {genderOptions.length > 0 && (
  <Label htmlFor="gender" className="mt-4">
    <span>Gender</span>
    <Select
      id="gender"
      className="mt-1"
      {...formik.getFieldProps('gender')}
      valid={formik.touched.gender && !formik.errors.gender}
    >
      <option value="">Select Trustee's Gender</option>
      {genderOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
    {formik.touched.gender && formik.errors.gender && (
      <HelperText className="text-red-500">{formik.errors.gender}</HelperText>
    )}
  </Label>
)}


              {/* Address */}
        <Label className="mt-4">
          <span>Address</span>
          <Input
            className="mt-1"
            placeholder="Enter the trustee address"
            type="text"
            {...formik.getFieldProps('Address')}
                  valid={formik.touched.Address && !formik.errors.Address}


                  onChange={(e) => handleInputChange('Address', e)}
                  
          />
          {formik.touched.Address && formik.errors.Address && (
            <HelperText className="text-red-500">{formik.errors.Address}</HelperText>
          )}
        </Label>

             <Label className="mt-4">
  <span>Trustee Contact Number</span>
  <PhoneInput
    inputComponent={Input}
    placeholder="Enter trustee number"
    defaultCountry="GH"
    international
    countryCallingCodeEditable={false}
    value={formik.values?.contactNumber || ''} 
    onChange={(value) => {
      formik.setFieldValue('contactNumber', value);
      formik.setFieldTouched('contactNumber', true);
    }}
    error={
      (formik.values?.contactNumber &&
        !isPossiblePhoneNumber(formik.values.contactNumber)
        ? ['Invalid phone number length']
        : [])
    }
  />
  {(formik.touched.contactNumber &&
    formik.errors.contactNumber) ||
    (formik.values?.contactNumber &&
      !isPossiblePhoneNumber(formik.values.contactNumber)) ? (
    <div className="text-red-600">
      {formik.errors.contactNumber ||
        'Invalid phone number length'}
    </div>
  ) : null}
</Label>

              <ModalFooter className="flex justify-center">
                <div className="flex justify-center mt-10">
                  <Button
                    onClick={formik.handleSubmit}
                    type="button"
                    style={{ backgroundColor: '#792F7E', color: 'white', padding: '10px 50px' }}
                  >
                    Save
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditTrusteeModal;
