import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  HelperText,
  Label,
  Select,
} from '@windmill/react-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, set, parse, differenceInYears } from 'date-fns';
const EditBeneficiaryModal = ({ isOpen, onClose, beneficiaryIndex, relationshipOptions, validationSchema, initialValues, saveEditedBeneficiary, selectedProduct  }) => {
const [selectedDate, setSelectedDate] = useState(null);
  const [genderOptions, setGenderOptions] = useState([]);


  
const calculateAge = (dob) => {
    return differenceInYears(new Date(), dob);
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    formik.setFieldValue('dob', date); // Set the date object directly
  };





  const isEligibleForSubmission = () => {
    const age = calculateAge(selectedDate);
    const isChildLifelinePlus = selectedProduct === 'Child Lifeline Plus';
    return !isChildLifelinePlus || age < 18;
  };


  const handleRelationshipChange = (event) => {
  const selectedRelationship = event.target.value;
  const selectedOption = relationshipOptions.find((option) => option.description === selectedRelationship);

  if (selectedOption) {
    formik.setFieldValue('relationship', selectedOption.description);
    formik.setFieldValue('gender', ''); 
    setGenderOptions(selectedOption.genderOptions);
  } else {
    formik.setFieldValue('relationship', '');
    formik.setFieldValue('gender', '');
    setGenderOptions([]);
  }
  };

 const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };





  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isEligibleForSubmission()) {
        saveEditedBeneficiary(beneficiaryIndex, values);
      } else {
        toast.error(
          "Beneficiary age must be less than 18 years for Child Lifeline Plus"
        );
      }
    },
  });






useEffect(() => {
  if (initialValues && initialValues.dob) {
    setSelectedDate(new Date(initialValues.dob));
  } else {
    setSelectedDate(null);
  }
}, [initialValues]);



 

  return (
    <>
      <div className="max-w-4xl">
        <Toaster />
    <Modal isOpen={isOpen} onClose={onClose} >
      <ModalHeader>Edit Beneficiary</ModalHeader>
      <ModalBody>
        <div className="flex-1 items-center justify-center">
          <form onSubmit={formik.handleSubmit}>
            {/* First Name */}
            <Label className="mt-4">
              <span>First Name</span>
              <Input
                className="mt-1"
                placeholder="Enter your first name"
                    {...formik.getFieldProps('firstNames')}
                    onChange={(e) => handleInputChange('firstNames', e)}
                valid={formik.touched.firstNames && !formik.errors.firstNames}
              />
              {formik.touched.firstNames && formik.errors.firstNames && (
                <HelperText className="text-red-600">{formik.errors.firstNames}</HelperText>
              )}
            </Label>

            {/* Other Names */}
            <Label className="mt-4">
              <span>Other Names</span>
              <Input
                className="mt-1"
                placeholder="Enter your other names"
                    {...formik.getFieldProps('otherNames')}
                    onChange={(e) => handleInputChange('otherNames', e)}
                valid={formik.touched.otherNames && !formik.errors.otherNames}
              />
              {formik.touched.otherNames && formik.errors.otherNames && (
                <HelperText className="text-red-600">{formik.errors.otherNames}</HelperText>
              )}
            </Label>

            {/* Last Name */}
            <Label className="mt-4">
              <span>Last Name</span>
              <Input
                className="mt-1"
                placeholder="Enter your last name"
                    {...formik.getFieldProps('lastName')}
                   
                    onChange={(e) => handleInputChange('lastName', e)}
                valid={formik.touched.lastName && !formik.errors.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <HelperText className="text-red-500">{formik.errors.lastName}</HelperText>
              )}
            </Label>


                 <Label className="mt-4">
              <span>Date of Birth</span>
           <div className="mt-1">
             <DatePicker
  id="dob"
  selected={selectedDate}
 onChange={(date) => {
              setSelectedDate(date);
              formik.setFieldValue('dob', date); 
            }}
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()}
  customInput={
    <Input
      type="text"
      placeholder="Select date"
      className={formik.touched.dob && formik.errors.dob ? 'error' : ''}
      
    />
  }
  onBlur={() => formik.setFieldTouched('dob', true)}
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
  placeholderText="Select date"
/>

                    </div>
      {formik.touched.dob && formik.errors.dob && (
      <div className="text-red-500">{formik.errors.dob}</div>
      )}
            </Label>


            {/* Relationship */}
            <Label htmlFor="relationship" className="mt-4">
      <span>Relationship</span>
      <Select
    id="relationship"
    className="mt-1"
    {...formik.getFieldProps('relationship')}
    valid={formik.touched.relationship && !formik.errors.relationship}
    onChange={handleRelationshipChange}
      >
    <option value="">Select Beneficiary relationship</option>
    {relationshipOptions.map((option) => (
      <option key={option.code} value={option.description}>
        {option.description}
      </option>
    ))}
  </Select>
  {formik.touched.relationship && formik.errors.relationship && (
    <HelperText className="text-red-500">{formik.errors.relationship}</HelperText>
  )}
</Label>

            {/* Gender */}
            {genderOptions.length > 0 && (
              <Label htmlFor="gender" className="mt-4">
                <span>Gender</span>
                <Select
                  id="gender"
                  className="mt-1"
                  {...formik.getFieldProps('gender')}
                  valid={formik.touched.gender && !formik.errors.gender}
                >
                  <option value="">Select Beneficiary's Gender</option>
                  {genderOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {formik.touched.gender && formik.errors.gender && (
                  <HelperText className="text-red-500">{formik.errors.gender}</HelperText>
                )}
              </Label>
            )}

            {/* Percentage */}
            <Label className="mt-4">
              <span>Percentage Allocation</span>
              <Input
                className="mt-1"
                placeholder="Enter percentage allocation"
                type="number"
                {...formik.getFieldProps('percentage')}
                valid={formik.touched.percentage && !formik.errors.percentage}
                
              />
              {formik.touched.percentage && formik.errors.percentage && (
                <HelperText className="text-red-500">{formik.errors.percentage}</HelperText>
              )}
            </Label>

            <Label className="mt-4">
  <span>Beneficiary Contact Number</span>
  <PhoneInput
    inputComponent={Input}
    placeholder="Enter trustee number"
    defaultCountry="GH"
    international
    countryCallingCodeEditable={false}
    value={formik.values?.contactNumber || ''} 
    onChange={(value) => {
      formik.setFieldValue('contactNumber', value);
      formik.setFieldTouched('contactNumber', true);
    }}
    error={
      (formik.values?.contactNumber &&
        !isValidPhoneNumber(formik.values.contactNumber)
        ? ['Invalid phone number length']
        : [])
    }
  />
  {(formik.touched.contactNumber &&
    formik.errors.contactNumber) ||
    (formik.values?.contactNumber &&
      !isValidPhoneNumber(formik.values.contactNumber)) ? (
    <div className="text-red-600">
      {formik.errors.contactNumber ||
        'Invalid phone number length'}
    </div>
  ) : null}
</Label>

            
           <ModalFooter className="flex justify-center">
              <div className="flex justify-center mt-10">
                <Button
                   onClick={formik.handleSubmit}
                  type="button"
                  
                  style={{ backgroundColor: '#792F7E', color: 'white' , padding: "10px 50px"}}
                >
                  Save
                </Button>
                
              </div>
            </ModalFooter>
          </form>
        </div>
      </ModalBody>
      </Modal>
        </div>
</> );
};

export default EditBeneficiaryModal;
