import React, { useContext, useEffect, useState } from 'react';
// import {useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import PhoneInput, {  isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as Yup from 'yup';
import SectionTitle from '../components/Typography/SectionTitle';
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import OnboardingAlert from '../components/Alerts/OnboardingAlert';
import { Input, HelperText, Label, Select, Button, TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell } from '@windmill/react-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { differenceInYears } from 'date-fns';
import { toast, Toaster } from 'react-hot-toast';
import { EditIcon } from '../icons';
import {TrashIcon} from '../icons';
import EditBeneficiaryModal from './EditBeneficiaryModal';
import { useApplicationContext } from '../context/ApplicationContext';
import { ClientContext } from '../context/ClientContext';



const relationshipOptions = [
  { code: 200222, description: 'SELF', genderOptions: ['MALE','FEMALE'] },
  { code: 200223, description: 'CHILD', genderOptions: ['MALE', 'FEMALE'] },
  { code: 200224, description: 'FATHER', genderOptions: ['MALE'] },
  { code: 200225, description: 'MOTHER', genderOptions: ['FEMALE'] },
  { code: 200327, description: 'NEPHEW', genderOptions: ['MALE'] },
   { code: 200327, description: 'NIECE', genderOptions: ['FEMALE'] },
  { code: 201035, description: 'SPOUSE', genderOptions: ['MALE', 'FEMALE'] },
  { code: 201458, description: 'AUNT', genderOptions: ['FEMALE'] },
  { code: 201459, description: 'BROTHER-IN-LAW', genderOptions: ['MALE'] },
  { code: 201460, description: 'SISTER-IN-LAW', genderOptions: ['FEMALE'] },
  { code: 201461, description: 'UNCLE', genderOptions: ['MALE'] },
  { code: 201462, description: 'COUSIN', genderOptions: ['MALE', 'FEMALE'] },
  { code: 201463, description: 'DAUGHTER', genderOptions: ['FEMALE'] },
  { code: 201464, description: 'SON', genderOptions: ['MALE'] },
  { code: 237, description: "SISTER", genderOptions: ['FEMALE'] },
  { code: 235, description: 'BROTHER', genderOptions: ['MALE'] },
  { code: 201465, description: 'FRIEND', genderOptions: ['MALE', 'FEMALE'] },
  { code: 201466, description: 'GRAND DAUGHTER', genderOptions: ['FEMALE'] },
  { code: 2021247, description: 'STEP SON', genderOptions: ['MALE'] },
  { code: 2021248, description: 'STEP DAUGHTER', genderOptions: ['FEMALE'] }
];


export const BeneficiaryDetails = () => {
    let applicationID = localStorage.getItem('currentApplicationID');

  const [genderOptions, setGenderOptions] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isTotalPercentage100, setIsTotalPercentage100] = useState(false);
  const [hasBeneficiaryUnder18, setHasBeneficiaryUnder18] = useState(false);
  const [editBeneficiaryIndex, setEditBeneficiaryIndex] = useState(null);
  const { updateStepCompletionStatus } = useContext(ClientContext);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { setGlobalBeneficiaryUnder18, isTrusteeAdded, setGlobalTotalBenPercentage } = useApplicationContext()

  const initials = applicationID.substring(0, 3);

   let selectedProduct = null;
   switch (initials) {
    case 'WPP':
      selectedProduct = 'Wealth Master Plus';
       break;
    case 'FWP':
      selectedProduct = 'Fabulous Wealthmaster';
       break;
    case 'PWP':
      selectedProduct = 'Phobia Wealth Master';
       break;
    case 'CLP':
      selectedProduct = 'Child Lifeline Plus';
       break;
    case 'FCL':
      selectedProduct = 'Fabulous Child Education';
       break;
    case 'PCL':
      selectedProduct = 'Phobia Child Education';
      break;
    case 'UPP':
      selectedProduct = 'Ultimate Protection Plus';
       break;
    case 'FUP':
      selectedProduct = 'Fabulous Ultimate Protection';
       break;
    case 'PUP':
      selectedProduct = 'Phobia Ultimate Protection';
       break;
    case 'EHP':
      selectedProduct = 'Esteem Homecall Plus';
       break;
    case 'FEH':
      selectedProduct = 'Fabulous Homecall';
       break;
    case 'PEH':
      selectedProduct = 'Phobia Homecall';
       break;
    case 'SUH':
      selectedProduct = 'Supreme Homecall Plan';
       break;
    case 'CBP':
      selectedProduct = 'Cashbuilder Plus';
       break;
    case 'FPP':
      selectedProduct = 'Family Protection Plan';
      break;
    default:
      selectedProduct = 'Unknown Product';
  }

const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
    const matchingApplication = storedOngoingApplications.find(app => app.id === applicationID);
  
  const beneficiary = matchingApplication.data.beneficiaryData




const initialValues = {
  firstNames:  '',
  otherNames:  '',
  lastName: '',
  dob: null,
  gender: '',
  relationship: '',
  percentage: '',
  contactNumber: '',
};


  const validationSchema =Yup.object().shape({
    firstNames: Yup.string().required('First Name is required'),
    otherNames: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    dob: Yup.string().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required'),
    relationship: Yup.string().required('Relationship is required'),
    percentage: Yup.number()
      .required('Percentage allocation is required')
      .positive('Percentage must be a positive number')
      .max(100, 'Total percentage must not exceed 100'),
    contactNumber: Yup.string().required('Beneficiary Contact Number is required'),
  });


const handleRelationshipChange = (event) => {
  const selectedRelationship = event.target.value;
  const selectedOption = relationshipOptions.find((option) => option.description === selectedRelationship);

  if (selectedOption) {
    formik.setFieldValue('relationship', selectedOption.description);
    formik.setFieldValue('gender', ''); // Reset gender field when relationship changes
    setGenderOptions(selectedOption.genderOptions);
  } else {
    formik.setFieldValue('relationship', '');
    formik.setFieldValue('gender', '');
    setGenderOptions([]);
  }
  };
  
 const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };

  


   const openEditModal = (index) => {
    setEditBeneficiaryIndex(index);
    setEditModalOpen(true);
  };


const handleEditSubmit = (editedIndex, editedValues) => {
  const ageOver18 = calculateAge(editedValues.dob) >= 18;
  const ageUnder18 = !ageOver18;

  if (['CLP', 'FCL', 'PCL'].includes(initials) && ageOver18) {
  const productName = selectedProduct || 'Unknown Product';
  toast.error(`Beneficiary age must be less than 18 years for ${productName}`);
  return;
}


  const updatedBeneficiaries = beneficiaries.map((beneficiary, index) =>
    index === editedIndex ? { ...beneficiary, ...editedValues } : beneficiary
  );

  const newPercentageTotal = updatedBeneficiaries.reduce(
    (total, beneficiary) => total + beneficiary.percentage,
    0
  );

  if (newPercentageTotal > 100) {
    toast.error("Total percentage cannot exceed 100%");
    return;
  }

  setHasBeneficiaryUnder18(ageUnder18);
  setGlobalBeneficiaryUnder18(ageUnder18);
  setBeneficiaries(updatedBeneficiaries);

  const updatedBeneficiaryData = [
    {
      totalBenPercentage: newPercentageTotal,
      beneficiaries: updatedBeneficiaries,
    },
  ];

  updateLocalStorage(updatedBeneficiaryData);

  setEditModalOpen(false);
  setEditBeneficiaryIndex(null);
};






const updateLocalStorage = (newBeneficiaries) => {
  // Retrieve the ongoing applications from localStorage
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

  // Find the application to update using its ID
  const applicationIndex = storedOngoingApplications.findIndex(app => app.id === applicationID);

  if (applicationIndex !== -1) {
    // Calculate the total percentage for the new beneficiaries
    const totalPercentage = newBeneficiaries.reduce((total, beneficiary) => total + beneficiary.percentage, 0);

    // Prepare the updated beneficiary data
    const updatedBeneficiaryData = {
      totalBenPercentage: totalPercentage,
      beneficiaries: newBeneficiaries,
    };

    // Directly update the found application with the new beneficiary data
    storedOngoingApplications[applicationIndex].data.beneficiaryData = updatedBeneficiaryData;

    // Save the updated array back to localStorage
    localStorage.setItem('ongoingApplications', JSON.stringify(storedOngoingApplications));
  }
};

  

const calculateAge = (dob) => {
    return differenceInYears(new Date(), dob);
  };


  
  
  




 const formik = useFormik({
  initialValues,
  validationSchema,
   onSubmit: async (values) => {
     const age = calculateAge(values.dob);
     
     if (['CLP', 'FCL', 'PCL'].includes(initials) && age >= 18) {
       const productName = selectedProduct || 'Unknown Product';
    toast.error(`Beneficiary age must be less than 18 years for ${productName}`);
    return;
  }
   
    if (age < 18) {
      setHasBeneficiaryUnder18(true);
    } else {
      setHasBeneficiaryUnder18(false);
     }
     
     
    const newPercentageTotal = beneficiaries.reduce(
      (total, beneficiary) => total + beneficiary.percentage,
      values.percentage
    );

    if (newPercentageTotal > 100) {
      toast.error("Total percentage cannot exceed 100%");
      return;
    }

    // Proceed to add the new beneficiary to the beneficiaries array
    const newBeneficiary = {
      firstNames: values.firstNames,
      otherNames: values.otherNames,
      lastName: values.lastName,
      dob: values.dob,
      
      gender: values.gender,
      relationship: values.relationship,
      percentage: values.percentage,
      contactNumber: values.contactNumber,
    };

    const updatedBeneficiaries = [...beneficiaries, newBeneficiary];
    setBeneficiaries(updatedBeneficiaries);

    // Update local storage
    const updatedBeneficiaryData = [
      {
        totalBenPercentage: newPercentageTotal,
        beneficiaries: updatedBeneficiaries,
      },
    ];

     updateLocalStorage(updatedBeneficiaryData);
     updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 4 : 3, true);
     formik.resetForm();
     
    
  },
});

  const deleteBeneficiary = (index) => {
  
  const updatedBeneficiaries = [...beneficiaries];
  updatedBeneficiaries.splice(index, 1);
  setBeneficiaries(updatedBeneficiaries);

  updateLocalStorage(updatedBeneficiaries);
};



// ...

useEffect(() => {
  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));

  // Find the application directly without flattening
  const matchingApplication = cachedOngoingApplications.find(application => application.id === applicationID);

  if (matchingApplication) {
    const savedBeneficiaryData = matchingApplication.data.beneficiaryData;
    console.log("savedBeneficiaryData", savedBeneficiaryData);

    if (savedBeneficiaryData) {
      // Assuming savedBeneficiaryData structure contains the array directly
      const beneficiariesArray = savedBeneficiaryData.beneficiaries;

      if (beneficiariesArray) {
        setBeneficiaries(beneficiariesArray);

        // Check if totalBenPercentage is 100
        setIsTotalPercentage100(savedBeneficiaryData.totalBenPercentage === 100);

        const beneficiaryUnder18 = beneficiariesArray.some(beneficiary => {
          const age = calculateAge(new Date(beneficiary.dob));
          return age < 18;
        });
        setHasBeneficiaryUnder18(beneficiaryUnder18);
      } else {
        // Handle the case where beneficiariesArray does not exist
        setBeneficiaries([]);
        setIsTotalPercentage100(false);
        setHasBeneficiaryUnder18(false);
        setGlobalBeneficiaryUnder18(false);
      }
    }
  }
}, [applicationID]);



// ...

useEffect(() => {
  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));
  // Find the specific application directly instead of mapping through all applications
  const applicationToUpdate = cachedOngoingApplications.find(app => app.id === applicationID);

  if (applicationToUpdate) {
    const totalPercentage = beneficiaries.reduce((total, beneficiary) => total + beneficiary.percentage, 0);
    setGlobalTotalBenPercentage(totalPercentage);
    setIsTotalPercentage100(totalPercentage === 100);

    // Update beneficiary data directly within the found application
    const updatedBeneficiaryData = {
      totalBenPercentage: totalPercentage,
      beneficiaries: beneficiaries,
      trustee: applicationToUpdate.data.beneficiaryData?.trustee || [],
    };

    // Update the application directly with the new beneficiary data
    applicationToUpdate.data.beneficiaryData = updatedBeneficiaryData;

    // Reflect changes in global state if necessary
    const beneficiaryUnder18 = beneficiaries.some(beneficiary => {
      const age = calculateAge(new Date(beneficiary.dob));
      return age < 18;
    });
    setHasBeneficiaryUnder18(beneficiaryUnder18);
    setGlobalBeneficiaryUnder18(beneficiaryUnder18);

    // Update the step completion status accordingly
    updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 4 : 3, true);

    // Update localStorage with the modified array
    localStorage.setItem('ongoingApplications', JSON.stringify(cachedOngoingApplications));
  }
}, [beneficiaries, applicationID]);




useEffect(() => {
    
    setGlobalBeneficiaryUnder18(hasBeneficiaryUnder18);
  }, [hasBeneficiaryUnder18]);



  return (
    <div className=" py-3 mb-8 flex flex-col mr-10 justify-start mt-10">
      <Toaster />
      <div className="mx-6 flex-auto  justify-start">
        
        <OnboardingAlert />
       
        <SectionTitle textAlign="center">Beneficiary Details</SectionTitle>
         <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-8">

            <div className="col-span-1 flex flex-col ">
              
              
        <Label className="mt-4">
          <span>First Name</span>
          <Input
            className="mt-1"
            placeholder="Enter your first name"
                {...formik.getFieldProps('firstNames')}
                onChange={(e) => handleInputChange('firstNames', e)}
            valid={formik.touched.firstNames && !formik.errors.firstNames}
          />
          {formik.touched.firstNames && formik.errors.firstNames && (
            <HelperText className="text-red-600">{formik.errors.firstNames}</HelperText>
          )}
        </Label>

        {/* Other Names */}
        <Label className="mt-4">
          <span>Other Names</span>
          <Input
            className="mt-1"
            placeholder="Enter your other names"
                {...formik.getFieldProps('otherNames')}
                onChange={(e) => handleInputChange('otherNames', e)}
            valid={formik.touched.otherNames && !formik.errors.otherNames}
          />
          {formik.touched.otherNames && formik.errors.otherNames && (
            <HelperText className="text-red-600">{formik.errors.otherNames}</HelperText>
          )}
        </Label>

        {/* Last Name */}
        <Label className="mt-4">
          <span>Last Name</span>
          <Input
            className="mt-1"
            placeholder="Enter your last name"
                {...formik.getFieldProps('lastName')}
                onChange={(e) => handleInputChange('lastName', e)}
            valid={formik.touched.lastName && !formik.errors.lastName}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <HelperText className="text-red-500">{formik.errors.lastName}</HelperText>
          )}
        </Label>

        {/* Date of Birth */}
         <Label htmlFor="dob" className="mt-4">
              <span>Date of Birth</span>
           <div className="mt-1">
           <DatePicker
          id="dob"
      selected={formik.values.dob}
      onChange={(date) => formik.setFieldValue('dob', date)}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
      customInput={
        <Input
          type="text"
          placeholder="Select date"
          className={formik.touched.dob && formik.errors.dob ? 'error' : ''}
          value={formik.values.dob ? formik.values.dob.toString() : ''}
          
        />
      }
      onBlur={() => formik.setFieldTouched('dob', true)}
      showMonthDropdown
      showYearDropdown
                dropdownMode="select"
                placeholderText="Select date"
      />
      </div>
      {formik.touched.dob && formik.errors.dob && (
      <div className="text-red-500">{formik.errors.dob}</div>
      )}
            </Label>
            
            </div>
            
            <div className="col-span-1 flex flex-col ">


                {/* Relationship */}
       <Label htmlFor="relationship" className="mt-4">
      <span>Relationship</span>
      <Select
    id="relationship"
    className="mt-1"
    {...formik.getFieldProps('relationship')}
    valid={formik.touched.relationship && !formik.errors.relationship}
    onChange={handleRelationshipChange}
      >
    <option value="">Select Beneficiary relationship</option>
    {relationshipOptions.map((option) => (
      <option key={option.code} value={option.description}>
        {option.description}
      </option>
    ))}
  </Select>
  {formik.touched.relationship && formik.errors.relationship && (
    <HelperText className="text-red-500">{formik.errors.relationship}</HelperText>
  )}
</Label>

{/* Gender */}
{genderOptions.length > 0 && (
  <Label htmlFor="gender" className="mt-4">
    <span>Gender</span>
    <Select
      id="gender"
      className="mt-1"
      {...formik.getFieldProps('gender')}
                  valid={formik.touched.gender && !formik.errors.gender}
                  onChange={(e) => handleInputChange('gender', e)}
    >
      <option value="">Select Beneficiary's Gender</option>
      {genderOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
    {formik.touched.gender && formik.errors.gender && (
      <HelperText className="text-red-500">{formik.errors.gender}</HelperText>
    )}
  </Label>
)}

        

        {/* Percentage */}
       <Label className="mt-4">
  <span>Percentage Allocation</span>
  <Input
    className="mt-1"
    placeholder="Enter percentage allocation"
    type="number"
    {...formik.getFieldProps('percentage')}
    valid={formik.touched.percentage && !formik.errors.percentage}
    onChange={(e) => {
      formik.handleChange(e);
      
    }}
  />
  {formik.touched.percentage && formik.errors.percentage && (
    <HelperText className="text-red-500">{formik.errors.percentage}</HelperText>
  )}
</Label>

                
                  <Label className="mt-4">
  <span>Beneficiary Contact Number</span>
 <PhoneInput
inputComponent={Input}
  placeholder="Enter beneficiary number"
  defaultCountry="GH"
  international
  countryCallingCodeEditable={false}
  value={formik.values.contactNumber}
  
                    onChange={(value) => {
                      
    formik.setFieldValue('contactNumber', value);
    formik.setFieldTouched('contactNumber', true); 
  }}
  error={
      (formik.values.contactNumber &&
        !isValidPhoneNumber(formik.values.contactNumber)
        ? ['Invalid phone number length']
        : [])
    }
/>

  {(formik.touched.contactNumber &&
    formik.errors.contactNumber) ||
    (formik.values.contactNumber &&
      !isValidPhoneNumber(formik.values.contactNumber)) ? (
    <div className="text-red-600">
      {formik.errors.contactNumber ||
        'Invalid phone number length'}
    </div>
  ) : null}
</Label>

            </div>

<div className="col-span-2 flex mt-12">
            <Button disabled={isTotalPercentage100}  onClick={formik.handleSubmit} type="submit" className="text-white px-4 py-2 rounded hover:bg-gray-600" style={{backgroundColor: "#792F7E"}} >
              Add New Beneficiary
            </Button>
          </div>

          </div>
           </form>
          
      


      <div className="col-span-2 mt-12">
  {beneficiaries.length > 0 && (
    <div>
      <SectionTitle>Beneficiary(ies) Added</SectionTitle>
      <TableContainer className="mt-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Relationship</TableCell>
              <TableCell>Percentage</TableCell>
              <TableCell>Contact Number</TableCell>
              <TableCell>Action</TableCell>
             
              <TableCell></TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {beneficiaries.map((beneficiary, index) => (
              <TableRow key={index}>
                <TableCell>{beneficiary.firstNames}</TableCell>
                <TableCell>{beneficiary.lastName}</TableCell>
                <TableCell>{new Date(beneficiary.dob).toLocaleDateString('en-GB')}</TableCell>

                <TableCell>{beneficiary.gender}</TableCell>
                <TableCell>{beneficiary.relationship}</TableCell>
                <TableCell>{beneficiary.percentage}</TableCell>
                <TableCell>{beneficiary.contactNumber}</TableCell>
               
                <TableCell>
                  <TableCell className="flex items-center">
                    <TrashIcon
                      className="w-8 h-8 mr-8"
                      style={{ cursor: 'pointer', fill: "#dc2626" }}
                      onClick={() => deleteBeneficiary(index)}
                    />
                    <EditIcon
                      className="w-8 h-8"
                      style={{ cursor: 'pointer' }}
                      onClick={() => openEditModal(index)}
                    />
                  </TableCell>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
              <div className='mb-10'>
               
              </div>
               {hasBeneficiaryUnder18 && !isTrusteeAdded && (
                <Alert
                  color="warning"
                  rounded
                   withBorderAccent
                icon={HiInformationCircle}
                   >
                 <span>
                   <p className="font-medium">
                 
                       Warning alert! Take Note! One or more of your beneficiaries is/are less than the legal age of 18 years. Please you must add a trustee on the next page.
                       </p>
                     </span>
                  </Alert>
                      )} 
                

              
            </div>
          )}
           


        </div>
      </div>
      
<EditBeneficiaryModal
  isOpen={editModalOpen}
  onClose={() => setEditModalOpen(false)}
  beneficiaryIndex={editBeneficiaryIndex}
  initialValues={beneficiaries[editBeneficiaryIndex]} // Make sure this is correct
  saveEditedBeneficiary={handleEditSubmit}
        validationSchema={validationSchema}
        relationshipOptions={relationshipOptions}
        selectedProduct ={selectedProduct }
/>



      </div>
  );
};

export default BeneficiaryDetails;
