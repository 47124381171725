export const paymentFrequency = [
  {
    freqCode: 'A',
    freqShtDesc: 'A',
    freqDesc: 'ANNUALLY',
  },
  {
    freqCode: 'M',
    freqShtDesc: 'M',
    freqDesc: 'MONTHLY',
  },
  {
    freqCode: 'Q',
    freqShtDesc: 'Q',
    freqDesc: 'QUARTERLY',
  },
  {
    freqCode: 'S',
    freqShtDesc: 'S',
    freqDesc: 'SEMI-ANNUALLY',
  },
];