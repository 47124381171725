import React, { useState, useRef, useEffect, useContext, forwardRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert } from 'flowbite-react';
import {useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { format, parse, parseISO, set, addDays } from 'date-fns';
import {toast, Toaster} from 'react-hot-toast';
import { Input, HelperText, Label, Select, Button } from '@windmill/react-ui';
import { ClearIcon } from '../icons';
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import SectionTitle from '../components/Typography/SectionTitle';
import SignatureCanvas from 'react-signature-canvas';
import { institutions } from '../constants/institutions';
import { paymentModes } from '../constants/paymentModes';
import OnboardingAlert from '../components/Alerts/OnboardingAlert';
import Confirmation from './Confirmation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PaymentDataContext } from '../context/PaymentDataContext'; 
import ImagePreview from '../components/ImagePreview/ImagePreview';
import { SignatureContext } from '../context/SignatureContext'; 
import { titles } from '../constants/titles';
import { Spinner } from 'flowbite-react'
import { banks } from '../constants/banks';
 import { ClientContext } from '../context/ClientContext';
import { occupations } from '../constants/occupations';
import { paymentFrequency } from '../constants/paymentFrequencies';

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiKey = process.env.REACT_APP_API_KEY;
const apiUser = process.env.REACT_APP_API_USER;



const PaymentDetails = () => {
  let applicationID = localStorage.getItem('currentApplicationID');
  const initials = applicationID.substring(0, 3);
  const [title, setTitle] = useState('');
  const [bankBranches, setBankBranches] = useState([]);
  const [signatureRef, setSignatureRef] = useState(null);
   const [bankNameVerification, setBankNameVerification] = useState('');
  const [momoNameVerification, setMomoNameVerification] = useState('');
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedBankBranchCode, setSelectedBankBranchCode] = useState('');
  const [paymentData, setPaymentData] = useState({});
  const { signatureValue, setSignatureValue } = useContext(SignatureContext);
  const { updateStepCompletionStatus } = useContext(ClientContext);
  const [agnCode, setAgnCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastVerifiedAccountNumber, setLastVerifiedAccountNumber] = useState('');
  const [lastVerifiedMomoNumber, setLastVerifiedMomoNumber] = useState('');
  const location = useLocation();


  



  const [signed, setSigned] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const  [paymentFreqSet, setPaymentFreqSet] = useState(false);


  const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
const matchingApplication = ongoingApplications.find(application => application.id === applicationID);



const newClientCode = matchingApplication?.data.clientData.New_Client_Code || null;
const oldClientCode = matchingApplication?.data.clientData.existingClientCode || null;

  

  
  
const initialValues = {
    paymentFrequency: matchingApplication?.data?.productDetailsData?.quoteDetails?.paymentFrequency || '',
  paymentMode: paymentData.paymentMode || '',
  bank: paymentData.bank || '',
  bankBranch: paymentData.bankBranch || '',
  institution: paymentData.institution || '',
  accountNumber: paymentData.accountNumber || '',
firstPaymentDate: paymentData.firstPaymentDate || null,
  bankAccountName: paymentData.bankAccountName || '',
  signature: paymentData.signature || '',
  signatureImage: paymentData.signatureImage || '',
  momoNumber: paymentData.momoNumber || '',
  momoAccountName: paymentData.momoAccountName || '',
  network: paymentData.network || '',
  staffID: paymentData.staffID || '',
  cash: paymentData.cash || '',
  signaturePreference: paymentData.signaturePreference || '',
};


  

  const validationSchema = Yup.object().shape({

signaturePreference: Yup.string().required('Signature Preference is required'),
signature: Yup.lazy((value) => {
  return value === 'signature' ? Yup.string().required('Signature is required') : Yup.string();
}),
signatureImage: Yup.lazy((value) => {
  return value === 'signatureImage' ? Yup.mixed().required('Signature image is required') : Yup.mixed();
}),


  paymentFrequency: Yup.string().required('Payment Frequency is required'),
  paymentMode: Yup.string().required('Payment Mode is required'),
  bank: Yup.number().when('paymentMode', {
    is: (mode) => mode === 'Bank',
    then: (schema) => schema.required('Bank is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  bankBranch: Yup.string().when('paymentMode', {
    is: (mode) => mode === 'Bank',
    then: (schema) => schema.required('Bank Branch is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  institution: Yup.string().when('paymentMode', {
    is: (mode) => mode === 'Institution',
    then: (schema) => schema.required('Institution is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  staffID: Yup.string().when('paymentMode', {
    is: (mode) => mode === 'Institution',
    then: (schema) => schema.required('Your Staff ID  is required'),
    otherwise: (schema) => schema.notRequired(),
  }),


    accountNumber: Yup.number().when('paymentMode', {
    is: (mode) => mode === 'Bank',
    then: (schema) => schema.required('Account Number is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  
    momoNumber: Yup.number().when('paymentMode', {
    is: (mode) => mode === 'Mobile Money',
    then: (schema) => schema.required('Mobile Money Number is required'),
    otherwise: (schema) => schema.notRequired(),
    }),
    
    network: Yup.string().when('paymentMode', {
    is: (mode) => mode === 'Mobile Money',
    then: (schema) => schema.required('Please select mobile money carrier'),
    otherwise: (schema) => schema.notRequired(),
  }),
    firstPaymentDate: Yup.date().nullable().required('First Payment Date is required'),

    bankAccountName: Yup.string().when('paymentMode', {
    is: (mode) => mode === 'Bank',
    then: (schema) => schema.required('Please Enter the name associated with the account'),
    otherwise: (schema) => schema.notRequired(),
    }),
    
    momoAccountName: Yup.string().when('paymentMode', {
    is: (mode) =>  mode === 'Mobile Money',
    then: (schema) => schema.required('The name associated with the account'),
    otherwise: (schema) => schema.notRequired(),
  }),

});

    const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };

  const getBankName = (code) => {
  const bank = banks.find((item) => item.bnkCode === code);
  return bank ? bank.bnkBankName : 'Unknown Bank';
  };
  


 // Function to verify bank account number
  const verifyBankAccountNumber = async (accountNumber, bankName) => {
    setVerificationLoading(true);
    try {
      const payload = {
        Account_Number: accountNumber,
        Bank_Name: bankName,
        Api_User: apiUser,
        Api_Key: apiKey,
      };
      const response = await axios.post(`https://slac-api.starlifecentral.com/StarLife/SL_VerifyBankAccountNumber`, payload);
      setBankNameVerification(response.data.data.account_name);
      formik.setFieldValue('bankAccountName', response.data.data.account_name);
    } catch (error) {
      console.error(error);
     
    }
    setVerificationLoading(false);
  };

  const verifyMomoNumber = async (phoneNumber, networkName) => {
    setVerificationLoading(true);
    try {
      const payload = {
        Phone_Number: formatPhoneNumber(phoneNumber).replace(/\s/g, ''),
        Network_Name: networkName,
        Api_User: apiUser,
        Api_Key: apiKey,
      };
      const response = await axios.post(`https://slac-api.starlifecentral.com/StarLife/SL_VerifyMomoNumber`, payload);
      setMomoNameVerification(response.data.data.account_name);
      formik.setFieldValue('momoAccountName', response.data.data.account_name);
    } catch (error) {
      console.error(error);
    }
    setVerificationLoading(false);
  };


  const formik = useFormik({
    initialValues,
    validationSchema,

       enableReinitialize: true,    
    validationSchema,
    onSubmit: async (values) => {

      
     
     console.log('payment values are.......', values);
     localStorage.setItem('client_signature', signatureValue);

     
     const selectedPaymentMode = paymentModes.find(
      (mode) => mode.mode === values.paymentMode
    );
     const paymentModeCode = selectedPaymentMode ? selectedPaymentMode.code : '';
     

     const selectedInstitution = institutions.find(
      (institutions) => institutions.Institution_Name === values.institution
    );
     const institutionCode = selectedInstitution ? selectedInstitution.Institution_Code : '';
     console.log('institutionCode', institutionCode);
       
     


      try {
        setLoading(true)

        const payload = {
          Web_Quote_Code: matchingApplication?.data?.productDetailsData?.quoteDetails?.quote_code.toString(),
         Web_Client_Code: matchingApplication?.data?.clientData?.New_Client_Code || null,
          Client_Code: matchingApplication?.data?.clientData?.existingClientCode || null,
          Client_Agent_Code: agnCode,
          Title: title || '',
          First_Name: matchingApplication?.data?.clientData?.firstName,
          Other_Name: matchingApplication?.data?.clientData?.otherNames,
          Surname: matchingApplication?.data?.clientData?.lastName,
          DOB: matchingApplication.data.clientData.dob ? format(matchingApplication.data.clientData.dob.includes('T') ? parseISO(matchingApplication.data.clientData.dob) : parse(matchingApplication.data.clientData.dob, 'dd-MM-yyyy', new Date()), 'dd-MM-yyyy') : '',


          Gender: matchingApplication?.data?.clientData?.gender?.charAt(0).toUpperCase() ?? '',
          Marital_Status: matchingApplication?.data?.clientData?.maritalStatus || '',
          ID_Type: matchingApplication.data.clientData.idType?.toUpperCase() ?? 'GHANA CARD',
          ID_Number: matchingApplication.data.clientData.ghanaCardNumber?.toString() ?? '',
          Occupation: matchingApplication.data.clientData.occupation?.toString()?? '',
          Phone_Number: formatPhoneNumber(matchingApplication.data.clientData.mobileNumber).replace(/\s/g, ''),
          Tel_Number: formatPhoneNumber(matchingApplication.data.clientData.mobileNumber).replace(/\s/g, ''),
          Postal_Address: matchingApplication?.data?.clientData?.clientPostalAddress,
          Residential_Address: matchingApplication?.data?.clientData?.residentialAddress,
          Email: matchingApplication?.data?.clientData?.email,
          Client_Type: 'I',
          Country_Code: 95,
          Nationality: 'GHANAIAN',
          Religion: null,
          Highest_Education: null,
          Professional_Qualification: null,
          Income: null,
          Form_Of_Employment: null,
          Bank_Account_No: values.accountNumber || null,
          Bank_Account_Name: values.bankAccountName|| null,
          Bank_Account_Type: null,
          Bank_Branch_Code: selectedBankBranchCode.toString() || null,
          Payroll_No: values.staffID||null,
          Institution: values.institution||null,
          Payment_Mode: paymentModeCode,
          Payment_Debit_Day: 25,
      Payment_Start_Date: formik.values.firstPaymentDate
    ? `25-${format(parseISO(formik.values.firstPaymentDate), 'MM-yyyy')}`
    : '',
          
          Api_User: apiUser,
          Api_Key: apiKey,

        };

        const response = await axios.post(`${apiUrl}/SL_AdditionalInfo`, payload);
        

        if (response.data.webClntCode || response.data.clntCode) {
          formik.setValues({
      ...formik.values,  
      firstPaymentDate: values.firstPaymentDate,  
     
    });
          toast.success('Payment details added successfully. Please proceed to confirm your proposal application',
            {
              position: 'top-center',
              duration: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          setLoading(false);
          updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 7 : 6, true);
           

          console.log('Payment details added successfully.');
        }
        
      } catch (error) {
        console.log('Payment details error', error);
        toast.error(error,
          {
            position: 'top-center',
            duration: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            draggable: true,

          });
        updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 7 : 6, false);
      }
    },
  });

  const clearSignature = () => {
  if (signatureRef) {
    signatureRef.clear();
    setSignatureValue(null);
    localStorage.removeItem('client_signature');
  }
  };

  const handleSignatureChange = () => {
    if (signatureRef) {
      const newSignatureValue = signatureRef.toDataURL();
      setSignatureValue(newSignatureValue);
      localStorage.setItem('client_signature', newSignatureValue);
    }
  };

  


  const fetchBankBranches = async (bnkCode) => {
    try {

      const payload = {
        Api_User: apiUser,
        Api_Key: apiKey,
        Bank_Code: bnkCode.toString(),
      };
      const response = await axios.post(`${apiUrl}/SL_BankBranches`, payload);
      const fetchedBranches = response.data;
      const firstBranchCode = fetchedBranches.length > 0 ? fetchedBranches[0].bbrCode : '';
      setSelectedBankBranchCode(firstBranchCode);
      console.log(response.data);
      const branchNames = response.data.map((branch) => branch.bbrBranchName);
      setBankBranches(branchNames);
    } catch (error) {
      console.error(error);
    }
  };

useEffect(() => {
    if (initialValues.firstPaymentDate) {
      setSelectedDate(new Date(initialValues.firstPaymentDate));
    }
  }, [initialValues.firstPaymentDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    formik.setFieldValue('firstPaymentDate', date ? date.toISOString() : null);
  };

  const [selectedDate, setSelectedDate] = useState(null);


  const handlePaymentModeChange = (event) => {
    const selectedMode = event.target.value;
    formik.setFieldValue('paymentMode', selectedMode);
  };

   const handleBankChange = (event) => {
    const selectedBankCode = parseInt(event.target.value);
    formik.setFieldValue('bank', selectedBankCode);
    fetchBankBranches(selectedBankCode);
  };

  


useEffect(() => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const matchingApplication = storedOngoingApplications.find(application => application.id === applicationID);

  if (matchingApplication && matchingApplication.data && matchingApplication.data.paymentData) {
    setPaymentData(matchingApplication.data.paymentData);

    const matchingPaymentFrequency = paymentFrequency.find(
      (freq) => freq.freqDesc === matchingApplication.data.productDetailsData.quoteDetails.paymentFrequency
    );

    if (matchingPaymentFrequency) {
      formik.setFieldValue('paymentFrequency', matchingPaymentFrequency.freqDesc);
    }
  } else {
    setPaymentData({});
  }
}, []);






   
 

  

useEffect(() => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

  if (formik.dirty && storedOngoingApplications.length > 0) {
    const updatedApplications = storedOngoingApplications.map(application => {
      if (application.id === applicationID) {
        return {
          ...application,
          data: {
            ...application.data,
            paymentData: formik.values,
          },
        };
      }
      return application;
    });

    localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));
  }
}, [applicationID, paymentData, formik.values, formik.dirty]);


  useEffect(() => {
    const savedSignature = localStorage.getItem('client_signature');
    if (savedSignature) {
      setSignatureValue(savedSignature);
    }
  }, []);


    useEffect(() => {
  const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

  // Directly find the matching application without a grouping concept
  const matchingApplication = ongoingApplications.find(application => application.id === applicationID);

  if (matchingApplication) {
    const titleCode = parseInt(matchingApplication?.data?.clientData?.title, 10);
    const titleInfo = titles.find((item) => item.Code === titleCode);
    if (titleInfo) {
      setTitle(titleInfo.Title);
    }
  }
}, [applicationID]); // Depend on applicationID to re-run this effect when it changes






  const handleSignaturePreferenceChange = (event) => {
  const preference = event.target.value;
  formik.setFieldValue('signaturePreference', preference);
};




 
  
useEffect(() => {
        // Fetch the agent code from localStorage
        const agentCode = JSON.parse(localStorage.getItem('agentData')).agent_code;
        const payload = {
            "Agn_Code": agentCode,
            "Api_User": apiUser,
            "Api_Key": apiKey,
        };

        // Make the API request
        axios.post(`${apiUrl}/SL_FindAgency`, payload)
            .then(response => {
                const agnCode = response.data.agnCode;
                setAgnCode(agnCode);
            })
            .catch(error => {
                console.error('Error:', error);
            });
}, []);
  
  
  
  useEffect(() => {
    const currentAccountNumber = formik.values.accountNumber;
    const bankCode = formik.values.bank;

   
    if (bankCode && currentAccountNumber && currentAccountNumber !== lastVerifiedAccountNumber) {
      const bankName = getBankName(parseInt(bankCode));
      
      if (bankName && bankName !== 'Unknown Bank') {
        verifyBankAccountNumber(currentAccountNumber, bankName);
        setLastVerifiedAccountNumber(currentAccountNumber); 
      }
    }
  }, [formik.values.accountNumber, formik.values.bank]); 


  useEffect(() => {
    const currentMomoNumber = formik.values.momoNumber;
    const networkName = formik.values.network;

   
    if (networkName && currentMomoNumber && currentMomoNumber !== lastVerifiedMomoNumber) {
      verifyMomoNumber(currentMomoNumber, networkName);
      setLastVerifiedMomoNumber(currentMomoNumber); 
    }
  }, [formik.values.momoNumber, formik.values.network]);





  

  return (
   <div className=" py-3 mb-8 flex flex-col mr-10 justify-start mt-10">
       <div className="mx-6 flex-auto  justify-start">
        <Toaster />
        <OnboardingAlert />
        <SectionTitle textAlign="center">Payment Details</SectionTitle>
        
        <form onSubmit={formik.handleSubmit}>

          <div className="grid grid-cols-2 gap-8 mb-8">
            


            <div className="col-span-1 flex flex-col ">


              
          <Label className="mt-4">
            <span>Payment Mode</span>
            <Select
              className="mt-1"
              placeholder="Select Payment Mode"
              {...formik.getFieldProps('paymentMode')}
              valid={formik.touched.paymentMode && !formik.errors.paymentMode}
              onChange={handlePaymentModeChange}
            >
              <option value="">Select Payment Mode</option>
              <option value="Bank">BANK</option>
              <option value="Institution">INSTITUTION</option>
              <option value="Mobile Money">MOBILE MONEY</option>
              <option value="Cash">CASH PAYMENT</option>
            </Select>
            {formik.touched.paymentMode && formik.errors.paymentMode && (
              <HelperText className="text-red-600">{formik.errors.paymentMode}</HelperText>
            )}
          </Label>

          {formik.values.paymentMode === 'Bank' && (
            <>
              <Label className="mt-4">
                <span>Bank</span>
                <Select
                  className="mt-1"
                  placeholder="Select Bank"
                  {...formik.getFieldProps('bank')}
                  valid={formik.touched.bank && !formik.errors.bank}
                  onChange={handleBankChange}
                >
                  <option value="">Select Bank</option>
                  {banks.map((bank) => (
                    <option key={bank.bnkCode} value={bank.bnkCode}>
                      {bank.bnkBankName}
                    </option>
                  ))}
                </Select>
                {formik.touched.bank && formik.errors.bank && (
                  <HelperText className="text-red-600">{formik.errors.bank}</HelperText>
                )}
              </Label>

              <Label className="mt-4">
                <span>Bank Branch</span>
                <Select
                  className="mt-1"
                  placeholder="Select Bank Branch"
                  {...formik.getFieldProps('bankBranch')}
                  valid={formik.touched.bankBranch && !formik.errors.bankBranch}
                >
                  <option value="">Select Bank Branch</option>
                  {bankBranches.map((branch, index) => (
                    <option key={index} value={branch}>
                      {branch}
                    </option>
                  ))}
                </Select>
                {formik.touched.bankBranch && formik.errors.bankBranch && (
                  <HelperText className="text-red-600">{formik.errors.bankBranch}</HelperText>
                )}
              </Label>
            </>
        )}
        
        {formik.values.paymentMode === 'Bank' && (
  <>
    <Label className="mt-4">
      <span>Account Number</span>
      <Input
        className="mt-1"
        placeholder="Enter Your Bank Account Number"
        {...formik.getFieldProps('accountNumber')}
        valid={formik.touched.accountNumber && !formik.errors.accountNumber}
      />
      {formik.touched.accountNumber && formik.errors.accountNumber && (
        <HelperText className="text-red-600">{formik.errors.accountNumber}</HelperText>
      )}
    </Label>
    {bankNameVerification && (
      <Alert color="success" className="text-sm mt-2">
        Bank account number verified successfully as {bankNameVerification}
      </Alert>
    )}
  </>
)}

              
                 
              
              {formik.values.paymentMode === 'Institution' && (
            <Label className="mt-4">
              <span>Institution</span>
              <Select
                className="mt-1"
                placeholder="Select Institution"
                {...formik.getFieldProps('institution')}
                valid={formik.touched.institution && !formik.errors.institution}
              >
                <option value="">Select Institution</option>
                {institutions.map((institution) => (
                  <option key={institution.Institution_Code} value={institution.Institution_Code}>
                    {institution.Institution_Name}
                  </option>
                ))}
              </Select>
              {formik.touched.institution && formik.errors.institution && (
                <HelperText className="text-red-600">{formik.errors.institution}</HelperText>
              )}
            </Label>
          )}


           {formik.values.paymentMode === 'Institution' && (
            <Label className="mt-4">
            <span>Staff ID</span>
            <Input
              className="mt-1"
              placeholder="Enter Your Staff ID"
              {...formik.getFieldProps('staffID')}
              valid={formik.touched.staffID && !formik.errors.staffID}
            />
            {formik.touched.staffID && formik.errors.staffID && (
              <HelperText className="text-red-600">{formik.errors.staffID}</HelperText>
            )}
          </Label>
              )}



               {formik.values.paymentMode === 'Mobile Money' && (
            <Label className="mt-4">
              <span>Mobile Money Carrier</span>
              <Select
                className="mt-1"
                placeholder="Select MoMo Carrier"
                {...formik.getFieldProps('network')}
                valid={formik.touched.network && !formik.errors.network}
              >
                <option value="">Select Network</option>
              <option value="MTN">MTN</option>
              <option value="Vodafone">VODAFONE</option>
              <option value="airteltigo">AIRTEL TIGO</option>
              </Select>
              {formik.touched.network && formik.errors.network && (
                <HelperText className="text-red-600">{formik.errors.network}</HelperText>
              )}
            </Label>
          )}


             {formik.values.paymentMode === 'Mobile Money' && (
  <>
    <Label className="mt-4">
      <span>Mobile Money Number</span>
      <PhoneInput
        inputComponent={Input}
        placeholder="Enter your mobile money number"
        defaultCountry="GH"
        international
        countryCallingCodeEditable={false}
        value={formik.values.momoNumber}
        onChange={(value) => {
          formik.setFieldValue('momoNumber', value);
          formik.setFieldTouched('momoNumber', true);
        }}
        error={
          (formik.values.momoNumber && !isValidPhoneNumber(formik.values.momoNumber))
            ? ['Invalid phone number length']
            : []
        }
      />
      {(formik.touched.momoNumber && formik.errors.momoNumber) || 
       (formik.values.momoNumber && !isValidPhoneNumber(formik.values.momoNumber)) ? (
        <div className="text-red-600">
          {formik.errors.momoNumber || 'Invalid phone number length'}
        </div>
      ) : null}
    </Label>
    {momoNameVerification && (
      <Alert color="success" className="text-sm mt-2">
        Mobile Money number verified successfully as {momoNameVerification}
      </Alert>
    )}
  </>
)}

              
             
              
               



             <Label className="mt-4">
  <span>Signature Preference</span>
  <Select
    className="mt-1"
    placeholder="Select Signature Preference"
     {...formik.getFieldProps('signaturePreference')} 
    valid={formik.touched.signaturePreference && !formik.errors.signaturePreference}
    //onChange={handleSignaturePreferenceChange}
  >
    <option value="">Select Signature Preference</option>
    <option value="signature">Sign on Pad</option>
    <option value="signatureImage">Upload Signature</option>
  </Select>
  {formik.touched.signaturePreference && formik.errors.signaturePreference && (
    <HelperText className="text-red-600">{formik.errors.signaturePreference}</HelperText>
  )}
</Label>
              
               <div className="mt-4">
      {formik.values.signaturePreference === 'signature' && (
        <Label className="mt-4">
          <span className="font-semibold">Signature Here</span>
          <div className="mt-1" style={{ border: "2px solid black", width: 455, height: 200 }}>
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 455, height: 200, className: 'border' }}
              ref={(ref) => {
                setSignatureRef(ref);
                if (ref && !signatureValue) {
                  const savedSignature = localStorage.getItem('savedSignature');
                  if (savedSignature) {
                    ref.fromDataURL(savedSignature);
                    setSignatureValue(savedSignature);
                  }
                }
              }}
              onEnd={handleSignatureChange}
            />
          </div>
        </Label>
      )}

      {formik.values.signaturePreference === 'signature' && (
        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
          <Button style={{ background: '#792F7E' }} type="button" onClick={clearSignature}>
            Clear Signature
            <ClearIcon className="ml-3 h-6 w-6" />
          </Button>
        </div>
      )}
    </div>


             
           

              {formik.values.signaturePreference === 'signatureImage' && (
               <Label className="mt-4">
                <span className="font-semibold">Upload Signature Image</span>
                
                 
                   <div className="mt-1"style={{
                          width: 455,
                          height: 200,
                          display: "flex",
                          justifyContent: "center",
                      alignItems: "center",
                    }}>
                      <Label class="h-full flex flex-col items-center w-full max-w-lg p-5 mx-auto mt-2 text-center bg-white border-2 border-gray-300 border-dashed cursor-pointer dark:bg-gray-900 dark:border-gray-700 rounded-xl">
                       
                         <svg style={{ color: '#792F7E' }}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12  dark:text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
               </svg>

                   <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>

                   <p class="mt-2 text-xs tracking-wide text-gray-500 dark:text-gray-400">Supported files include SVG, PNG or JPG. </p>

                      <input id="dropzone-file" type="file" class="hidden" accept="image/jpeg, image/png, image/svg+xml"
                        
                        onChange={(event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // Further check to ensure the file type is an image
      if (/^image\/(jpeg|png|svg\+xml)$/.test(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            formik.setFieldValue("signatureImage", reader.result);
          }
        };
        reader.readAsDataURL(file);
      } else {
        toast.error('Unsupported file type. Please select an image file.');
      }
    } else {
      //update the form state or UI to indicate no file was selected
      formik.setFieldValue("signatureImage", null);
      toast.error('No image file selected. Please select an image file as signature')

    }
  }}


                        valid={formik.touched.signatureImage && !formik.errors.signatureImage}
                        
                    />
                     </Label>
                  </div>
                  {formik.touched.signatureImage && formik.errors.signatureImage && (
            <HelperText className="text-red-500">{formik.errors.signatureImage}</HelperText>
                     )}

                   <div className='mt-8 justify-center'>
                  
                    {formik.values.signatureImage && <ImagePreview file={formik.values.signatureImage} />}


                    </div>
               
              </Label>
                

              )}


            </div>

            <div className="col-span-1 flex flex-col ">


             
             <Label className="mt-4" htmlFor="paymentFrequency">
  <span>Payment Frequency</span>
  <Input
    disabled={true}
    className="mt-1"
    type="text"
    placeholder="Enter Payment Frequency"
    {...formik.getFieldProps('paymentFrequency')}
    value={formik.values.paymentFrequency}
    valid={formik.touched.paymentFrequency && !formik.errors.paymentFrequency}
  />
  {formik.touched.paymentFrequency && formik.errors.paymentFrequency && (
    <HelperText className="text-red-600">
      {formik.errors.paymentFrequency}
    </HelperText>
  )}
</Label>



              
              <Label className="mt-4">
  <span>First Payment Date</span>
  <div className="mt-1">
                  <DatePicker
                     id="firstPaymentDate"
      selected={selectedDate}
      onChange={handleDateChange}
        dateFormat="MM-yyyy"
      showMonthYearPicker
      customInput={<Input type="text" placeholder="Select date" />}
      onBlur={() => formik.setFieldTouched('firstPaymentDate', true)}
      showMonthDropdown
      showYearDropdown
      showFullMonthYearPicker
      dropdownMode="select"
      placeholderText="Select date"
    />
  </div>
  {formik.touched.firstPaymentDate && formik.errors.firstPaymentDate && (
    <div className="text-red-500">{formik.errors.firstPaymentDate}</div>
  )}
</Label>



              {formik.values.paymentMode === 'Bank' && (
  <>
    <Label className="mt-4">
      <span>Name Associated with Account number</span>
      <Input
        className="mt-1"
        placeholder="Enter the Name on your bank account"
        value={bankNameVerification || ''}
        readOnly
        disabled={true}
        {...formik.getFieldProps('bankAccountName')}
        valid={formik.touched.bankAccountName && !formik.errors.bankAccountName}
      />
      {formik.touched.bankAccountName && formik.errors.bankAccountName && (
        <HelperText className="text-red-600">{formik.errors.bankAccountName}</HelperText>
      )}
    </Label>
    
  </>
)}

              

{formik.values.paymentMode === 'Mobile Money' && (
  <>
    <Label className="mt-4">
      <span>Name Associated with MoMo number</span>
      <Input
        className="mt-1"
        placeholder="Enter the Name on momo account"
        value={momoNameVerification || ''}
        readOnly
        disabled={true}
        {...formik.getFieldProps('momoAccountName')}
        valid={formik.touched.momoAccountName && !formik.errors.momoAccountName}
      />
      {formik.touched.momoAccountName && formik.errors.momoAccountName && (
        <HelperText className="text-red-600">{formik.errors.momoAccountName}</HelperText>
      )}
    </Label>
    
  </>
)}





            </div>
          </div>



            <div className="col-span-2 flex mt-12">
            <Button className="mt-4 px-4"
  style={{ background: '#792F7E' }}
  type="submit"
            >
               {loading ? (
    <>
      <Spinner size="md" color="" className="mr-2" />
      Adding...
    </>
  ) : (
    ' Add Payment Details' 
  )}
  
 
</Button> 
            </div>          

          </form>
      </div>
         
      </div>
  );
};

export default PaymentDetails;