import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { differenceInYears, parse, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import QuoteModal from './QuoteModal';
import { paymentFrequency } from '../constants/paymentFrequencies';
import { toast, Toaster } from 'react-hot-toast';
import { ClientContext } from '../context/ClientContext';
import { Checkbox } from 'flowbite-react';
import { Spinner } from 'flowbite-react'
import OnboardingAlert from '../components/Alerts/OnboardingAlert';
import { Input, HelperText, Label, Select, Button, Card, CardBody, TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell, } from '@windmill/react-ui';
import SectionTitle from '../components/Typography/SectionTitle';


const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiKey = process.env.REACT_APP_API_KEY;
const apiUser = process.env.REACT_APP_API_USER;

export const ProductDetailsWPP = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sumAssured, setSumAssured] = useState(null);
  const [coverTypes, setCoverTypes] = useState([]); 
  const [agnCode, setAgnCode] = useState(null);
  const [selectedProductCode, setSelectedProductCode] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [productDetailsMore, setProductDetailsMore] = useState([]);
  const [selectedProductCoverTypes, setSelectedProductCoverTypes] = useState([]);
  const [selectedCoverTypesCvtCodes, setSelectedCoverTypesCvtCodes] = useState(new Set());
  const [createdQuote, setCreatedQuote] = useState(null);
  const { updateStepCompletionStatus } = useContext(ClientContext);
  const [loading, setLoading] = useState(false);
  const [dependents, setDependents] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const location = useLocation();



  
  let applicationID = localStorage.getItem('currentApplicationID');
const initials = applicationID.substring(0, 3);

const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

// Find the matching application directly using the applicationID
const matchingApplication = cachedOngoingApplications.find(app => app.id === applicationID);

// Assuming clientData is nested within the data structure of the matching application
  const clientData = matchingApplication ? matchingApplication.data.clientData : {};
  console.log("Client Data: " ,  clientData.dob);


const initialQuote = matchingApplication
  ? {
      policyTerm: matchingApplication.data.productDetailsData?.quoteDetails?.policyTerm || '',
      basicPremium: matchingApplication.data.productDetailsData?.quoteDetails?.basicPremium || '',
      initialSumInsured: matchingApplication.data.productDetailsData?.quoteDetails?.initialSumAssured || '',
      paymentFrequency: matchingApplication.data.productDetailsData?.quoteDetails?.paymentFrequency || '',
      quoteCode: matchingApplication.data.productDetailsData?.quoteDetails?.quote_code || '',
      coverTypeAllocations: matchingApplication.data.productDetailsData?.quoteDetails?.coverTypeAllocations || [],
    }
  : 
  
  
  {
      policyTerm: '',
      basicPremium: '',
      initialSumInsured: '',
      paymentFrequency: '',
      quoteCode: '',
      coverTypeAllocations: [],
    };

const [quote, setQuote] = useState(initialQuote);









const generatePolicyTermOptions = (
  minAgeAtEntry,
  maxAgeAtEntry,
  minTerm,
  maxTerm,
  age,
  maxAgeAtExit
) => {
  const policyTermOptions = [];

  if (age >= minAgeAtEntry && age < maxAgeAtEntry) { 
    const maxPossibleTerm = maxAgeAtExit - age;
    const maxAvailableTerm = Math.min(maxPossibleTerm, maxTerm);

    for (let step = minTerm; step < maxAvailableTerm; step++) {  
      policyTermOptions.push({ value: step, label: `${step} YEARS` });
    }
  } else {
    console.log('no terms for you bro');
  }

  return policyTermOptions;
};

const clpPolicyParams = {
  minAgeAtEntry: 18+1,
  maxAgeAtEntry: 51+1,
  minTerm: 8,
  maxTerm: 25,
  maxAgeAtExit: 52+8, 
};

const wppPolicyParams = {
  minAgeAtEntry: 18+1,
  maxAgeAtEntry: 59+1,
  minTerm: 4,
  maxTerm: 10,
  maxAgeAtExit: 60+5
  };
  const uppPolicyParams = {
  minAgeAtEntry: 18+1,
  maxAgeAtEntry: 56+1,
  minTerm: 8,
  maxTerm: 20,
  maxAgeAtExit: 65
  };

  const cbpPolicyParams = {
  minAgeAtEntry: 18+1,
  maxAgeAtEntry: 49+1,
  minTerm: 10,
  maxTerm: 60-19,
  maxAgeAtExit: 60
  };

  const age = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z$/.test(clientData.dob) ? differenceInYears(new Date(), new Date(clientData.dob)) : differenceInYears(new Date(), parse(clientData.dob, 'dd-MM-yyyy', new Date()));

  console.log ("current age", age);



// Generate policy term options
const policyTermCLP = generatePolicyTermOptions(
  clpPolicyParams.minAgeAtEntry,
  clpPolicyParams.maxAgeAtEntry,
  clpPolicyParams.minTerm,
  clpPolicyParams.maxTerm,
  age,
  clpPolicyParams.maxAgeAtExit
);

const policyTermWPP = generatePolicyTermOptions(
  wppPolicyParams.minAgeAtEntry,
  wppPolicyParams.maxAgeAtEntry,
  wppPolicyParams.minTerm,
  wppPolicyParams.maxTerm,
  age,
  wppPolicyParams.maxAgeAtExit
  );
  

  const policyTermUPP = generatePolicyTermOptions(
  uppPolicyParams.minAgeAtEntry,
  uppPolicyParams.maxAgeAtEntry,
  uppPolicyParams.minTerm,
  uppPolicyParams.maxTerm,
  age,
  uppPolicyParams.maxAgeAtExit
);


  const policyTermCBP = generatePolicyTermOptions(
  cbpPolicyParams.minAgeAtEntry,
  cbpPolicyParams.maxAgeAtEntry,
  cbpPolicyParams.minTerm,
  cbpPolicyParams.maxTerm,
  age,
  cbpPolicyParams.maxAgeAtExit
);

  

console.log("Policy Terms for CLP:", policyTermCLP);
console.log("Policy Terms for WPP:", policyTermWPP);
console.log("Policy Terms for UPP:", policyTermUPP);
console.log("Policy Terms for CBP:", policyTermCBP);





  
  
  let selectedProduct = null;
   switch (initials) {
    case 'WPP':
      selectedProduct = 'Wealth Master Plus';
       break;
    case 'CLP':
      selectedProduct = 'Child Lifeline Plus';
      break;
    case 'UPP':
      selectedProduct = 'Ultimate Protection Plus';
       break;
    case 'EHP':
      selectedProduct = 'Esteem Homecall Plus';
       break;
    case 'SUH':
      selectedProduct = 'Supreme Homecall Plan';
       break;
    case 'CBP':
      selectedProduct = 'Cashbuilder Plus';
       break;
    case 'FPP':
      selectedProduct = 'Family Protection Plan';
       break;
    case 'FCL':
      selectedProduct = 'Fabulous Child Education';
       break;
    case 'FWP':
      selectedProduct = 'Fabulous Wealthmaster';
       break;
    case 'FUP':
      selectedProduct = 'Fabulous Ultimate Protection';
       break;
    case 'FEH':
      selectedProduct = 'Fabulous Homecall';
       break;
    case 'PCL':
      selectedProduct = 'Phobia Child Education';
       break;
    case 'PUP':
      selectedProduct = 'Phobia Ultimate Protection';
       break;
    case 'PWP':
      selectedProduct = 'Phobia Wealth Master';
       break;
    case 'PEH':
      selectedProduct = 'Phobia Homecall';
      break;
    default:
      selectedProduct = 'Unknown Product';
  }
  console.log("Selected product in quotation component", selectedProduct);


const incrementOptionsWPP = ['5% PREMIUM - 3% SUM ASSURED',
                          '10% PREMIUM - 6% SUM ASSURED',
                          '15% PREMIUM - 9% SUM ASSURED',
                          '20% PREMIUM - 12% SUM ASSURED',];
                          

 const incrementOptionsCLP = ['10% PREMIUM - 6% SUM ASSURED',
                          '15% PREMIUM - 9% SUM ASSURED',
                          '20% PREMIUM - 12% SUM ASSURED',];
                          
const sumAssuredOptionsUPP = ['GH₵ 100,000', 'GH₵ 200,000'];

const productPlansEHP = [
  'BASIC - SUM ASSURED (GH₵ 2,500)',
  'STANDARD - SUM ASSURED (GH₵ 5,000)',
  'PRESTIGE - SUM ASSURED (GH₵ 10,000)',
  'ELITE - SUM ASSURED (GH₵ 15,000)',
  'ULTIMATE - SUM ASSURED (GH₵ 30,000)',
];

const productPlansFPP = [
  'BASIC - SUM ASSURED (GH₵ 10,000)',
  'STANDARD - SUM ASSURED (GH₵ 17,500)',
  'PRESTIGE - SUM ASSURED (GH₵ 25,000)',
];



const extractPercentage = (option) => {
  const percentageMatch = option.match(/\d+/); 
  return percentageMatch ? parseInt(percentageMatch[0]) : 0;
};

  const extractSumAssuredFromPlan = (selectedPlan) => {
  const match = selectedPlan.match(/SUM ASSURED \(GH₵ (\d+(?:,\d{3})*(?:\.\d+)?)\)/);
  return match ? parseFloat(match[1].replace(/,/g, '')) : null;
};

const extractFigure = (option) => {
  const figureMatch = option.match(/[\d.,]+/); 
  return figureMatch ? parseFloat(figureMatch[0].replace(/,/g, '')) : 0;
};


  

  

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


const savedProductDetailsData = matchingApplication.data.productDetailsData?.quoteDetails;


  const handleCancel = () => {
    setIsModalOpen(false);
  };

const initialValuesWPP = {
  policyTerm: '',
  basicPremium: '',
  benefitsOptionQuestion: '',
  incrementalOption: '',
  paymentFrequency: '',
};


const initialValuesCLP = {
  policyTerm: '',
  basicPremium: '',
  disability: '',
  retrenchment: '',
  dreadDisease: '',
  personalAccident: '',
  benefitsOptionQuestion: '',
  incrementalOption: '',
  paymentFrequency: '',
};



const initialValuesUPP = {
  addRider: '',
  dreadDiseaseUPP: '',
  sumAssured: '',
  policyTerm: '',
  benefitsOptionQuestion: '',
  incrementalOption: '',
  paymentFrequency: '',
};

const initialValuesEHP = {
  productPlanEHP: '',
  benefitsOptionQuestion: '',
  incrementalOption: '',
  paymentFrequency: '',
};

const initialValuesFPP = {
  productPlanFPP: '',
  paymentFrequency: '',
};

  const initialValuesCBP = {
  addRider: '',
  dreadDiseaseCBP: '',
  policyTerm: '',
  basicPremium: '',
  paymentFrequency: '',
};






  const validationSchemaFPP = Yup.object().shape({

  productPlanFPP: Yup.string().required('Product Plan is required'),
  paymentFrequency: Yup.string().required('Payment Frequency is required'),
});

const validationSchemaCBP = Yup.object().shape({
  addRider: Yup.string().required('This field is required'),
  dreadDiseaseCBP: Yup.string().when('addRider', {
    is: (addRider) => addRider === 'YES',
    then: (schema)=>schema.required('Select available Rider'),
    otherwise: (schema) => schema.notRequired(),
  }),
  policyTerm: Yup.number().required('Policy Term is required'),
   basicPremium: Yup.number().required('Basic Premium is required'),
  paymentFrequency: Yup.string().required('Payment Frequency is required'),
});



const validationSchemaWPP = Yup.object().shape({
  policyTerm: Yup.number().required('Policy Term is required'),
  basicPremium: Yup.number().required('Basic Premium is required'),
  benefitsOptionQuestion: Yup.string().required('Please Select a yes or no option'),
  incrementalOption: Yup.string().when('benefitsOptionQuestion', {
    is: (question) => question === 'YES',
    then:(schema)=>schema.required('Incremental Option is required'),
    otherwise: (schema) => schema.notRequired(),

  }),
  paymentFrequency: Yup.string().required('Payment Frequency is required'),
});


const validationSchemaCLP = Yup.object().shape({
  policyTerm: Yup.number().required('Policy Term is required'),
  basicPremium: Yup.number().required('Basic Premium is required'),
  disability: Yup.string().required('This field is required'),
  retrenchment: Yup.string().required('This field is required'),
  dreadDisease: Yup.string().required('This field is required'),
  personalAccident: Yup.string().required('This field is required'),
  benefitsOptionQuestion: Yup.string().required('Please Select a yes or no option'),
  incrementalOption: Yup.string().when('benefitsOptionQuestion', {
    is: (question) => question === 'YES',
    then:(schema)=>schema.required('Incremental Option is required'),
    otherwise: (schema) => schema.notRequired(),

  }),
  paymentFrequency: Yup.string().required('Payment Frequency is required'),
});




  const validationSchemaUPP = Yup.object().shape({
  addRider: Yup.string().required('This field is required'),
  dreadDiseaseUPP: Yup.string().when('addRider', {
    is: (addRider) => addRider === 'YES',
    then: (schema)=>schema.required('Select available Rider'),
    otherwise: (schema) => schema.notRequired(),
  }),
  sumAssured: Yup.string().required('Sum Assured is required'),
  policyTerm: Yup.number().required('Policy Term is required'),
  benefitsOptionQuestion: Yup.string().required('Please Select a yes or no option'),
  incrementalOption: Yup.string().when('benefitsOptionQuestion', {
    is: (question) => question === 'YES',
    then: (schema) => schema.required('Incremental Option is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  paymentFrequency: Yup.string().required('Payment Frequency is required'),
});


  const validationSchemaEHP = Yup.object().shape({

  productPlanEHP: Yup.string().required('Product Plan is required'),
  benefitsOptionQuestion: Yup.string().required('Please Select a yes or no option'),
  incrementalOption: Yup.string().when('benefitsOptionQuestion', {
    is: (question) => question === 'YES',
    then: (schema) => schema.required('Incremental Option is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  paymentFrequency: Yup.string().required('Payment Frequency is required'),
});


  
  


 
  

const selectedProductCategory = productDetailsMore.find(details => details.firstProduct);
const webProdCode = selectedProductCategory ? selectedProductCategory.firstProduct.webProdCode : '';
  const selectedProductCategoryCode = selectedProductCategory ? selectedProductCategory.categoryCode : null;

  
const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

const applicationToUpdate = ongoingApplications.find(app => app.id === applicationID);



let policyTermEHP, premiumTermEHP;

if (initials === 'EHP'|| initials === 'FEH' || initials === 'PEH') {
  if ((age + 1) < 55) {
    policyTermEHP = 80;
    premiumTermEHP = 65 - (age + 1);
  } else {
    policyTermEHP = 90;
    premiumTermEHP = 90;
  }
}

let policyTermFPP, premiumTermFPP;

if (initials === 'FPP') {
  if (age < 55) {
    policyTermFPP = 75;
    premiumTermFPP = 60 - age;
  } else {
    policyTermFPP = null;
    premiumTermFPP = null;
  }
}

  
  const formik = useFormik({
     initialValues:
    initials === 'WPP' ? initialValuesWPP :
    initials === 'CLP' ? initialValuesCLP :
    initials === 'FPP' ? initialValuesFPP :
    initials === 'UPP' ? initialValuesUPP :
    initials === 'CBP' ? initialValuesCBP :
    initials === 'EHP' ? initialValuesEHP :
    initials === 'FCL' ? initialValuesCLP :
    initials === 'FWP' ? initialValuesWPP :
    initials === 'FUP' ? initialValuesUPP :
    initials === 'FEH' ? initialValuesEHP :
    initials === 'PCL' ? initialValuesCLP :
    initials === 'PWP' ? initialValuesWPP :
    initials === 'PUP' ? initialValuesUPP :
    initials === 'PEH' ? initialValuesEHP :
    {},
  validationSchema:
     initials === 'WPP' ? validationSchemaWPP :
     initials === 'CLP' ? validationSchemaCLP :
     initials === 'CBP' ? validationSchemaCBP :
     initials === 'FPP' ? validationSchemaFPP :
     initials === 'UPP' ? validationSchemaUPP :
     initials === 'EHP' ? validationSchemaEHP :
                
     initials === 'FWP' ? validationSchemaWPP :
     initials === 'PWP' ? validationSchemaWPP :
     initials === 'FCL' ? validationSchemaCLP :
     initials === 'PCL' ? validationSchemaCLP :
     initials === 'FUP' ? validationSchemaUPP :
     initials === 'PUP' ? validationSchemaUPP :
     initials === 'FEH' ? validationSchemaEHP :
     initials === 'PEH' ? validationSchemaEHP :
    {},
    onSubmit: async (values) => {
            const escalationRate = formik.values.benefitsOptionQuestion === 'YES' ? extractPercentage(values.incrementalOption) : null;
              const allowedProducts = ['Ultimate Protection Plus', 'Fabulous Ultimate Protection', 'Phobia Ultimate Protection'];
              const sumAssuredValue = allowedProducts.includes(selectedProduct) ? extractFigure(values.sumAssured) : 0;
              let planSumAssured;
        switch (initials) {
          case 'EHP':
            planSumAssured = extractSumAssuredFromPlan(values.productPlanEHP);
            break;
          case 'FEH':
            planSumAssured = extractSumAssuredFromPlan(values.productPlanEHP);
            break;
          case 'PEH':
            planSumAssured = extractSumAssuredFromPlan(values.productPlanEHP);
            break;
          case 'FPP':
            planSumAssured = extractSumAssuredFromPlan(values.productPlanFPP);
            break;
          default:
            planSumAssured = 0;
        }

        let Sum_Assured;
        switch (initials) {
          case 'UPP':
          case 'FUP':
          case 'PUP':
            Sum_Assured = sumAssuredValue;
            break;
          case 'EHP':
          case 'FEH':
          case 'PEH':
          case 'FPP':
            Sum_Assured = planSumAssured;
            break;
          default:
            Sum_Assured = null;
        }



              try {
            setLoading(true);
            //const applicationToUpdate = ongoingApplications.find(app => app.id === applicationID);

            const payload = {
                Agent_Code: location.pathname.includes('/client') ? null : agnCode,
                Cover_Type_Code: [...selectedCoverTypesCvtCodes],
                Escalation_Rate: escalationRate,
                Life_Rider: 'N',
                Payment_Frequency: values.paymentFrequency.charAt(0).toString(),
                Policy_Term: ['EHP', 'PEH', 'FEH'].includes(initials) ? policyTermEHP : (initials === 'FPP' ? policyTermFPP : parseInt(values.policyTerm)),
                Premium_Term: ['EHP', 'PEH', 'FEH'].includes(initials) ? premiumTermEHP : (initials === 'FPP' ? premiumTermFPP : parseInt(values.policyTerm)),
                Product_Code: webProdCode,
                Premium: ['UPP', 'EHP', 'FEH', 'PEH', 'PUP', 'FUP', 'FPP'].includes(initials) ? null : parseInt(values.basicPremium),
                Quote_Code: null,
                Sum_Assured: Sum_Assured,
                Web_Client_Code: applicationToUpdate?.data.clientData.New_Client_Code || null,
                Client_Code: applicationToUpdate?.data.clientData.existingClientCode || null,
                Api_User: apiUser,
                Api_Key: apiKey,
            };

            payload.Client_Type = payload.Web_Client_Code ? 'W' : 'C';
            const response = await axios.post(`${apiUrl}/SL_CalculateQuote`, payload);

            const calculatedQuote = {
                policyTerm: response.data.policyTerm,
                basicPremium: response.data.premium,
                initialSumInsured: response.data.sumInsured,
                quoteCode: response.data.quoCode,
                escalationRate: payload.Escalation_Rate,
                paymentFrequency: formik.values.paymentFrequency,
                coverTypeAllocations: response.data.coverTypeAllocations.map(allocation => ({
                type: allocation.cvtDesc,
                coverTypeSumAssured: allocation.cvtSa,
                })),
            };

            setLoading(false);
            setQuote(calculatedQuote);
            setSumAssured(response.data.sumInsured);
            setCoverTypes(response.data.coverTypeAllocations.map(allocation => allocation.cvtDesc));

            // Update the local storage with the new quote details
            const applicationIndex = ongoingApplications.findIndex(app => app.id === applicationID);
            if (applicationIndex !== -1) {
                const application = ongoingApplications[applicationIndex];
                application.data.productDetailsData = {
                    ...application.data.productDetailsData,
                    quoteDetails: {
                        ...calculatedQuote,
                        coverTypeAllocations: calculatedQuote.coverTypeAllocations,
                    },
                };

                ongoingApplications[applicationIndex] = application;
                localStorage.setItem('ongoingApplications', JSON.stringify(ongoingApplications));
            }

            setIsModalOpen(true);
        } catch (error) {
            console.error('Error calculating quote:', error);
            setLoading(false);
        }

            }
          });

  const handleAccept = async (values) => {
  
  try {
    const acceptedQuote = {
  Client_Code: applicationToUpdate?.data.clientData.New_Client_Code ? applicationToUpdate.data.clientData.New_Client_Code.toString() : applicationToUpdate?.data.clientData.existingClientCode?.toString(),
  Client_Type: applicationToUpdate?.data.clientData.New_Client_Code ? 'W' : 'C',

  Cover_Type_Code: [...selectedCoverTypesCvtCodes],
  Escalation_Rate: quote.escalationRate,
  Life_Cover: 'N',
  Policy_Term: ['EHP', 'PEH', 'FEH'].includes(initials) ? policyTermEHP : (initials === 'FPP' ? policyTermFPP : parseInt(quote.policyTerm)),
  Premium_Term: ['EHP', 'PEH', 'FEH'].includes(initials) ? premiumTermEHP : (initials === 'FPP' ? premiumTermFPP : parseInt(quote.policyTerm)),

  Product_Category_Code: selectedProductCategoryCode,
  Product_Code: webProdCode,
  Agent_Code: location.pathname.includes('/client') ? null : agnCode,
  Quote_Code: quote.quoteCode,
  Payment_Frequency: quote.paymentFrequency.charAt(0).toString(),
  Premium: parseFloat(quote.basicPremium),
  Sum_Assured: parseFloat(quote.initialSumInsured),
  Quote_Status: 'DR',
  Quote_Type: ['UPP', 'EHP', 'FEH', 'PEH', 'FUP', 'PUP', 'FPP'].includes(initials) ? 'Sum Assured' : 'Premium',
  Api_User: apiUser,
  Api_Key: apiKey,
};


    const response = await axios.post(`${apiUrl}/SL_CreateQuote`, acceptedQuote);
    const apiResponse = response.data;
    updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 3 : 2, true);
    


if (applicationToUpdate) {
  // Update the application's details
  const updatedQuoteDetails = {
    ...formik.values,
    quote_code: apiResponse.webQuickQuote.webQuoteCode,
    initialSumAssured: apiResponse.webQuickQuote.webQuoteSumInsured,
    policyTerm: apiResponse.webQuickQuote.webPolicyTerm,
    paymentFrequency: formik.values.paymentFrequency,
    basicPremium: apiResponse.webQuickQuote.webQuotePremium,
    calculation_quote_number: apiResponse.webQuickQuote.webQuoteNo,
    coverTypeAllocations: quote.coverTypeAllocations,
  };

  // Assuming you have deep cloning method to avoid direct mutation
  const updatedApplication = {
    ...applicationToUpdate,
    data: {
      ...applicationToUpdate.data,
      productDetailsData: {
        ...applicationToUpdate.data.productDetailsData,
        quoteDetails: updatedQuoteDetails,
      },
    },
  };

  // Replace the old application with the updated one in the array
  const updatedApplications = ongoingApplications.map(app =>
    app.id === applicationID ? updatedApplication : app
  );

  // Save the updated array back to storage or state
  localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));
}
    setCreatedQuote(apiResponse);
    updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 3 : 2, true);

    localStorage.setItem('quote_code', apiResponse.webQuickQuote.webQuoteCode);

     if (apiResponse?.webQuickQuote?.webPaymentStatus === 'PENDING') {
      // Show a success toast using react-hot-toast
      const toastMessage = (
  <span>
    Quotation Accepted Successfully. Click <strong>Next</strong> to add beneficiary.
  </span>
);

const toastOptions = {
  duration: 7000,
  position: 'top-center',
};

toast.success(toastMessage, toastOptions);
    }
    setIsModalOpen(false);
  } catch (error) {
    console.log(error);
    // Handle error
  }
  };


useEffect(() => {

  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));
  const matchingApplication = cachedOngoingApplications
    .flatMap(applicationGroup => applicationGroup)
    .find(application => application.id === applicationID);

 if (matchingApplication) {
    const savedProductDetailsData = matchingApplication.data.productDetailsData;
    console.log('productDetailsData', savedProductDetailsData);
    if (savedProductDetailsData && savedProductDetailsData.quoteDetails) {
      formik.setValues(savedProductDetailsData.quoteDetails);
      
      const quoteDetails = savedProductDetailsData.quoteDetails;

      const updatedQuote = {
        ...formik.values,
        policyTerm: quoteDetails.policyTerm || '',
        basicPremium: quoteDetails.basicPremium || '',
        initialSumInsured: quoteDetails.initialSumAssured || '',
        paymentFrequency: quoteDetails.paymentFrequency || '',
        quoteCode: quoteDetails.quote_code || '',
        coverTypeAllocations: quoteDetails.coverTypeAllocations || [],
        
      
      
       
      };

      setQuote(updatedQuote); 
    }
  }
}, []);
  
  




useEffect(() => {
  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

  if (createdQuote) {
    // Find the specific application to update
    const applicationToUpdate = cachedOngoingApplications.find(app => app.id === applicationID);

    if (applicationToUpdate) {
      // Update the quote details for the found application
      const updatedQuoteDetails = {
        ...formik.values,
        quote_code: createdQuote.webQuickQuote.webQuoteCode,
        initialSumAssured: createdQuote.webQuickQuote.webQuoteSumInsured,
        policyTerm: createdQuote.webQuickQuote.webPolicyTerm,
        basicPremium: createdQuote.webQuickQuote.webQuotePremium,
        paymentFrequency: formik.values.paymentFrequency,
        calculation_quote_number: createdQuote.webQuickQuote.webQuoteNo,
        coverTypeAllocations: quote.coverTypeAllocations,
      };

      // Update the product details data for the found application
      const updatedProductDetailsData = {
        ...applicationToUpdate.data.productDetailsData,
        quoteDetails: updatedQuoteDetails,
      };

      // Construct the updated data object
      const updatedData = {
        ...applicationToUpdate.data,
        productDetailsData: updatedProductDetailsData,
      };

      // Apply the updated data object to the found application
      applicationToUpdate.data = updatedData;

      // Update localStorage with the modified `ongoingApplications`
      localStorage.setItem('ongoingApplications', JSON.stringify(cachedOngoingApplications));

      // Optionally, update the step completion status
      updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 3 : 2, true);
    }
  }
}, [createdQuote]);

  
  
    useEffect(() => {
        // Fetch the agent code from localStorage
        const agentCode = JSON.parse(localStorage.getItem('agentData')).agent_code;
        const payload = {
            "Agn_Code": agentCode,
            "Api_User": apiUser,
            "Api_Key": apiKey
        };

        axios.post(`${apiUrl}/SL_FindAgency`, payload)
            .then(response => {
                const agnCode = response.data.agnCode;
                setAgnCode(agnCode);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);
  
  
  
useEffect(() => {
  const fetchData = async () => {
    try {
      const payload = {
        Api_User: apiUser,
        Api_Key: apiKey,
      };

      const findProductsResponse = await axios.post(`${apiUrl}/SL_FindProducts`, payload);
      const matchedProducts = findProductsResponse.data.filter(product =>
  product.prodDesc.toLowerCase().includes(selectedProduct.toLowerCase()) && 
        product.prodShtDesc === initials
        
      );
      console.log('matched Products',  matchedProducts)


      if (matchedProducts.length > 0) {
        const selectedProductCode = matchedProducts[0].prodCode;


       const categoryResponse = await axios.post(`${apiUrl}/SL_ProductCategory`, payload);
        const categoriesWithoutIcon = categoryResponse.data.map(category => {
          const { webProdCategIconcode, ...rest } = category;
          return rest;
        });
        setProductCategories(categoriesWithoutIcon);


      const updatedProductDetails = [];
for (const category of categoriesWithoutIcon) {
  const productResponse = await axios.post(`${apiUrl}/SL_WebProduct`, {
    Category_Code: category.webProdCategCode.toString(),
    Api_User: apiUser,
    Api_Key: apiKey,
  });

  const otherMatchedProducts = productResponse.data.filter(product => {
    if (initials === 'EHP' || initials === 'FPP' || initials === 'PEH' || initials === 'FEH') {
      const selectedProductPlan = 
    ['EHP', 'PEH', 'FEH'].includes(initials) ? 
    formik.values.productPlanEHP : 
    formik.values.productPlanFPP;

      return (
        product.prodCode === selectedProductCode &&
        product.prodDesc.toLowerCase().includes(selectedProduct.toLowerCase()) &&
        selectedProductPlan &&  
        product.popDesc === selectedProductPlan.split(' ')[0]
      );
    } else {
      return (
        product.prodCode === selectedProductCode &&
        product.prodDesc.toLowerCase().includes(selectedProduct.toLowerCase())
      );
    }
  });


  if (otherMatchedProducts.length > 0) {
    updatedProductDetails.push({
      categoryCode: category.webProdCategCode,
      firstProduct: otherMatchedProducts[0]
    });
  
  }
}

        setProductDetailsMore(updatedProductDetails);

        const firstProduct = updatedProductDetails.find(details => details.firstProduct);
        const popCode = firstProduct ? firstProduct.firstProduct.popCode : '';

        // Fetch cover types
        const coverTypesResponse = await axios.post(`${apiUrl}/SL_CoverTypes`, {
          Product_Code: selectedProductCode.toString(),
          Product_Option_Code: popCode.toString(),
          Api_User: apiUser,
          Api_Key: apiKey,
        });
        const selectedProductCoverTypes = coverTypesResponse.data;
        
        setSelectedProductCoverTypes(selectedProductCoverTypes);
      }
      setDataFetched(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  fetchData();
}, [selectedProduct, initials, formik.values.productPlanEHP, formik.values.productPlanFPP]);


useEffect(() => {
    if (['WPP', 'FWP', 'PWP'].includes(initials) && Array.isArray(selectedProductCoverTypes)) {
        const cvtCodes = selectedProductCoverTypes.map(coverType => coverType.cvtCode);
        setSelectedCoverTypesCvtCodes(cvtCodes);
    }
}, [initials, selectedProductCoverTypes]);




const findCvtCode = (coverTypeDescription) => {
  let keyword = coverTypeDescription; // changed this from 'const' to 'let'
  
  if (!Array.isArray(selectedProductCoverTypes)) {
    return [];
  }

  const exFamilyRelations = ['NEPHEW', 'FIANCE(E)', 'NIECE', 'BROTHER', 'SISTER', 'AUNT', 'BROTHER-IN-LAW', 'SISTER-IN-LAW', 'UNCLE', 'COUSIN', 'DAUGHTER', 'SON', 'GRANDFATHER', 'GRANDMOTHER', 'GUARDIAN', 'STEP FATHER', 'STEP MOTHER'];

  const cvtCodes = [];

  if (['EHP', 'PEH', 'FEH', 'FPP'].includes(initials)) {
    const mainCover = selectedProductCoverTypes.find((coverType) => coverType.cvtDesc.includes("MAIN COVER"));
    if (mainCover) {
      console.log(mainCover.cvtCode);
      cvtCodes.push(mainCover.cvtCode);
    }

    if (exFamilyRelations.includes(coverTypeDescription)) {
      keyword = 'EX FAMILY';
    }

    const selectedCoverType = selectedProductCoverTypes.find((coverType) => coverType.cvtDesc.includes(keyword));
    if (selectedCoverType) {
      console.log(selectedCoverType.cvtCode);
      cvtCodes.push(selectedCoverType.cvtCode);
    }
  } else {
    const selectedCoverType = selectedProductCoverTypes.find((coverType) => coverType.cvtDesc.toLowerCase().includes(keyword.toLowerCase()));
    if (selectedCoverType) {
      console.log(selectedCoverType.cvtCode);
      cvtCodes.push(selectedCoverType.cvtCode);
    }
  }

  
  return cvtCodes;
  
};

useEffect(() => {
  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));
  

  const matchingApplication = cachedOngoingApplications
    .flatMap(applicationGroup => applicationGroup)
    .find(application => application.id === applicationID);
  

  if (matchingApplication) {
    const initialDependents = matchingApplication.data.dependentData;
    setDependents(initialDependents);
    
  }
}, [applicationID]);








  return (
    <div className="py-3 mb-8 flex flex-col mr-10 justify-start mt-10">
      <div className="mx-6 flex-auto  justify-start">
        
        <OnboardingAlert />

        <SectionTitle textAlign="center">Quotation Details</SectionTitle>
        
      <form onSubmit={formik.handleSubmit}>

        <div className="grid grid-cols-2 gap-8 mb-8 " >

           <div className="col-span-1 flex flex-col ">


             {(initials === 'EHP' || initials === 'FPP'|| initials === 'FEH'|| initials === 'PEH') && (
  <Label className="mt-4">
    <span>Available Product Plans</span>
    <Select
      className="mt-1"
      placeholder="Please select a product plan"
      {...formik.getFieldProps(['EHP', 'FEH', 'PEH'].includes(initials) ? 'productPlanEHP' : 'productPlanFPP')}
      value={['EHP', 'FEH', 'PEH'].includes(initials) ? formik.values.productPlanEHP : formik.values.productPlanFPP}
      onChange={(event) => {
  formik.handleChange(event);
  if (['EHP', 'FEH', 'PEH', 'FPP'].includes(initials)) {
    const allCodes = new Set();
    dependents.forEach((dependent) => {
      const dependentCoverTypes = findCvtCode(dependent.dependent);
      dependentCoverTypes.forEach(code => allCodes.add(code)); 
    });
    setSelectedCoverTypesCvtCodes(prevCodes => new Set([...prevCodes, ...allCodes])); 
  }
}}

     valid={['EHP', 'FEH', 'PEH'].includes(initials)
  ? (formik.touched.productPlanEHP && !formik.errors.productPlanEHP)
  : (formik.touched.productPlanFPP && !formik.errors.productPlanFPP)}

    >
      <option value="">Please select a product plan</option>
      {['EHP', 'FEH', 'PEH'].includes(initials)
  ? productPlansEHP.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))
  : productPlansFPP.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}

    </Select>
    {['EHP', 'FEH', 'PEH'].includes(initials)
  ? (formik.touched.productPlanEHP && formik.errors.productPlanEHP && (
      <HelperText className="text-red-600">{formik.errors.productPlanEHP}</HelperText>
    ))
  : (formik.touched.productPlanFPP && formik.errors.productPlanFPP && (
      <HelperText className="text-red-600">{formik.errors.productPlanFPP}</HelperText>
    ))}

  </Label>
)}






              {['WPP', 'UPP', 'CLP', 'CBP', 'PUP', 'FUP', 'FCL', 'PCL', 'FWP', 'PWP'].includes(initials) && (
  <Label className="mt-4">
    <span>Policy Term</span>
    <Select
      className="mt-1"
      placeholder="Select Policy Term"
      {...formik.getFieldProps('policyTerm')}
      valid={formik.touched.policyTerm && !formik.errors.policyTerm}
      onChange={formik.handleChange}
    >
      <option value="">Available Policy Terms</option>
      {['CLP', 'PCL', 'FCL'].includes(initials)
  ? policyTermCLP.map((term) => (
      <option key={term.value} value={term.value}>
        {term.label}
      </option>
    ))
  : ['UPP', 'PUP', 'FUP'].includes(initials)
  ? policyTermUPP.map((term) => (
      <option key={term.value} value={term.value}>
        {term.label}
      </option>
  ))
  :['CBP'].includes(initials)
  ? policyTermCBP.map((term) => (
      <option key={term.value} value={term.value}>
        {term.label}
      </option>
    ))
  : ['WPP', 'PWP', 'FWP'].includes(initials)
  ? policyTermWPP.map((term) => (
      <option key={term.value} value={term.value}>
        {term.label}
      </option>
    ))
  : null}

    </Select>
    {formik.touched.policyTerm && formik.errors.policyTerm && (
      <HelperText className="text-red-600">{formik.errors.policyTerm}</HelperText>
    )}
  </Label>
)}






{initials === 'EHP' || initials === 'PEH' || initials === 'FEH' || initials === 'FPP' ? (
  null 
) : (
  <Label className="mt-4">
    {['UPP', 'FUP', 'PUP'].includes(initials) ? (
      <>
        <span>Sum Assured</span>
        <Select
          className="mt-1"
          placeholder="Select Sum Assured Option"
          {...formik.getFieldProps('sumAssured')}
          value={formik.values.sumAssured}
          onChange={formik.handleChange}
          valid={formik.touched.sumAssured && !formik.errors.sumAssured}
        >
          <option value="">Select Sum Assured</option>
          {sumAssuredOptionsUPP.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        {formik.touched.sumAssured && formik.errors.sumAssured && (
          <HelperText className="text-red-600">{formik.errors.sumAssured}</HelperText>
        )}
      </>
    ) : (
      <>
        <span>Basic Premium</span>
        <Input
          className="mt-1"
          placeholder="Enter basic premium"
          {...formik.getFieldProps('basicPremium')}
          valid={formik.touched.basicPremium && !formik.errors.basicPremium}
        />
        {formik.touched.basicPremium && formik.errors.basicPremium && (
          <HelperText className="text-red-500">{formik.errors.basicPremium}</HelperText>
        )}
      </>
    )}
  </Label>
)}




              <Label className="mt-4">
              <span>Payment Frequency</span>
             <Select
  className="mt-1"
  placeholder="Select Payment Frequency"
  {...formik.getFieldProps('paymentFrequency')}
  value={formik.values.paymentFrequency} 
  onChange={formik.handleChange} 
  valid={formik.touched.paymentFrequency && !formik.errors.paymentFrequency}
>
  <option value="">Select Payment Frequency</option>
  {paymentFrequency.map((freq) => (
    <option key={freq.freqCode} value={freq.freqDesc}>
      {freq.freqDesc}
    </option>
  ))}
</Select>

              {formik.touched.paymentFrequency && formik.errors.paymentFrequency && (
                <HelperText className="text-red-600">{formik.errors.paymentFrequency}</HelperText>
              )}
              </Label>
              
{(initials !== "CBP" && initials !== "FPP") && (
  <>
    <Label className="mt-4">
      <span>Do you wish to have the incremental option?</span>
      <Select
        className="mt-1"
        placeholder="Select Option"
        {...formik.getFieldProps('benefitsOptionQuestion')}
        valid={formik.touched.benefitsOptionQuestion && !formik.errors.benefitsOptionQuestion}
      >
        <option value="">Select Option</option>
        <option value="YES">YES</option>
        <option value="NO">NO</option>
      </Select>
      {formik.touched.benefitsOptionQuestion && formik.errors.benefitsOptionQuestion && (
        <HelperText className="text-red-500">{formik.errors.benefitsOptionQuestion}</HelperText>
      )}
    </Label>

    {formik.values.benefitsOptionQuestion === 'YES' && (
      <Label className="mt-4">
        <span>Incremental Options</span>
        <Select
          className="mt-1"
          placeholder="Select Incremental Option"
          {...formik.getFieldProps('incrementalOption')}
          valid={formik.touched.incrementalOption && !formik.errors.incrementalOption}
        >
          <option value="">Select Incremental Option</option>
          {['CLP', 'PCL', 'FCL'].includes(initials) ? (
            
            incrementOptionsWPP.slice(-3).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))
          ) : (
            
            incrementOptionsWPP.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))
          )}
        </Select>
        {formik.touched.incrementalOption && formik.errors.incrementalOption && (
          <HelperText className="text-red-500">{formik.errors.incrementalOption}</HelperText>
        )}
      </Label>
    )}
  </>
)}



           </div>
          
              <div className='col-span-1 flex flex-col'>
            
            
              


               {['CLP', 'PCL', 'FCL'].includes(initials) && (
  <Label className="mt-4">
    <span>Total and Permanent Disability</span>
    <Select
      className="mt-1"
      placeholder="Select Option"
      {...formik.getFieldProps('disability')}
      valid={formik.touched.disability && !formik.errors.disability}
          onChange={(event) => {
  formik.handleChange(event);
  const coverType = 'disability';

  const cvtCodes = findCvtCode(coverType);

  if (event.target.value === 'YES' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => new Set([...prevCodes, ...cvtCodes]));
  } else if (event.target.value === 'NO' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => {
      const updatedCodes = new Set(prevCodes);
      cvtCodes.forEach(code => updatedCodes.delete(code));
      return updatedCodes;
    });
  }
}}

    >
      <option value="">Select Option</option>
      <option value="YES">YES</option>
      <option value="NO">NO</option>
    </Select>
    {formik.touched.disability && formik.errors.disability && (
      <HelperText className="text-red-500">{formik.errors.disability}</HelperText>
    )}
  </Label>
)}

              
             {['CLP', 'PCL', 'FCL'].includes(initials) && (
                  <Label className="mt-4">
              <span>Retrenchment</span>
              <Select
                className="mt-1"
                placeholder="Select Option"
                {...formik.getFieldProps('retrenchment')}
                    valid={formik.touched.retrenchment && !formik.errors.retrenchment}
                        onChange={(event) => {
  formik.handleChange(event);
  const coverType = 'retrenchment';

  const cvtCodes = findCvtCode(coverType);

  if (event.target.value === 'YES' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => new Set([...prevCodes, ...cvtCodes]));
  } else if (event.target.value === 'NO' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => {
      const updatedCodes = new Set(prevCodes);
      cvtCodes.forEach(code => updatedCodes.delete(code));
      return updatedCodes;
    });
  }
}}

              >
                <option value="">Select Option</option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </Select>
              {formik.touched.retrenchment && formik.errors.retrenchment && (
                <HelperText className="text-red-500">{formik.errors.retrenchment}</HelperText>
              )}
            </Label>

              )}


              {['CLP', 'PCL', 'FCL'].includes(initials) && (
                  <Label className="mt-4">
              <span>Dread Disease</span>
              <Select
                className="mt-1"
                placeholder="Select Option"
                {...formik.getFieldProps('dreadDisease')}
                    valid={formik.touched.dreadDisease && !formik.errors.dreadDisease}
                         onChange={(event) => {
  formik.handleChange(event);
  const coverType = 'dreadDisease';

  const cvtCodes = findCvtCode(coverType);

  if (event.target.value === 'YES' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => new Set([...prevCodes, ...cvtCodes]));
  } else if (event.target.value === 'NO' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => {
      const updatedCodes = new Set(prevCodes);
      cvtCodes.forEach(code => updatedCodes.delete(code));
      return updatedCodes;
    });
  }
}}

              >
                <option value="">Select Option</option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </Select>
              {formik.touched.dreadDisease && formik.errors.dreadDisease && (
                <HelperText className="text-red-500">{formik.errors.dreadDisease}</HelperText>
              )}
            </Label>

              )}


               {['CLP', 'PCL', 'FCL'].includes(initials) && (
                  <Label className="mt-4">
              <span>Personal Accident for child</span>
              <Select
                className="mt-1"
                placeholder="Select Option"
                {...formik.getFieldProps('personalAccident')}
                    valid={formik.touched.personalAccident && !formik.errors.personalAccident}
                         onChange={(event) => {
  formik.handleChange(event);
  const coverType = 'personalAccident';

  const cvtCodes = findCvtCode(coverType);

  if (event.target.value === 'YES' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => new Set([...prevCodes, ...cvtCodes]));
  } else if (event.target.value === 'NO' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => {
      const updatedCodes = new Set(prevCodes);
      cvtCodes.forEach(code => updatedCodes.delete(code));
      return updatedCodes;
    });
  }
}}

              >
                <option value="">Select Option</option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </Select>
              {formik.touched.personalAccident && formik.errors.personalAccident && (
                <HelperText className="text-red-500">{formik.errors.personalAccident}</HelperText>
              )}
            </Label>

              )}


             {(initials === 'UPP' || initials === 'FUP' || initials === 'PUP' || initials === 'CBP') && (
  <div>
    <Label className="mt-4">
      <span>Do you wish to add a Rider?</span>
      <Select
        className="mt-1"
        placeholder="Select Option"
        {...formik.getFieldProps('addRider')}
      >
        <option value="">Select Option</option>
        <option value="YES">YES</option>
        <option value="NO">NO</option>
      </Select>
      {formik.touched.addRider && formik.errors.addRider && (
        <HelperText className="text-red-500">{formik.errors.addRider}</HelperText>
      )}
    </Label>

    {formik.values.addRider === 'YES' && (
  <div className="mt-4">
    <Label className="flex items-center gap-2">
      <Checkbox
        style={{ color: '#9E2E89', borderColor: '#718096' }}
        {...formik.getFieldProps(initials === 'CBP' ? 'dreadDiseaseCBP' : 'dreadDiseaseUPP')}
        onChange={(event) => {
  formik.handleChange(event);
   const coverType = initials === 'CBP' ? 'CRITICAL ILLNESS/DREAD DISEASE' : 'UPP CRITICAL ILLNES';


  const cvtCodes = findCvtCode(coverType);

  if (event.target.value === 'YES' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => new Set([...prevCodes, ...cvtCodes]));
  } else if (event.target.value === 'NO' && cvtCodes) {
    setSelectedCoverTypesCvtCodes(prevCodes => {
      const updatedCodes = new Set(prevCodes);
      cvtCodes.forEach(code => updatedCodes.delete(code));
      return updatedCodes;
    });
  }
}}

      />
      <span>Dread Disease/Critical Illness</span>
    </Label>
    {formik.touched[initials === 'CBP' ? 'dreadDiseaseCBP' : 'dreadDiseaseUPP'] && formik.errors[initials === 'CBP' ? 'dreadDiseaseCBP' : 'dreadDiseaseUPP'] && (
      <HelperText className="text-red-500">
        {formik.errors[initials === 'CBP' ? 'dreadDiseaseCBP' : 'dreadDiseaseUPP']}
      </HelperText>
    )}
  </div>
)}

  </div>
)}









 </div>
          
            <div className="col-span-2 flex  mt-12">
              <Button disabled={!dataFetched} type="submit" className="text-white px-4 py-2 rounded hover:bg-gray-600" style={{ backgroundColor: "#792F7E" }}>
                {loading ? (
    <>
      <Spinner size="md" color="" className="mr-2" />
      Calculating...
    </>
  ) : (
    'Calculate Quote' 
  )}
              
            </Button>
            </div>
          
          </div>

             </form>
          
       
      <section className="flex justify-start mt-8">
  <div className="mt-10">
    <SectionTitle>Quotation Preview</SectionTitle>
  </div>
</section>

{quote && (
  <TableContainer style={{ width: '70%' }}>
    <Table className="table-auto w-full">
      <TableHeader style={{ backgroundColor: '#792F7E', color: 'white' }}>
        <TableRow>
          <TableCell>INITIAL SUM ASSURED</TableCell>
          <TableCell>COVER TYPES</TableCell>
         { (initials === 'UPP' || initials === 'FUP' || initials === 'PUP' || initials === 'EHP' || initials === 'FEH' || initials === 'PEH' || initials === 'FPP') && (
  <TableCell>PREMIUM PAYABLE</TableCell>
)}

        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>{quote.initialSumInsured ? `GH₵ ${quote.initialSumInsured.toLocaleString('en-US')}` : ''}</TableCell>
          <TableCell>
            <ul className="list-disc pl-4">
              {quote.coverTypeAllocations.map((allocation, index) => (
                <li key={index} className="mt-1">
                  {allocation.type}
                </li>
              ))}
            </ul>
          </TableCell>
         { (initials === 'UPP' || initials === 'FUP' || initials === 'PUP' || initials === 'EHP' || initials === 'FEH' || initials === 'PEH' || initials === 'FPP') && (
  <TableCell>
    {quote.basicPremium ? `GH₵ ${quote.basicPremium.toLocaleString('en-US')}` : ''}
  </TableCell>
)}
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
)}



        
        <QuoteModal isOpen={isModalOpen} onClose={handleCloseModal} onCancel={handleCancel} onAccept={handleAccept} quoteData={quote} />
        {/* {quote && <Confirmation formData={quote} />} */}
      
       </div>
     
        
      </div>
     
  );
};

