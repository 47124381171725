import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'flowbite-react';
import { ConfirmIcon } from '../icons';
import FinalOTPVerification from './FinalOTPVerification';

export default function ApplicationConfirmationModal({ isOpen, onClose, onConfirm}) {
  const [modalSize, setModalSize] = useState('md');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFinalOTPModalOpen, setIsFinalOTPModalOpen] = useState(false);



  // const openModal = () => {
  //   setIsModalOpen(true); 
  // };

  const closeModal = () => {
    setIsModalOpen(false); 
    onClose();
  };
                                  
  // const closeOTPModal = () => {
  //   setIsFinalOTPModalOpen(false);
  // }

  useEffect(() => {
    if (isFinalOTPModalOpen) {
      closeModal();
      console.log('is otp modal value', isFinalOTPModalOpen) 
    }
  }, [isFinalOTPModalOpen]);                            


  return (
    <>

      {/* The modal */}
      <Modal show={isOpen} size={modalSize} onClose={closeModal}>
        <Modal.Header > Confirmation </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <ConfirmIcon className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to submit this onboarding application?
            </h3>
            <div className="flex justify-center gap-4">
              <Button style={{ backgroundColor: '#792F7E'}} onClick={onConfirm}>
                Yes, I'm sure
              </Button>
              <Button color="gray" onClick={onClose}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {isFinalOTPModalOpen && (
        <FinalOTPVerification
          isOpen={isFinalOTPModalOpen}
          onClose={() => setIsFinalOTPModalOpen(false)}
        />
      )}
      
    </>
  );
}
