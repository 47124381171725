import React, { createContext, useState } from 'react';

export const PaymentDataContext = createContext();

export const PaymentDataContextProvider = ({ children }) => {
  const [paymentData, setPaymentData] = useState({});
  // // const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false);
  // const [formSubmitted, setFormSubmitted] = useState(false);
  // // const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false);



  return (
    <PaymentDataContext.Provider
      value={{
        paymentData,
        setPaymentData,
      }}
    >
      {children}
    </PaymentDataContext.Provider>
  );
};
