import React, { createContext, useState, useEffect } from 'react';

export const ClientContext = createContext();

export const ClientContextProvider = ({ children }) => {
  const [stepCompletionStatus, setStepCompletionStatus] = useState({});

  const updateStepCompletionStatus = (stepIndex, isSuccess) => {
    setStepCompletionStatus(prevState => {
      const newState = { ...prevState, [stepIndex]: isSuccess };
      const applicationID = localStorage.getItem('currentApplicationID');
      
      if (applicationID) {
        const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
        const applicationToUpdate = ongoingApplications.find(app => app.id === applicationID);
        
        if (applicationToUpdate) {
          applicationToUpdate.formSubmissionStatus = {
            ...applicationToUpdate.formSubmissionStatus,
            [stepIndex]: isSuccess
          };
          localStorage.setItem('ongoingApplications', JSON.stringify(ongoingApplications));
        }
      }
      
      return newState;
    });
  };

  useEffect(() => {
    const applicationID = localStorage.getItem('currentApplicationID');
    if (applicationID) {
      const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
      const application = ongoingApplications.find(app => app.id === applicationID);
      if (application && application.formSubmissionStatus) {
        setStepCompletionStatus(application.formSubmissionStatus);
      }
    }
  }, []);

  return (
    <ClientContext.Provider value={{ stepCompletionStatus, updateStepCompletionStatus }}>
      {children}
    </ClientContext.Provider>
  );
};
