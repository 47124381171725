import React, { createContext, useState, useEffect } from 'react';

export const BeneficiaryContext = createContext();

export const BeneficiaryContextProvider = ({ children }) => {
 const [beneficiaryData, setBeneficiaryData] = useState([]);

  const [requiredBeneFieldsComplete, setRequiredBeneFieldsComplete] = useState(false);
  const [isBeneficiaryUnder18, setIsBeneficiaryUnder18] = useState(false);
  const [isTotalPercentage100, setIsTotalPercentage100] = useState(false);
  const [hasBeneficiaryUnder18, setHasBeneficiaryUnder18] = useState(false);
  // const [formSubmitted, setFormSubmitted] = useState(false);
  // const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false);
  // console.log(clientData);

  const updateRequiredBeneFieldsComplete = (isComplete) => {
  setRequiredBeneFieldsComplete(isComplete);
  };

  const updateIsBeneficiaryUnder18 = (isUnder18) => {
    setIsBeneficiaryUnder18(isUnder18);
  };

  // Function to calculate the total percentage
  const calculateTotalPercentage = (beneficiaries) => {
    return beneficiaries.reduce((sum, beneficiary) => sum + Number(beneficiary.percentage), 0);
  };

  // Update isTotalPercentage100 whenever beneficiaryData changes
  useEffect(() => {
    const totalPercentage = calculateTotalPercentage(beneficiaryData);
    setIsTotalPercentage100(totalPercentage === 100);
  }, [beneficiaryData]);


useEffect(() => {
  console.log('beneficiaryData:', beneficiaryData);

  const hasUnder18 = beneficiaryData.some((beneficiary) => {
    // Extract day, month, and year from the date string
    const [day, month, year] = beneficiary.dob.split('-').map(Number);

    // Create a new Date object using the parsed values
    const dob = new Date(year, month - 1, day); // Month is 0-based in JavaScript Date

    // Calculate the age
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();

    console.log(`Beneficiary age: ${age}`);
    return age < 18;
  });

  console.log('hasUnder18:', hasUnder18);

  setHasBeneficiaryUnder18(hasUnder18);
}, [beneficiaryData]);


  console.log(hasBeneficiaryUnder18);

  return (
    <BeneficiaryContext.Provider value={{ beneficiaryData, setBeneficiaryData, requiredBeneFieldsComplete, updateRequiredBeneFieldsComplete, isBeneficiaryUnder18, setIsBeneficiaryUnder18, updateIsBeneficiaryUnder18, isTotalPercentage100, setIsTotalPercentage100, hasBeneficiaryUnder18 }}>
      {children}
    </BeneficiaryContext.Provider>
  );
};
