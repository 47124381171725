import { ReactComponent as ButtonsIcon } from './buttons.svg'
import { ReactComponent as CardsIcon } from './cards.svg'
import { ReactComponent as ChartsIcon } from './charts.svg'
import { ReactComponent as FormsIcon } from './forms.svg'
import { ReactComponent as HomeIcon } from './home.svg'
import { ReactComponent as ModalsIcon } from './modals.svg'
import { ReactComponent as PagesIcon } from './pages.svg'
import { ReactComponent as TablesIcon } from './tables.svg'
import { ReactComponent as HeartIcon } from './heart.svg'
import { ReactComponent as EditIcon } from './edit.svg'
import { ReactComponent as TrashIcon } from './trash.svg'
import { ReactComponent as ForbiddenIcon } from './forbidden.svg'
import { ReactComponent as GithubIcon } from './github.svg'
import { ReactComponent as TwitterIcon } from './twitter.svg'
import { ReactComponent as MailIcon } from './mail.svg'
import { ReactComponent as CartIcon } from './cart.svg'
import { ReactComponent as ChatIcon } from './chat.svg'
import { ReactComponent as MoneyIcon } from './money.svg'
import { ReactComponent as PeopleIcon } from './people.svg'
import { ReactComponent as SearchIcon } from './search.svg'
import { ReactComponent as MoonIcon } from './moon.svg'
import { ReactComponent as SunIcon } from './sun.svg'
import { ReactComponent as BellIcon } from './bell.svg'
import { ReactComponent as WppIcon } from './wpp.svg'
import { ReactComponent as ChildIcon } from './child.svg'
import { ReactComponent as CashBuilderIcon } from './cashBuilder.svg'
import { ReactComponent as EsteemeIcon } from './EsteemIcon.svg'
import { ReactComponent as UnknownIcon } from './unknownIcon.svg'
import { ReactComponent as FamilyProtectionIcon } from './FamilyProtectionIcon.svg'
import { ReactComponent as SupremeIcon } from './SupremeIcon.svg'
import { ReactComponent as UppIcon } from './UppIcon.svg'
import { ReactComponent as MenuIcon } from './menu.svg'
import { ReactComponent as DropdownIcon } from './dropdown.svg'
import { ReactComponent as OutlinePersonIcon } from './outlinePerson.svg'
import { ReactComponent as OutlineCogIcon } from './outlineCog.svg'
import { ReactComponent as OutlineLogoutIcon } from './outlineLogout.svg'
import { ReactComponent as EyeIcon } from './eye.svg'
import { ReactComponent as Continue } from './continue.svg'
import { ReactComponent as Exclamation } from './exclamation.svg'
import { ReactComponent as ClearIcon } from './clear.svg'
import { ReactComponent as QuestionTooltipIcon } from './questionTooltip.svg'
import { ReactComponent as ConfirmIcon } from './confirm.svg'
import { ReactComponent as CheckMarkIcon } from './checkmark.svg'
import { ReactComponent as TerminateIcon } from './terminate.svg'
import { ReactComponent as PendingIcon } from './pending.svg'
import { ReactComponent as ProductIcon } from './product.svg'
import { ReactComponent as PolicyIcon } from './policy.svg'
import { ReactComponent as OfflineIcon } from './offline.svg'
import { ReactComponent as ProvidentIcon } from './provident.svg'
import { ReactComponent as ReportIcon } from './report.svg'
import { ReactComponent as CalculatorIcon } from './calculator.svg'
import { ReactComponent as HelpIcon } from './help.svg'
import { ReactComponent as GhanaFlagIcon } from './ghanaflag.svg'
import { ReactComponent as CalenderIcon } from './calender.svg'
import { ReactComponent as PDFIcon } from './pdf.svg'

export {
  ConfirmIcon,
  GhanaFlagIcon,
  CalenderIcon,
  CalculatorIcon,
  ProvidentIcon,
  ReportIcon,
  HelpIcon,
  PolicyIcon,
  OfflineIcon,
  ProductIcon,
  PendingIcon,
  TerminateIcon,
  CheckMarkIcon,
  QuestionTooltipIcon,
  ClearIcon,
  Exclamation,
  PDFIcon,
  Continue,
  ButtonsIcon,
  CardsIcon,
  ChartsIcon,
  FormsIcon,
  HomeIcon,
  ModalsIcon,
  PagesIcon,
  TablesIcon,
  HeartIcon,
  EditIcon,
  TrashIcon,
  ForbiddenIcon,
  GithubIcon,
  TwitterIcon,
  MailIcon,
  CartIcon,
  ChatIcon,
  MoneyIcon,
  PeopleIcon,
  WppIcon,
  UppIcon,
  ChildIcon,
  CashBuilderIcon,
  UnknownIcon,
  EsteemeIcon,
  FamilyProtectionIcon,
  SupremeIcon,
  SearchIcon,
  MoonIcon,
  SunIcon,
  BellIcon,
  MenuIcon,
  DropdownIcon,
  OutlinePersonIcon,
  OutlineCogIcon,
  OutlineLogoutIcon,
  EyeIcon,
}
