import React, { createContext, useState } from 'react';

export const CheckboxContext = createContext();

export const CheckboxProvider = ({ children }) => {
   const initialCheckboxesState = {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  };

  const [checkboxesChecked, setCheckboxesChecked] = useState(initialCheckboxesState);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const allCheckboxesChecked = () => {
    return Object.values(checkboxesChecked).every((checked) => checked);
  };

   const resetCheckboxes = () => {
    setCheckboxesChecked(initialCheckboxesState);
  };

  return (
    <CheckboxContext.Provider value={{resetCheckboxes, checkboxesChecked, setCheckboxesChecked, isFinalStep, setIsFinalStep, allCheckboxesChecked }}>
      {children}
    </CheckboxContext.Provider>
  );
};
