import React, { createContext, useState } from 'react';

export const AgentContext = createContext();

export const AgentContextProvider = ({ children }) => {
  const [agentData, setAgentData] = useState({});



  return (
    <AgentContext.Provider
      value={{
        agentData,
        setAgentData
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};
