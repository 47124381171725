import React from 'react';
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';

function OnboardingAlert() {
  return (
    <Alert
      color="failure"
      rounded
      icon={HiInformationCircle}
    >
      <span className="font-medium">Info alert!</span> Please ensure all required fields are filled out accurately before proceeding. Incomplete or inaccurate information may impact system processes and result in unintended outcomes.
    </Alert>
  );
}

export default OnboardingAlert;
