import React, { createContext, useContext, useEffect, useState } from 'react';

const ApplicationContext = createContext();

export function useApplicationContext() {
  return useContext(ApplicationContext);
}

export function ApplicationContextProvider({ children }) {
  // Assuming there's a way to get a unique identifier for the current application.
  // This could be from the URL, a context, or somewhere else.
  const applicationID = localStorage.getItem('currentApplicationID'); // Example: Retrieve from localStorage or another state management solution

  // Function to get the initial state for globalBeneficiaryUnder18 specific to the current application
  const getInitialGlobalBeneficiaryUnder18 = () => {
    const applicationsData = JSON.parse(localStorage.getItem('applicationsData')) || {};
    return applicationsData[applicationID]?.globalBeneficiaryUnder18 || false;
  };

  const [globalBeneficiaryUnder18, setGlobalBeneficiaryUnder18] = useState(getInitialGlobalBeneficiaryUnder18);
  const [isTrusteeAdded, setIsTrusteeAdded] = useState(false);
  const [globaltotalBenPercentage, setGlobalTotalBenPercentage] = useState(0);
  const [isClientVerified, setIsClientVerified] = useState(false);
  const [existingClientData, setExistingClientData] = useState({});

  // Effect to update localStorage when globalBeneficiaryUnder18 changes, specific to the current application
  useEffect(() => {
    const applicationsData = JSON.parse(localStorage.getItem('applicationsData')) || {};
    applicationsData[applicationID] = {
      ...applicationsData[applicationID],
      globalBeneficiaryUnder18: globalBeneficiaryUnder18
    };
    localStorage.setItem('applicationsData', JSON.stringify(applicationsData));
  }, [globalBeneficiaryUnder18, applicationID]);

  return (
    <ApplicationContext.Provider value={{ existingClientData, setExistingClientData, globalBeneficiaryUnder18, setGlobalBeneficiaryUnder18, isTrusteeAdded, setIsTrusteeAdded, globaltotalBenPercentage, setGlobalTotalBenPercentage, isClientVerified, setIsClientVerified }}>
      {children}
    </ApplicationContext.Provider>
  );
}
