import React, { useEffect, useContext, useState } from 'react';
import { useFormik } from 'formik';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as Yup from 'yup';
import { EditIcon } from '../icons';
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import { TrashIcon } from '../icons';

import SectionTitle from '../components/Typography/SectionTitle';
import { ClientContext } from '../context/ClientContext';
import { Input, HelperText, Label, Select, Button, TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell} from '@windmill/react-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, Toaster } from 'react-hot-toast';
import { format, set, parse } from 'date-fns';
import { differenceInYears } from 'date-fns';
import axios from 'axios';
import EditTrusteeModal from './EditTrusteeModal';
import {useApplicationContext} from '../context/ApplicationContext';



const relationshipOptions = [
  { code: 200222, description: 'SELF', genderOptions: ['MALE','FEMALE'] },
  { code: 200223, description: 'CHILD', genderOptions: ['MALE', 'FEMALE'] },
  { code: 200224, description: 'FATHER', genderOptions: ['MALE'] },
  { code: 200225, description: 'MOTHER', genderOptions: ['Female'] },
  { code: 200327, description: 'NEPHEW', genderOptions: ['MALE'] },
  { code: 201035, description: 'SPOUSE', genderOptions: ['MALE', 'FEMALE'] },
  { code: 201458, description: 'AUNT', genderOptions: ['FEMALE'] },
  { code: 201459, description: 'BROTHER-IN-LAW', genderOptions: ['MALE'] },
  { code: 201460, description: 'SISTER-IN-LAW', genderOptions: ['FEMALE'] },
  { code: 201461, description: 'UNCLE', genderOptions: ['MALE'] },
  { code: 201462, description: 'COUSIN', genderOptions: ['MALE', 'FEMALE'] },
  { code: 201463, description: 'DAUGHTER', genderOptions: ['FEMALE'] },
  { code: 201464, description: 'SON', genderOptions: ['MALE'] },
  { code: 201465, description: 'FRIEND', genderOptions: ['MALE', 'FEMALE'] },
  { code: 201466, description: 'GRAND DAUGHTER', genderOptions: ['FEMALE'] },
  { code: 2021247, description: 'STEP SON', genderOptions: ['MALE'] },
  { code: 2021248, description: 'STEP DAUGHTER', genderOptions: ['FEMALE'] },
  { code: 237, description: "SISTER", genderOptions: ['FEMALE'] },
  { code: 235, description: 'BROTHER', genderOptions: ['MALE'] },
];

export const Trustee = () => {
  let applicationID = localStorage.getItem('currentApplicationID');
  const initials = applicationID.substring(0, 3);

   const [genderOptions, setGenderOptions] = useState([]);
  const [trustee, setTrustee] = useState([]);
  
  const [editTrusteeIndex, setEditTrusteeIndex] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { globalBeneficiaryUnder18,  isTrusteeAdded, setIsTrusteeAdded } = useApplicationContext();
  
    const { updateStepCompletionStatus } = useContext(ClientContext);

  // Formik initialization
  const initialValues = {
    firstName:  '',
    otherName:  '',
    lastName: '',
    dob: '',
    gender: '',
    relationship: '',
    Address: '',
    contactNumber: '',
    email: '',
  };

  

  const validationSchema =Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    otherName: Yup.string().required('Other Names are required'),
    lastName: Yup.string().required('Last Name is required'),
    dob: Yup.string().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required'),
    relationship: Yup.string().required('Relationship is required'),
    Address: Yup.string().required('Address is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    contactNumber: Yup.string().required('Trustee Contact Number is required'),
  });


  const handleRelationshipChange = (event) => {
  const selectedRelationship = event.target.value;
  const selectedOption = relationshipOptions.find((option) => option.description === selectedRelationship);

  if (selectedOption) {
    formik.setFieldValue('relationship', selectedOption.description);
    formik.setFieldValue('gender', ''); // Reset gender field when relationship changes
    setGenderOptions(selectedOption.genderOptions);
  } else {
    formik.setFieldValue('relationship', '');
    formik.setFieldValue('gender', '');
    setGenderOptions([]);
  }
  };

      const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };

  const calculateAge = (dob) => {
    return differenceInYears(new Date(), dob);
  };


const handleEditSubmit = (editedIndex, editedValues) => {
  const trustee_Age = calculateAge(editedValues.dob);

  if (trustee_Age < 18) {
    toast.error('The trustee cannot be less than 18 years.');
    return;
  }

  const updatedTrustees = trustee.map((trustee, index) =>
    index === editedIndex ? { ...trustee, ...editedValues } : trustee
  );

  setTrustee(updatedTrustees);

  const updatedTrusteeData = [
    {
      trustee: updatedTrustees,
    },
  ];

  setIsTrusteeAdded(true);
  updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 5 : 4, true);

  updateLocalStorage(updatedTrusteeData);

  setEditModalOpen(false);
  setEditTrusteeIndex(null);
  
};





const updateLocalStorage = (updatedTrustee) => {
  const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));

  const updatedOngoingApplications = cachedOngoingApplications.map(applicationGroup => {
    return applicationGroup.map(application => {
      if (application.id === applicationID) {
        const updatedApplication = {
          ...application,
          data: {
            ...application.data,
            beneficiaryData: [
              {
                ...application.data.beneficiaryData[0], 
                trustee: updatedTrustee,
              },
            ],
          },
        };
        return updatedApplication;
      }
      return application;
    });
  });

  localStorage.setItem('ongoingApplications', JSON.stringify(updatedOngoingApplications));
};




  
const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: async (values) => {
    const trustee_Age = calculateAge(values.dob);

    if (trustee_Age < 18) {
      toast.error('The trustee cannot be less than 18 years.');
      return;
    }
    const newTrustee = {
      firstName: values.firstName,
      otherName: values.otherName,
      lastName: values.lastName,
      dob: values.dob,
      gender: values.gender,
      relationship: values.relationship,
      Address: values.Address,
      contactNumber: values.contactNumber,
      email: values.email,
    };
    const updatedTrustee = [...trustee, newTrustee];
    setTrustee(updatedTrustee);


    const updatedTrusteeData = [
      {
        trustee: updatedTrustee,
      },
    ];
    //updateLocalStorage(updatedTrusteeData);

    setIsTrusteeAdded(true);
    updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 5 : 4, true);

    formik.resetForm();
  },
});

  
  
  const deleteTrustee = (index) => {
  const updatedTrustee = [...trustee];
  updatedTrustee.splice(index, 1);
  setTrustee(updatedTrustee);

    //updateLocalStorage(updatedTrustee);
    setIsTrusteeAdded(false);
};


 const openEditModal = (index) => {
    setEditTrusteeIndex(index);
    setEditModalOpen(true);
  };



 useEffect(() => {
  if (globalBeneficiaryUnder18) {
    const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));

    const matchingApplication = cachedOngoingApplications
      .find(application => application.id === applicationID);

    if (matchingApplication) {
      const savedBeneficiaryData = matchingApplication.data.beneficiaryData;

      if (savedBeneficiaryData && savedBeneficiaryData.trustee) {
        const trusteesArray = savedBeneficiaryData.trustee;
        setTrustee(trusteesArray);
      }
    }
  }
}, [applicationID]);





  

useEffect(() => {
  if (globalBeneficiaryUnder18 && trustee) {
    const cachedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications'));

    const updatedOngoingApplications = cachedOngoingApplications.map(application => {
      if (application.id === applicationID) {
        const updatedBeneficiaryData = {
          ...application.data.beneficiaryData, 
          trustee: trustee,
        };

        return {
          ...application,
          data: {
            ...application.data,
            beneficiaryData: updatedBeneficiaryData,
          },
        };
      }
      return application;
    });

    localStorage.setItem('ongoingApplications', JSON.stringify(updatedOngoingApplications));
    updateStepCompletionStatus(['FEH', 'PEH', 'EHP', 'FPP'].includes(initials) ? 5 : 4, true);
    setIsTrusteeAdded(true);
  }
}, [trustee, applicationID]);










  return (
      <div className=" py-3 mb-8 flex flex-col mr-10 justify-start mt-6">
      <Toaster />
      <div className="mx-6 flex-auto  justify-start">
        <div className="flex justify-center items-center mb-1">
            
               {globalBeneficiaryUnder18  && !isTrusteeAdded && (
                <Alert
                  color="warning"
                  rounded
                icon={HiInformationCircle}
                   >
                 <span>
                   <p className="font-medium">
               
                       Reminder! Please you are gently reminded to add a trustee because you beneficiary(ies) is/are less than 18 years.
                       </p>
                     </span>
                  </Alert>
                      )} 
              
            
            </div>
        
        <SectionTitle textAlign="center">Trustee(s) Details</SectionTitle>
        
        <form onSubmit={formik.handleSubmit}>

            <div className="grid grid-cols-2 gap-8">
            
            <div className="col-span-1 flex flex-col ">

              
        {/* First Name */}
        <Label className="mt-4">
          <span>First Name</span>
          <Input
            className="mt-1"
            placeholder="Enter your first name"
            {...formik.getFieldProps('firstName')}
                  valid={formik.touched.firstName && !formik.errors.firstName}
                  onChange={(e) => handleInputChange('firstName', e)}
              type="text"
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <HelperText className="text-red-600">{formik.errors.firstName}</HelperText>
          )}
        </Label>

        {/* Other Names */}
        <Label className="mt-4">
          <span>Other Names</span>
          <Input
            className="mt-1"
            placeholder="Enter your other names"
              {...formik.getFieldProps('otherName')}
              type="text"
                  valid={formik.touched.otherName && !formik.errors.otherName}
                  onChange={(e) => handleInputChange('otherName', e)}
          />
          {formik.touched.otherName && formik.errors.otherName && (
            <HelperText className="text-red-600">{formik.errors.otherName}</HelperText>
          )}
        </Label>

        {/* Last Name */}
        <Label className="mt-4">
          <span>Last Name</span>
          <Input
            className="mt-1"
            placeholder="Enter your last name"
            {...formik.getFieldProps('lastName')}
                  valid={formik.touched.lastName && !formik.errors.lastName}
                  onChange={(e) => handleInputChange('lastName', e)}
              type="text"
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <HelperText className="text-red-500">{formik.errors.lastName}</HelperText>
          )}
          </Label>
          
            

                 
              
                    {/* Date of Birth */}
         <Label htmlFor="dob" className="mt-4">
              <span>Date of Birth</span>
           <div className="mt-1">
           <DatePicker
          id="dob"
      selected={formik.values.dob}
      onChange={(date) => formik.setFieldValue('dob', date)}
      dateFormat="dd-MM-yyyy"
      customInput={
        <Input
          type="text"
          placeholder="Select date"
          className={formik.touched.dob && formik.errors.dob ? 'error' : ''}
          value={formik.values.dob ? formik.values.dob.toDateString() : ''}
          
        />
      }
      onBlur={() => formik.setFieldTouched('dob', true)}
      showMonthDropdown
      showYearDropdown
                dropdownMode="select"
                placeholderText="Select date"
      />
      </div>
      {formik.touched.dob && formik.errors.dob && (
      <div className="text-red-500">{formik.errors.dob}</div>
      )}
              </Label>

            </div>



             <div className="col-span-1 flex flex-col ">

           
              
              <Label className="mt-4">
          <span>Email</span>
          <Input
              className="mt-1"
              type="email"
            placeholder="Enter the trustee email"
            {...formik.getFieldProps('email')}
                  valid={formik.touched.email && !formik.errors.email}
                  onChange={(e) => handleInputChange('email', e)}
          />
          {formik.touched.email && formik.errors.email && (
            <HelperText className="text-red-500">{formik.errors.email}</HelperText>
          )}
              </Label>
              
              <Label htmlFor="relationship" className="mt-4">
      <span>Relationship</span>
      <Select
    id="relationship"
    className="mt-1"
    {...formik.getFieldProps('relationship')}
    valid={formik.touched.relationship && !formik.errors.relationship}
    onChange={handleRelationshipChange}
      >
    <option value="">Select Trustee relationship</option>
    {relationshipOptions.map((option) => (
      <option key={option.code} value={option.description}>
        {option.description}
      </option>
    ))}
  </Select>
  {formik.touched.relationship && formik.errors.relationship && (
    <HelperText className="text-red-500">{formik.errors.relationship}</HelperText>
  )}
              </Label>



          

        {/* Gender */}
        {genderOptions.length > 0 && (
  <Label htmlFor="gender" className="mt-4">
    <span>Gender</span>
    <Select
      id="gender"
      className="mt-1"
      {...formik.getFieldProps('gender')}
      valid={formik.touched.gender && !formik.errors.gender}
    >
      <option value="">Select Trustee's Gender</option>
      {genderOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
    {formik.touched.gender && formik.errors.gender && (
      <HelperText className="text-red-500">{formik.errors.gender}</HelperText>
    )}
  </Label>
)}

        {/* Address */}
        <Label className="mt-4">
          <span>Address</span>
          <Input
            className="mt-1"
            placeholder="Enter the trustee address"
            type="text"
            {...formik.getFieldProps('Address')}
                  valid={formik.touched.Address && !formik.errors.Address}


                  onChange={(e) => handleInputChange('Address', e)}
                  
          />
          {formik.touched.Address && formik.errors.Address && (
            <HelperText className="text-red-500">{formik.errors.Address}</HelperText>
          )}
        </Label>

        <Label className="mt-4">
  <span>Trustee Contact Number</span>
 <PhoneInput
inputComponent={Input}
  placeholder="Enter trustee number"
  defaultCountry="GH"
  international
  countryCallingCodeEditable={false}
  value={formik.values.contactNumber}
  
                    onChange={(value) => {
                      
    formik.setFieldValue('contactNumber', value);
    formik.setFieldTouched('contactNumber', true); 
  }}
  error={
      (formik.values.contactNumber &&
        !isPossiblePhoneNumber(formik.values.contactNumber)
        ? ['Invalid phone number length']
        : [])
    }
/>

  {(formik.touched.contactNumber &&
    formik.errors.contactNumber) ||
    (formik.values.contactNumber &&
      !isPossiblePhoneNumber(formik.values.contactNumber)) ? (
    <div className="text-red-600">
      {formik.errors.contactNumber ||
        'Invalid phone number length'}
    </div>
  ) : null}
</Label>


            </div>

            <div className="col-span-2 flex justify-start mt-10">
            <Button disabled={!globalBeneficiaryUnder18}   onClick={formik.handleSubmit} type="submit" className="text-white px-4 py-2 rounded hover:bg-gray-600" style={{backgroundColor: "#792F7E"}}>
              Add Trustee
            </Button>
            </div>
            
            

            </div>

          </form>
     
        <div className="col-span-2 mt-12">
            
              {trustee.length > 0 && (
            <div>
              <SectionTitle>Trustee(s) Added</SectionTitle>
              <TableContainer className="mt-4">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                       <TableCell>Date of Birth</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Relationship</TableCell>
                      <TableCell>Address</TableCell>
                       <TableCell>Action</TableCell>
                      
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {trustee.map((trustee, index) => (
                      <TableRow key={index}>
                        <TableCell>{trustee.firstName}</TableCell>
                        <TableCell>{trustee.lastName}</TableCell>
                       <TableCell>{new Date(trustee.dob).toLocaleDateString('en-GB')}</TableCell>
                        <TableCell>{trustee.email}</TableCell>
                         <TableCell>{trustee.contactNumber}</TableCell>
                        <TableCell>{trustee.gender}</TableCell>
                        <TableCell>{trustee.relationship}</TableCell>
                        <TableCell>{trustee.Address}</TableCell>
                       <TableCell className="flex items-center">
                              <TrashIcon
                              className="w-8 h-8 mr-8" style={{ cursor: 'pointer', fill: "#dc2626" }}
                                onClick={() => deleteTrustee(index)}
                              />
                            <EditIcon className="w-8 h-8" style={{ cursor: 'pointer'}}
                            onClick={() => openEditModal(index)}/>
                            </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
               <div className='mb-16'>
               
              </div>

            </div>
          )}
     {/** <EditTrusteeModal
  isOpen={selectedTrusteeIndex !== null}
  onClose={() => {
    setSelectedTrusteeIndex(null);
    setEditFormValues(null);
  }}
  trustee={editFormValues} // Pass the beneficiary object as prop
  index={selectedTrusteeIndex} // Pass the index of the beneficiary being edited
  validationSchema={validationSchema}
  initialValues={editFormValues} 
  removeTrustee={removeTrustee}
  relationshipOptions={relationshipOptions}
  handleRelationshipChange={handleRelationshipChange}
  genderOptions={genderOptions}
  handleSaveChanges={handleSaveChanges}
/> */}     

         </div>
      </div>
      <EditTrusteeModal
  isOpen={editModalOpen}
  onClose={() => setEditModalOpen(false)}
  trusteeIndex={editTrusteeIndex}
  initialValues={trustee[editTrusteeIndex]}
  saveEditedTrustee={handleEditSubmit}
        validationSchema={validationSchema}
        relationshipOptions={relationshipOptions}
       
       
/>
    </div>
  );
};

export default Trustee;