import React, { useEffect, useState, useContext, forwardRef, useRef } from 'react'
import 'react-phone-number-input/style.css'
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {IMaskMixin, IMaskInput  } from 'react-imask';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber, isValidPhoneNumber  } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import _ from 'lodash';
import { Spinner } from 'flowbite-react'
import OnboardingAlert from '../components/Alerts/OnboardingAlert';
import 'react-datepicker/dist/react-datepicker.css';
import SectionTitle from '../components/Typography/SectionTitle'
import { Input, HelperText, Label, Select, Button } from '@windmill/react-ui'
import toast, { Toaster } from 'react-hot-toast';
import { differenceInYears, format, parse, parseISO } from 'date-fns';
import axios from 'axios'
import { maritalStatus } from '../constants/maritalStatus'
import { titles } from '../constants/titles'
import {occupations} from '../constants/occupations'
import { useApplicationContext } from '../context/ApplicationContext';
import { ClientContext } from '../context/ClientContext';
import ImagePreview, { BackImagePreview } from '../components/ImagePreview/ImagePreview';

import { useFormik } from 'formik';
import * as Yup from 'yup';


const apiUrl = `${process.env.REACT_APP_API_URL}/SL_CreateClient`;
const apiKey = process.env.REACT_APP_API_KEY;
const apiUser = process.env.REACT_APP_API_USER;


export const ClientInfo = () => {
  let applicationID = localStorage.getItem('currentApplicationID');
    const initials = applicationID.substring(0, 3);

  const { updateStepCompletionStatus } = useContext(ClientContext);

  const [loading, setLoading] = useState(false);
  const [newClientCode, setNewClientCode] = useState(null);
const { existingClientData} = useApplicationContext();
  

 const handleDateChange = (date) => {
  setSelectedDate(date);
  formik.setFieldValue('dob', date);
};





const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];

    
     const matchingApplication = storedOngoingApplications
      .flat() 
    .find(app => app.id === applicationID);
  
  const clientInfo = matchingApplication.data.clientData  

const isExistingDataNotEmpty = () => {
  

  for (const key in existingClientData) {
    if (existingClientData[key]) {
      return true;
    }
  }

  return false;
};



const initialValues = existingClientData
  ? {
      firstName: existingClientData.firstName || '',
      otherNames: existingClientData.otherNames || '',
      lastName: existingClientData.lastName || '',
       dob: existingClientData.dob
        ? parse(existingClientData.dob, 'dd-MM-yyyy', new Date())
        : null,
      gender: existingClientData.gender || '',
      occupation: existingClientData.occupation || '',
      idType: existingClientData.idType || '',
      ghanaCardNumber: existingClientData.ghanaCardNumber || '',
      ghanaCardFrontFile: existingClientData.ghanaCardFrontFile || null,
      ghanaCardBackFile: existingClientData.ghanaCardBackFile || null,
      email: existingClientData.email || '',
      clientPostalAddress: existingClientData.clientPostalAddress || '',
      residentialAddress: existingClientData.residentialAddress || '',
      maritalStatus: existingClientData.maritalStatus || '',
      title: existingClientData.title || '',
      mobileNumber: existingClientData.mobileNumber || '',
    }
  : {
      firstName: '',
      otherNames: '',
      lastName: '',
      dob: null,
      gender: '',
      occupation: '',
      idType: '',
      ghanaCardNumber: '',
      ghanaCardFrontFile: null,
      ghanaCardBackFile: null,
      email: '',
      clientPostalAddress: '',
      residentialAddress: '',
      maritalStatus: '',
      title: '',
      mobileNumber: '',
    };


const [selectedDate, setSelectedDate] = useState(
  initialValues.dob ? new Date(initialValues.dob) : null
);



  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    otherNames: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    dob: Yup.date().nullable().required('Date of Birth is required'),
    gender: Yup.string().required('Gender is required'),
    maritalStatus: Yup.string(),
    title: Yup.string(),
    mobileNumber: Yup.string()
    .required('Mobile/WhatsApp Number is required'),
    // .matches(/^0\d{9}$/, 'Mobile/WhatsApp Number must be a 10-digit number starting with zero'),
    occupation: Yup.string().required('Occupation is required'),
    idType: Yup.string().required('ID Type is required'),
    ghanaCardNumber: Yup.string().required('Ghana Card Number is required'),
    ghanaCardFrontFile: Yup.mixed().required('Front image of Ghana Card is required'),
      ghanaCardBackFile: Yup.mixed().required('Back image of Ghana Card is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    clientPostalAddress: Yup.string().required('Client Postal Address is required'),
    residentialAddress: Yup.string().required('Residential Address is required'),
  });
    
  const handleInputChange = (fieldName, event) => {
    const inputValue = event.target.value.toUpperCase();
    formik.setFieldValue(fieldName, inputValue);
  };






   const ref = useRef(null);
const inputRef = useRef(null);
const handleGhanaCardNumberAccept = (value, mask) => {

  if (mask._value !== formik.values.ghanaCardNumber) {
    // Only update the form value if it's different
    formik.setFieldValue('ghanaCardNumber', mask._value);
    setTimeout(() => {
      inputRef.current.focus();
    }, 1);
  }
};


   const MaskedInput = IMaskMixin(({ inputRef, ...props }) => (
    
    <Input {...props} ref={inputRef} />
  ));
  
const genderMapping = {
  M: 'MALE',
  F: 'FEMALE',
};
    

  const formik = useFormik({
    enableReinitialize: true,
  initialValues,
   validationSchema,
  
    onSubmit: async (values) => {   
      let parsedDob;
  
  console.log("Original dob:", values.dob);
  
  if (values.dob instanceof Date) {
    parsedDob = values.dob;
  } else {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (dateRegex.test(values.dob)) {

      parsedDob = parse(values.dob, 'dd-MM-yyyy', new Date());
    } else {
      parsedDob = parseISO(values.dob);
      if (isNaN(parsedDob.getTime())) {
        console.error("Failed to parse date:", values.dob);
        return;  
      }
    }
  }

  const clientAge = differenceInYears(new Date(), parsedDob);
  
  console.log("Parsed dob:", parsedDob);
      console.log("Client Age:", clientAge);
      

      let minAgeAtEntry, maxAgeAtEntry;
    
    switch (initials) {
        case "WPP":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 60;
        break;
        case "FWP":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 60;
        break;
      case "PWP":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 60;
            break;
        case "CLP":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 52;
        break;
        case "PCL":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 52;
        break;
      case "FCL":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 52;
            break;
        case "UPP":
            minAgeAtEntry = 21;
            maxAgeAtEntry = 56;
        break;
      case "FUP":
            minAgeAtEntry = 21;
            maxAgeAtEntry = 56;
        break;
      case "PUP":
            minAgeAtEntry = 21;
            maxAgeAtEntry = 56;
            break;
        case "EHP":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 60;
        break;
      case "FEH":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 60;
        break;
    case "PEH":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 60;
            break;
        case "FPP":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 55;
            break;
        case "CBP":
            minAgeAtEntry = 18;
            maxAgeAtEntry = 50;
            break;
        
        default:
            minAgeAtEntry = null;
            maxAgeAtEntry = null;
            break;
     }
     
   if (clientAge < minAgeAtEntry || clientAge >= maxAgeAtEntry-1) {
      toast.error(<div>
      Client must be at least <strong>{minAgeAtEntry}</strong> years but below <strong>{maxAgeAtEntry}</strong> years for {initials} Policy.
    </div>, {
        position: "top-center",
        duration: 7000,
        
      });
      return; 
    }
     

    
     
          const selectedTitle = titles.find((title) => title.Code === parseInt(values.title));
  const titleValue = selectedTitle ? selectedTitle.Title : '';
     
     console.log('title', titleValue);


    const formData = {
      Occupation: values.occupation,
      Country_Code: 95,
      DOB: values.dob ? format(new Date(values.dob), 'dd-MM-yyyy') : '',
      Email: values.email,
      webClntEmplPhone: formatPhoneNumber(values.mobileNumber).replace(/\s/g, ''),
      Tel_Number: formatPhoneNumber(values.mobileNumber).replace(/\s/g, ''),
      First_Name: values.firstName,
      Other_Name: values.otherNames || null,	
      Surname: values.lastName,
      Gender: values.gender?.charAt(0).toUpperCase() ?? 'F',
      ID_Type: values.idType,
      ID_Number: values.ghanaCardNumber,
      Marital_Status: values.maritalStatus,
      Phone_Number: formatPhoneNumber(values.mobileNumber).replace(/\s/g, ''),
      Nationality: 'Ghanaian',
      Residential_Address: values.residentialAddress,
      Postal_Address: values.clientPostalAddress,
      Title: titleValue,
      Client_Type: 'I',
    };
const selectedOccupation = occupations.find(
  (occupation) => occupation.occupationCode === parseInt(values.occupation)
);
const occupationDesc = selectedOccupation ? selectedOccupation.occupationDesc : '';


  try {
setLoading(true);
const response = await axios.post(apiUrl, {
  ...formData,
  Api_Key: apiKey,
  Api_User: apiUser,
});
setLoading(false);
if (response.status === 200 && response.data.message === "You should be above 18 years to register.") {
        toast.error("The client is below 18 years "+" \n "+ response.data.message, {
          position: "top-center",
          duration: 7000,
        });
        return;
      }
      
      else if (response.data.message === "1 Client(s) already exist" && response.data.message_code === 104) {
      updateStepCompletionStatus(1,false);
      toast.success(values.firstName + " "+ values.lastName + " is an existing client", {
        duration: 7000,
      });
        return;
      }
        
        else if (response.data.message === "Account already existing please try to login to proceed." && response.data.status === "OK") {
        updateStepCompletionStatus(1,false);
      toast.success(values.firstName + " "+ values.lastName + " is an existing client", {
        duration: 7000,
      });
        return;
      }
        
        
        
      else {
  if (response.data.webClntCode && response.data.clntCode) {
    updateStepCompletionStatus(1, true);
    setNewClientCode(response.data.webClntCode);
    const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
    const updatedOngoingApplications = ongoingApplications.map(app => {
      if (app.id === applicationID) {
        return {
          ...app,
          data: {
            ...app.data,
            clientData: {
              ...app.data.clientData,
              New_Client_Code: response.data.webClntCode
            }
          }
        };
      }
      return app;
    });

          localStorage.setItem('ongoingApplications', JSON.stringify(updatedOngoingApplications));
          updateStepCompletionStatus(1,true);

          toast.success("New Client Added Successfully. Proceed to calculate a quote.", {
            duration: 7000,
          });
          setLoading(false);

          const updatedClientData = {
        ...formik.values,
          New_Client_Code: response.data.webClntCode 

          };
          //setClientData(updatedClientData);
          

          updateStepCompletionStatus(1,true);
          
          
        }
      }
    } catch (error) {
       if (error) {
        setLoading(false);
        //updateFormSubmitted(true);
      updateStepCompletionStatus(1,false);
        // toast.error("Something went wrong. Please try again", {
        //   position: "top-center",
        //   duration: 4000,
        // })
      }; 
    }
   },
  
 });
  


    
useEffect(() => {
  const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const matchingApplication = storedOngoingApplications.find(app => app.id === applicationID);

  if (matchingApplication && !formik.dirty) {
    const clientData = matchingApplication.data.clientData;

    const initialValues = existingClientData
      ? {
         
          ...existingClientData,
          dob: existingClientData.dob ? parse(existingClientData.dob, 'dd-MM-yyyy', new Date()) : null,
        }
      : {
        
          ...clientData,
          dob: clientData.dob ? parse(clientData.dob, 'dd-MM-yyyy', new Date()) : null,
        };

    formik.setValues(initialValues);
    setSelectedDate(initialValues.dob);

  }
}, [applicationID, existingClientData, formik.dirty]);


  

useEffect(() => {
  if (formik.dirty && updateStepCompletionStatus) {
    const storedOngoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
    
    // Find the application with the specified applicationID
    const applicationToUpdate = storedOngoingApplications.find(app => app.id === applicationID);
    
    if (applicationToUpdate) {
      const existingNewClientCode = applicationToUpdate.data.clientData?.New_Client_Code || null;
      
      // Update the client data of the found application
      applicationToUpdate.data.clientData = {
        ...formik.values,
        New_Client_Code: existingNewClientCode
      };

      // Update local storage with the modified application
      localStorage.setItem('ongoingApplications', JSON.stringify(storedOngoingApplications));

      // Update step completion status
      updateStepCompletionStatus(1, true);
    }
  }
}, [applicationID, newClientCode]);






  
  useEffect(() => {
  if (formik.values.dob) {
    const dobDate = new Date(formik.values.dob);
  }
}, [formik.values]);



    return (
      
    <div className=" py-3 mb-8 flex flex-col mr-10 justify-start mt-10">
        <div className="mx-6 flex-auto  justify-start">
          <OnboardingAlert />
    <SectionTitle textAlign="center" >Client Information</SectionTitle>
            <form onSubmit={formik.handleSubmit}>

          <div className="grid grid-cols-2 gap-8 mb-8 " >
            
      
             <div className="col-span-1 flex flex-col ">
              
            <Label className="mt-4">
        <span>First Name</span>
                  <Input
                    disabled={isExistingDataNotEmpty()}
          className="mt-1"
          placeholder="Enter your first name"
        {...formik.getFieldProps('firstName')}
        
              valid={formik.touched.firstName && !formik.errors.firstName}
              onChange={(e) => handleInputChange('firstName', e)}
              //name attribute
                            name="firstName"
                           
                            id="firstName"
                            
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <HelperText className="text-red-600">{formik.errors.firstName}</HelperText>
        )}
      </Label>

      <Label className="mt-4">
        <span>Other Names</span>
                  <Input
                     disabled={isExistingDataNotEmpty()}
          className="mt-1"
          placeholder="Enter your other names"
                {...formik.getFieldProps('otherNames')}
                onChange={(e) => handleInputChange('otherNames', e)}
              valid={formik.touched.otherNames && !formik.errors.otherNames}
              name="otherNames" 
        />
        {formik.touched.otherNames && formik.errors.otherNames && (
          <HelperText className="text-red-600">{formik.errors.otherNames}</HelperText>
        )}
      </Label>

      <Label className="mt-4">
        <span>Last Name</span>
                  <Input
                     disabled={isExistingDataNotEmpty()}
          className="mt-1"
          placeholder="Enter your last name"
                {...formik.getFieldProps('lastName')}
                onChange={(e) => handleInputChange('lastName', e)}
                
                            valid={formik.touched.lastName && !formik.errors.lastName}
                            name="lastName"
                            
                            id="lastName"
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <HelperText className="text-red-500">{formik.errors.lastName}</HelperText>
        )}
            </Label>


            <Label  className="mt-4">
  <span>Date of Birth</span>
  <div className="mt-1">
                    <DatePicker
                      disabled={isExistingDataNotEmpty()}
      id="dob"
      selected={selectedDate} // Use selectedDate state
                      onChange={handleDateChange}
                      maxDate={new Date()}
      dateFormat="dd-MM-yyyy"
      customInput={
        <Input
          type="text"
          placeholder="Select date"
          className={formik.touched.dob && formik.errors.dob ? 'error' : ''}
          
         

        />
      }
      onBlur={() => formik.setFieldTouched('dob', true)}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      placeholderText="Select date"
    />
  </div>
  {formik.touched.dob && formik.errors.dob && (
    <div className="text-red-500">{formik.errors.dob}</div>
  )}
</Label>


              




                  <Label className="mt-4">
  <span>Mobile/WhatsApp Number</span>
                  <PhoneInput
                    disabled={isExistingDataNotEmpty()}
inputComponent={Input}
  placeholder="Enter client's mobile/WhatsApp number"
  defaultCountry="GH"
  international
  countryCallingCodeEditable={false}
  value={formik.values.mobileNumber}
  
                    onChange={(value) => {
                      
    formik.setFieldValue('mobileNumber', value);
    formik.setFieldTouched('mobileNumber', true); 
  }}
  error={
      (formik.values.mobileNumber &&
        !isValidPhoneNumber (formik.values.mobileNumber)
        ? ['Invalid phone number length']
        : [])
    }
/>

  {(formik.touched.mobileNumber &&
    formik.errors.mobileNumber) ||
    (formik.values.mobileNumber &&
      !isValidPhoneNumber (formik.values.mobileNumber)) ? (
    <div className="text-red-600">
      {formik.errors.mobileNumber ||
        'Invalid phone number length'}
    </div>
  ) : null}
</Label>

  
                

                
                 <Label className="mt-4">
        <span>ID Type</span>
                  <Select
                     disabled={isExistingDataNotEmpty()}
          className="mt-1"
          {...formik.getFieldProps('idType')}
          valid={formik.touched.idType && !formik.errors.idType}
        >
          <option value="">Select your ID type</option>
         
          <option value="ghanaCard">GHANA CARD</option>
        </Select>
        {formik.touched.idType && formik.errors.idType && (
          <HelperText className="text-red-500">{formik.errors.idType}</HelperText>
        )}
      </Label>



<Label className="mt-4">
  <span>Ghana Card Number</span>
  <div className="mt-1 relative">
                    <MaskedInput
                       disabled={isExistingDataNotEmpty()}
      mask="GHA-000000000-0"
      unmask={false}
      lazy={false}
      value={formik.values.ghanaCardNumber}
      onAccept={(value, mask) => handleGhanaCardNumberAccept(value, mask)}
      ref={ref}
      inputRef={inputRef} 
      placeholder="GHA-XXXXXXXXX-X"
      className="border-gray-300 focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200 rounded-md shadow-sm"
    />
  </div>
  {formik.touched.ghanaCardNumber && formik.errors.ghanaCardNumber && (
    <HelperText className="text-red-500">{formik.errors.ghanaCardNumber}</HelperText>
  )}
</Label>





           <Label className="mt-8">
                <span className="font-semibold">Front of Ghana Card</span>
                   <div className="mt-1"style={{
                          
                    }}>
                      <Label class="h-full flex flex-col items-center w-full max-w-lg p-5  mt-2 text-center bg-white border-2 border-gray-300 border-dashed cursor-pointer dark:bg-gray-900 dark:border-gray-700 rounded-xl">
                       
                         <svg style={{ color: '#792F7E' }}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12  dark:text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
               </svg>

                   <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>

                   <p class="mt-2 text-xs tracking-wide text-gray-500 dark:text-gray-400">Supported files include SVG, PNG or JPG. </p>

                      <input
                      disabled={isExistingDataNotEmpty()}  id="dropzone-file" type="file" class="hidden"
                        onChange={(event) => {
  const file = event.currentTarget.files[0];
  const reader = new FileReader();

  reader.onload = () => {
    if (reader.readyState === 2) {
     
      formik.setFieldValue("ghanaCardFrontFile", reader.result);
    }
  };

  reader.readAsDataURL(file);
}}

                       valid={formik.touched.ghanaCardFrontFile && !formik.errors.ghanaCardFrontFile}/>
                     </Label>
                  </div>
                  {formik.touched.ghanaCardFrontFile && formik.errors.ghanaCardFrontFile && (
            <HelperText className="text-red-500">{formik.errors.ghanaCardFrontFile}</HelperText>
                     )}

                   <div className='mt-8 justify-center'>
                  
                    {formik.values.ghanaCardFrontFile && <ImagePreview file={formik.values.ghanaCardFrontFile} />}


                    </div>
               
              </Label>
           </div>

              <div className="col-span-1 flex flex-col">
                

             
                  
            <Label className="mt-4">
        <span>Email</span>
                  <Input
                     disabled={isExistingDataNotEmpty()}
          className="mt-1"
          placeholder="Enter your email"
          {...formik.getFieldProps('email')}
                valid={formik.touched.email && !formik.errors.email}
                onChange={(e) => handleInputChange('email', e)}
        />
        {formik.touched.email && formik.errors.email && (
          <HelperText className="text-red-500">{formik.errors.email}</HelperText>
        )}
                </Label>
                
         

    
                

            <Label className="mt-4">         
        <span>Title</span>
                  <Select
                    disabled={isExistingDataNotEmpty()}
          className="mt-1"
          {...formik.getFieldProps('title')}
                valid={formik.touched.title && !formik.errors.title}
                onChange={(e) => handleInputChange('title', e)}
                
                id="title"
                name="title"
        >
          <option value="">Select your title</option>
          {titles.map((titles) => (
            <option key={titles.Code} value={titles.Code}>
              {titles.Title}
            </option>
          ))}
        </Select>
        {formik.touched.title && formik.errors.title && (
          <HelperText className="text-red-500">{formik.errors.title}</HelperText>
        )}
      </Label>
                

              <Label className="mt-4">
        <span>Marital Status</span>
                  <Select
                     disabled={isExistingDataNotEmpty()}
          className="mt-1"
                {...formik.getFieldProps('maritalStatus')}
                onChange={(e) => handleInputChange('maritalStatus', e)}
                
          valid={formik.touched.maritalStatus && !formik.errors.maritalStatus}
        >
          <option value="">Select your marital status</option>
          <option value="M">MARRIED</option>
          <option value="D">DIVORCED</option>
          <option value="S">SINGLE</option>
        </Select>
        {formik.touched.maritalStatus && formik.errors.maritalStatus && (
          <HelperText className="text-red-500">{formik.errors.maritalStatus}</HelperText>
        )}
      </Label>

            



   <Label className="mt-4">
  <span>Occupation</span>
  <Select
     disabled={isExistingDataNotEmpty()}
    className="mt-1"
    {...formik.getFieldProps('occupation')}
    valid={formik.touched.occupation && !formik.errors.occupation}
  >
    <option value="">Select client's occupation</option>
    {occupations.map((occupation) => (
      <option
        key={occupation.occupationCode}
        value={occupation.occupationCode}
        selected={occupation.occupationCode === initialValues.occupation}
      >
        {occupation.occupationDesc}
      </option>
    ))}
  </Select>
  {formik.touched.occupation && formik.errors.occupation && (
    <HelperText className="text-red-500">{formik.errors.occupation}</HelperText>
  )}
</Label>


    

       
      <Label className="mt-4">
  <span>Gender</span>
  <Select
     disabled={isExistingDataNotEmpty()}
    className="mt-1"
    {...formik.getFieldProps('gender')}
    valid={formik.touched.gender && !formik.errors.gender}
    onChange={formik.handleChange}
  >
    <option value="">Select client's gender</option>
    {Object.entries(genderMapping).map(([shortGender, fullGender]) => (
      <option
        key={shortGender}
        value={shortGender}
        selected={shortGender === initialValues.gender}
      >
        {fullGender}
      </option>
    ))}
  </Select>
  {formik.touched.gender && formik.errors.gender && (
    <HelperText className="text-red-500">{formik.errors.gender}</HelperText>
  )}
</Label>


            <Label className="mt-4">
        <span>Postal Address</span>
                  <Input
                     disabled={isExistingDataNotEmpty()}
          className="mt-1"
          placeholder="postal address"
                {...formik.getFieldProps('clientPostalAddress')}
                onChange={(e) => handleInputChange('clientPostalAddress', e)}
          valid={formik.touched.clientPostalAddress && !formik.errors.clientPostalAddress}
        />
        {formik.touched.clientPostalAddress && formik.errors.clientPostalAddress && (
          <HelperText className="text-red-500">{formik.errors.clientPostalAddress}</HelperText>
        )}
            </Label>



            
            <Label className="mt-4">
        <span>Residential Address</span>
                  <Input
                     disabled={isExistingDataNotEmpty()}
          className="mt-1"
          placeholder="Residential address"
                {...formik.getFieldProps('residentialAddress')}
                 onChange={(e) => handleInputChange('residentialAddress', e)}
          valid={formik.touched.residentialAddress && !formik.errors.residentialAddress}
        />
        {formik.touched.residentialAddress && formik.errors.residentialAddress && (
          <HelperText className="text-red-500">{formik.errors.residentialAddress}</HelperText>
        )}
                </Label>
                
                <Label className="mt-8">
                <span className="font-semibold">Back of Ghana Card</span>
                
                 
                   <div className="mt-1"style={{
                          
                          
                    }}>
                      <Label  class="h-full flex flex-col items-center w-full max-w-lg p-5  mt-2 text-center bg-white border-2 border-gray-300 border-dashed cursor-pointer dark:bg-gray-900 dark:border-gray-700 rounded-xl">
                       
                         <svg style={{ color: '#792F7E' }}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12  dark:text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
               </svg>

                   <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>

                   <p class="mt-2 text-xs tracking-wide text-gray-500 dark:text-gray-400">Supported files include SVG, PNG or JPG. </p>

                      <input
                       disabled={isExistingDataNotEmpty()}  id="dropzone-file" type="file" class="hidden"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                            const reader = new FileReader();

                            reader.onload = () => {
                           if (reader.readyState === 2) {
      // Set the formik field value to the data URL obtained from the FileReader
                        formik.setFieldValue("ghanaCardBackFile", reader.result);
                           }
                                };

                                reader.readAsDataURL(file);
                                        }}

                       valid={formik.touched.ghanaCardBackFile && !formik.errors.ghanaCardBackFile}/>
                     </Label>
                  </div>
                  {formik.touched.ghanaCardBackFile && formik.errors.ghanaCardBackFile && (
            <HelperText className="text-red-500">{formik.errors.ghanaCardBackFile}</HelperText>
                     )}

                   <div className='mt-8 justify-center'>
                   {formik.values.ghanaCardBackFile && <ImagePreview file={formik.values.ghanaCardBackFile} />}

                    </div>
               
              </Label>

             </div>

      
            <div className="col-span-2 flex justify-start mt-10">
                <Button
                  disabled={isExistingDataNotEmpty()}
                  onClick={formik.handleSubmit}
            className="px-6 py-2 text-white rounded-md"
            style={{ backgroundColor: "#792F7E" }}
            
                >
                 {loading ? (
    <>
      <Spinner size="md" color="" className="mr-2" />
      Adding Client...
    </>
  ) : (
    ' Add Client' 
  )}
                
                
                </Button>
 
                
              </div>
               </div>

          </form> 
     
      </div>
        <Toaster />
         {/* <Confirmation client={clientFormData} /> */}

    </div>);
 }; 
