import jsPDF from 'jspdf';
import axios from "axios";
import html2canvas from 'html2canvas';
import { PDFDocument  } from 'pdf-lib';
import ReactDOM from 'react-dom';
import React from 'react';
import MandateForm from '../../pages/MandateForm';


const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiKey = process.env.REACT_APP_API_KEY;
const apiUser = process.env.REACT_APP_API_USER;

async function convertImageToPNG(imageDataUrl) {
  return new Promise((resolve, reject) => {

    const image = new Image();
    

    image.onload = () => {

      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

 
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);


      resolve(canvas.toDataURL('image/png'));
    };
    
    image.onerror = (e) => {
    
      reject(e);
    };

    image.src = imageDataUrl;
  });
}




export const generatePDF = async () => {
  const input = document.getElementById('confirmation');

  const dpi = 72;
  const scaleFactor = dpi / 96;

  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: [210, 297], 
  });

  const canvas = await html2canvas(input, {
    scale: scaleFactor,
    scrollY: -window.scrollY,
    windowHeight: window.innerHeight,
  });

  pdf.addImage(canvas.toDataURL('image/png', 0.7), 'PNG', 0, 0, 210, 297); 
  return pdf.output('arraybuffer'); 
};

export const generateMandateFormPDF = async () => {

  const container = document.createElement('div');
  document.body.appendChild(container);
  ReactDOM.render(<MandateForm />, container);


  const dpi = 72;
  const scaleFactor = dpi / 96;


  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: [210, 297], 
  });


  const canvas = await html2canvas(container, {
    scale: scaleFactor,
    scrollY: -window.scrollY,
    windowHeight: window.innerHeight,
  });


  const imgData = canvas.toDataURL('image/png', 0.7);
  const aspectRatio = canvas.width / canvas.height;
  const pageWidth = 210; 
  const pageHeight = pageWidth / aspectRatio;

  pdf.addImage(imgData, 'PNG', 0, 10, pageWidth, pageHeight); 


  document.body.removeChild(container);


  pdf.setFontSize(20);


  return pdf.output('arraybuffer');
};


export const combinePDFs = async () => {

  const ongoingApplicationsJSON = localStorage.getItem('ongoingApplications');
  if (!ongoingApplicationsJSON) {
    console.error('No ongoing applications found.');
    return;
  }


  const pdf1Bytes = await generatePDF();
  const pdf2Bytes = await generateMandateFormPDF();

  if (!pdf1Bytes || !pdf2Bytes) {

    console.error('PDF generation failed.');
    return;
  }


  const applicationID = localStorage.getItem('currentApplicationID');


  const ongoingApplications = JSON.parse(ongoingApplicationsJSON);


const matchingApplication = ongoingApplications.find(application => application.id === applicationID);


  const combinedPdf = await PDFDocument.create();


  const pdfDoc1 = await PDFDocument.load(pdf1Bytes);
  const copiedPages1 = await combinedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
  copiedPages1.forEach((page) => combinedPdf.addPage(page));

 
  const pdfDoc2 = await PDFDocument.load(pdf2Bytes);
  const copiedPages2 = await combinedPdf.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
  copiedPages2.forEach((page) => combinedPdf.addPage(page));

  
  const [firstPage] = copiedPages2; 
  const newPage = combinedPdf.addPage([firstPage.getWidth(), firstPage.getHeight()]);


const frontIdCardImageDataUrl = matchingApplication.data.clientData.ghanaCardFrontFile;
const frontIdCardImagePngDataUrl = await convertImageToPNG(frontIdCardImageDataUrl);
const frontIdCardImage = await combinedPdf.embedPng(frontIdCardImagePngDataUrl.split(',')[1]);


const backIdCardImageDataUrl = matchingApplication.data.clientData.ghanaCardBackFile;
const backIdCardImagePngDataUrl = await convertImageToPNG(backIdCardImageDataUrl);
const backIdCardImage = await combinedPdf.embedPng(backIdCardImagePngDataUrl.split(',')[1]);


const maxImageWidth = newPage.getWidth() * 0.8; 
const maxImageHeight = newPage.getHeight() * 0.4; 


const frontScale = Math.min(maxImageWidth / frontIdCardImage.width, maxImageHeight / frontIdCardImage.height);
const frontImageWidth = frontIdCardImage.width * frontScale;
const frontImageHeight = frontIdCardImage.height * frontScale;


const backScale = Math.min(maxImageWidth / backIdCardImage.width, maxImageHeight / backIdCardImage.height);
const backImageWidth = backIdCardImage.width * backScale;
const backImageHeight = backIdCardImage.height * backScale;


const combinedImageHeight = frontImageHeight + backImageHeight;
const verticalMargin = (newPage.getHeight() - combinedImageHeight) / 3;

const backImageY = verticalMargin;
const frontImageY = backImageY + backImageHeight + verticalMargin;


const frontImageX = (newPage.getWidth() - frontImageWidth) / 2;
const backImageX = (newPage.getWidth() - backImageWidth) / 2;


newPage.drawImage(frontIdCardImage, {
  x: frontImageX,
  y: frontImageY,
  width: frontImageWidth,
  height: frontImageHeight,
});

newPage.drawImage(backIdCardImage, {
  x: backImageX,
  y: backImageY,
  width: backImageWidth,
  height: backImageHeight,
});


   try {
    const combinedPdfBytes = await combinedPdf.save();
    const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });

     const pdfUrl = URL.createObjectURL(blob);

     const downloadLink = document.createElement('a');
downloadLink.href = pdfUrl;
downloadLink.download = 'combinedPDF.pdf'; // You can specify the desired file name here
document.body.appendChild(downloadLink);
downloadLink.click();

// Clean up by removing the temporary link and revoking the object URL
document.body.removeChild(downloadLink);
URL.revokeObjectURL(pdfUrl);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result;
        const base64Content = base64data.split(',')[1];

        const payload = {
          Document: base64Content,
          Document_Type: "PR",
          Proposal_No: localStorage.getItem('proposal_number').toString(),
          Api_User: apiUser,
          Api_Key: apiKey,
        };

        try {
          const response = await axios.post(`${apiUrl}/SL_AttachDocument`, payload, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log('Document uploaded successfully:', response.data);
          resolve({ success: true, message: 'Document uploaded successfully.' });
        } catch (uploadError) {
          console.error('Error uploading document:', uploadError);
          reject({ success: false, message: 'Error uploading document.' });
        }
      };

      reader.onerror = () => {
        console.error('Error converting PDF to base64:', reader.error);
        reject({ success: false, message: 'Error converting PDF to base64.' });
      };

      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error generating PDF:', error);
    return { success: false, message: 'Error generating PDF.' };
  }
};