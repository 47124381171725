export const maritalStatus = [
    {
    "status": 'Married',
    "code": 'M',
    },
   {
    "status": 'Single',
    "code": 'S',
  },
    {
    "status": 'Divorced',
    "code": 'D',
  }
]