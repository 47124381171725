import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { Button, Modal } from 'flowbite-react';
import { CheckMarkIcon } from '../icons';

export default function SubmittedCheckMark({ isOpen, onClose, onDashboard, onOnboard}) {
  const [modalSize, setModalSize] = useState('md');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();


useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setIsModalOpen(onClose); 
   
  };

  const isClientPath = location.pathname.includes('/client');
   const clearLocalStorage = (applicationID) => {
    const keysToPreserve = ['proposalDetails', 'proposal_number', 'agentData', 'ongoingApplications', 'isLoggedIn'];
    
    // Remove the specific application from the ongoingApplications array
    const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
    const updatedApplications = ongoingApplications.map(applicationGroup =>
      applicationGroup.filter(application => application.id !== applicationID)
    );
    localStorage.setItem('ongoingApplications', JSON.stringify(updatedApplications));
    
    // Remove other unnecessary key-value pairs
    Object.keys(localStorage).forEach((key) => {
      if (!keysToPreserve.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    // Also remove 'newApplicationCreated' from sessionStorage
    sessionStorage.removeItem('newApplicationCreated');
  };

   const handleClientButtonClick = () => {
    const applicationID = localStorage.getItem('currentApplicationID');
    clearLocalStorage(applicationID);
    window.location.href = 'https://starlifenew.exceedbranding.com/products/';
  };
                                  

  return (
    <>
      
      

      {/* The modal */}
      <Modal show={isOpen} size={modalSize} onClose={onClose}>
        <Modal.Header > Application Submitted </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <CheckMarkIcon className="mx-auto mb-4 h-24 w-24 text-gray-400 dark:text-gray-200 animate-pulse" />
            <h3 className="mb-5 text-lg font-normal text-gray-600 dark:text-gray-400">
             Awesome! Your proposal application has been submitted successfully!</h3>
            <div className="flex justify-center gap-4">
                 {isClientPath ? (
                <Button style={{ backgroundColor: '#792F7E' }} onClick={handleClientButtonClick}>
                  View Other Products
                </Button>
              ) : (
                <>
                  <Button style={{ backgroundColor: '#792F7E' }} onClick={onDashboard}>
                    Go to Dashboard
                  </Button>
                  <Button color="gray" onClick={onOnboard}>
                    Onboard Another Client
                  </Button>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      
    </>
  );
}
