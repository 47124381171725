import React, { useState, useEffect } from 'react';

export default function ImagePreview({ file }) {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file) {
      setPreview(file);
    } else {
      setPreview(null);
    }
  }, [file]);

  return (
    <div className="p-5 mx-auto mt-2 text-center bg-white rounded-xl h-full w-full" >
      {preview ? <img src={preview} alt="preview" /> : "Loading..."}
    </div>
  );
}
