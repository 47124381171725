import React, { useState, useContext, useEffect } from 'react';
import logo from '../assets/img/starlife-logo.png';
import SectionTitle from '../components/Typography/SectionTitle';

import { format } from 'date-fns';
import { Label,  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '@windmill/react-ui'
import { ClientContext } from '../context/ClientContext';
import { CheckboxContext } from '../context/CheckboxContext';
import { SignatureContext } from '../context/SignatureContext';


import { institutions } from '../constants/institutions';
import { occupations } from '../constants/occupations';
import { banks } from '../constants/banks';
import { paymentModes } from '../constants/paymentModes';

  

const Confirmation = () => {
let applicationID = localStorage.getItem('currentApplicationID');

  const { checkboxesChecked, setCheckboxesChecked, setIsFinalStep, allCheckboxesChecked,  resetCheckboxes } = useContext(CheckboxContext);


  

  

  const initials = applicationID.substring(0, 3);
  let selectedProduct = null;
  let policyCondition = null;
   switch (initials) {
    case 'WPP':
       selectedProduct = 'Wealth Master Plus';
       policyCondition = '/assets/documents/StarLife-Weathmaster-Plus_v2.pdf';
       break;
    case 'CLP':
       selectedProduct = 'Child Lifeline Plus';
       policyCondition = '/assets/documents/StarLife-Child-Lifeline.pdf';
      break;
    case 'UPP':
       selectedProduct = 'Ultimate Protection Plus';
       policyCondition = '/assets/documents/upp-policy-condition.pdf';
       break;
    case 'EHP':
       selectedProduct = 'Esteem Homecall Plus';
       policyCondition = '/assets/documents/StarLife-Esteem-HomeCall-Plus_v2.pdf';
       break;
    case 'SUH':
       selectedProduct = 'Supreme Homecall Plan';
       policyCondition = '/assets/documents/suh-policy-condition.pdf';
       break;
    case 'CBP':
       selectedProduct = 'Cashbuilder Plus';
       policyCondition = '/assets/documents/cbp-policy-condition.pdf';
       break;
    case 'FPP':
       selectedProduct = 'Family Protection Plan';
       policyCondition = '/assets/documents/fpp-policy-condition.pdf';
       break;
    case 'FCL':
       selectedProduct = 'Fabulous Child Education';
       policyCondition = '/assets/documents/StarLife-FCL.pdf';
       break;
    case 'FWP':
       selectedProduct = 'Fabulous Wealthmaster';
       policyCondition = '/assets/documents/StarLife-FWP_v2.pdf';
       break;
    case 'FUP':
       selectedProduct = 'Fabulous Ultimate Protection';
       policyCondition = '/assets/documents/fup-policy-condition.pdf';
       break;
    case 'FEH':
       selectedProduct = 'Fabulous Homecall';
       policyCondition = '/assets/documents/StarLife-Fabulous-Esteem-HomeCall-Plus_v2.pdf';
       break;
    case 'PCL':
       selectedProduct = 'Phobia Child Education';
       policyCondition = '/assets/documents/StarLife-PCL.pdf';
       break;
    case 'PUP':
       selectedProduct = 'Phobia Ultimate Protection';
       policyCondition = '/assets/documents/pup-policy-condition.pdf';
       break;
    case 'PWP':
       selectedProduct = 'Phobia Wealth Master';
       policyCondition = '/assets/documents/StarLife-PWP_v2.pdf';
       break;
    case 'PEH':
       selectedProduct = 'Phobia Homecall';
       policyCondition = '/assets/documents/StarLife-Phobia-Esteem-HomeCall-Plus_v2.pdf';
      break;
    default:
       selectedProduct = 'Unknown Product';
       policyCondition = '/assets/documents/unknown-product-policy-condition.pdf';
  }
  console.log("Selected product in quotation component", selectedProduct);
  

  
  const getBankName = (code) => {
  const bank = banks.find((item) => item.bnkCode === code);
  return bank ? bank.bnkBankName : 'Unknown Bank';
  };  


  const getInstitutionName = (code) => {
  const institution = institutions.find((item) => item.Institution_Code === parseInt(code));
  return institution ? institution.Institution_Name : 'Unknown Institution';
};




    const handleCheckboxChange = (checkboxName) => {
    setCheckboxesChecked((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  
  useEffect(() => {
    const allChecked = allCheckboxesChecked();
    setIsFinalStep(allChecked);
  }, [setIsFinalStep]);


  
  
  const agentData = JSON.parse(localStorage.getItem('agentData'));
  const agentCode = agentData?.agent_code || '';
  const agentName = agentData?.agent_name || '';
  

const ongoingApplications = JSON.parse(localStorage.getItem('ongoingApplications')) || [];
  const matchingApplication = ongoingApplications.find(application => application.id === applicationID);
  
  const signature =matchingApplication?.data?.paymentData?.signature || matchingApplication?.data?.paymentData?.signatureImage
  console.log("matching application", matchingApplication);


  const sumAssured = matchingApplication?.data?.productDetailsData?.quoteDetails?.initialSumAssured;
  const coverTypeAllocations = matchingApplication?.data?.productDetailsData?.quoteDetails?.coverTypeAllocations || [];
  const beneficiaries = matchingApplication?.data?.beneficiaryData?.beneficiaries || [];
  const trustees = matchingApplication?.data?.beneficiaryData?.trustee || [];
  const dependents = matchingApplication?.data?.dependentData|| [];
  const medicalQuestions = matchingApplication?.medicalQuestions || {};
  const occupationCode = parseInt(matchingApplication?.data?.clientData?.occupation, 10);

  
  const currentDate = new Date().toLocaleDateString('en-GB');
  const displayConfirmationPage = matchingApplication?.data?.clientData && matchingApplication?.data?.clientData?.length > 0

const hasTrustees = trustees && trustees.length > 0;
  const hasDependents = dependents && dependents.length > 0;
  




  let currentLetterCode = 69;
const getNextLetter = () => {
  const letter = String.fromCharCode(currentLetterCode);
  currentLetterCode++;
  return letter;
};

const trusteesTitle = hasTrustees ? `[${getNextLetter()}]-TRUSTEE DETAILS` : "";

const dependentsTitle = hasDependents ? `[${getNextLetter()}]-DEPENDENT DETAILS` : "";

const medicalHistoryTitle = `[${getNextLetter()}] MEDICAL HISTORY`;

const dataProtectionTitle = `[${getNextLetter()}] DATA PROTECTION`;

const declarationTitle = `[${getNextLetter()}] DECLARATION`;
  

  

  return (
    <div id="confirmation" className="bg-white rounded-lg text-center px-4 py-2 md:px-8 md:py-4 mb-8 md:mb-10 mt-10 max-w-screen-xl mx-auto">
    
      
      
       { !displayConfirmationPage? (
        <>
          
            <img src={logo} alt="Starlife Logo" className="w-36 h-20 mb-4 mx-auto" />

      <div className="flex justify-between mb-4 md:mb-6">
        <div className="text-white rounded-lg py-1 px-4 md:py-2 md:px-6" style={{ backgroundColor: '#792F7E' }}>
          Agent Code: {agentCode.toUpperCase()}
        </div>
        <div>
              <div className="text-lg md:text-xl font-bold" style={{ color: '#792F7e'}}>STARLIFE {selectedProduct.toUpperCase()} POLICY</div>
            </div>
        <div className="text-white rounded-lg py-1 px-4 md:py-2 md:px-6" style={{ backgroundColor: '#792F7E' }}>
                Agent Name: {agentName.toUpperCase()}
            </div>
      </div>
      {/**  <PageTitle className="text-2xl md:text-3xl">{initials.toUpperCase()} APPLICATION</PageTitle> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            
            <div>
    <SectionTitle bgColor="#792F7E" textColor="white">[A]-CLIENT PERSONAL DETAILS</SectionTitle>
    <div className="flex flex-col">
        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">First Name:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.firstName || ''}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Other Name:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.otherNames || ''}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Surname:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.lastName || ''}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Email:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.email || ''}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Phone Number :</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.mobileNumber || ''}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Occupation:</Label>
            <div className="rounded-md px-2 py-1 underline">{occupations.find((item) => item.occupationCode === occupationCode)?.occupationDesc}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Residential Address:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.residentialAddress || ''}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Postal Address:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.clientPostalAddress || ''}</div>
        </div>

        <div className="flex items-center">
    <Label className="text-base font-semibold text-left w-52">Date of Birth:</Label>
    <div className="rounded-md px-2 py-1 underline">
        {/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z$/.test(matchingApplication?.data?.clientData?.dob)
            ? format(new Date(matchingApplication?.data?.clientData?.dob), 'dd-MM-yyyy')
            : matchingApplication?.data?.clientData?.dob
        }
    </div>
</div>


        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Gender:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.gender ? matchingApplication?.data?.clientData?.gender?.toUpperCase() : ''}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">ID Type:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.idType ? matchingApplication?.data?.clientData?.idType?.toUpperCase() : 'GHANA CARD'}</div>
        </div>

        <div className="flex items-center">
            <Label className="text-base font-semibold text-left w-52">Ghana Card Number:</Label>
            <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.clientData?.ghanaCardNumber || ''}</div>
        </div>
    </div>
</div>



          <div className="col-span-1">
      <SectionTitle bgColor="#792F7E" textColor="white">[B]-PRODUCT &amp; QUOTATION DETAILS</SectionTitle>

          

           <div className="flex items-center">
          <Label className="text-base	font-semibold text-left w-52">Policy Term:</Label>
          <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.productDetailsData?.quoteDetails.policyTerm + ' YEARS'}</div>
          </div>
      
          <div className="flex items-center">
          <Label className="text-base	font-semibold text-left w-52">Premium:</Label>
          <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.productDetailsData?.quoteDetails?.basicPremium ? (
        <>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GHC',
          }).format(matchingApplication?.data?.productDetailsData?.quoteDetails?.basicPremium)}
        </>
      ) : (
        ''
      )}</div>
        </div>
       <div className="flex items-center">
          <Label className="text-base	font-semibold text-left w-52">Sum Assured:</Label>
          <div className="rounded-md px-2 py-1 underline">{sumAssured? (
        <>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GHC',
          }).format(sumAssured)}
        </>
      ) : (
        ''
      )}</div>
        </div>
        <div className="flex items-center">
          <Label className="text-base	font-semibold text-left w-52">Payment Frequency:</Label>
          <div className="rounded-md px-2 py-1 underline">{matchingApplication?.data?.productDetailsData?.quoteDetails?.paymentFrequency}</div>
          </div>
          
          <div className="flex items-center">
  <Label className="text-base font-semibold text-left w-52">Benefit Option Question:</Label>
  <div className="rounded-md px-2 py-1 underline">
    {matchingApplication?.data?.productDetailsData?.quoteDetails?.benefitsOptionQuestion}
  </div>
</div>

{matchingApplication?.data?.productDetailsData?.quoteDetails?.benefitsOptionQuestion === 'YES' && (
  <div className="flex items-center">
    <Label className="text-base font-semibold text-left w-52">Benefit Option Selected:</Label>
    <div className="rounded-md px-2 py-1 underline">
      {matchingApplication?.data?.productDetailsData?.quoteDetails?.incrementalOption}
    </div>
  </div>
)}


    {/**  
       <div className="flex items-start">
  <Label className="text-base font-semibold text-left w-52">Cover Types:</Label>
  <div className="ml-28 relative">
    <ul className="list-disc">
      {coverTypeAllocations.map((allocation, index) => (
        <li key={index} className="flex items-center">
          <div className="mr-2">
            <span className="list-disc">&bull;</span>
          </div>
          <div>{allocation.type}</div>
        </li>
      ))}
    </ul>
  </div>
</div>
*/}
          

       
     
        
      </div>

      </div>
      


        

      <div>
     <SectionTitle bgColor="#792F7E" textColor="white">[C]-PAYMENT DETAILS</SectionTitle>   
      </div>
      <div className="grid grid-cols-2 gap-6 mb-4">
  <div className="col-span-1">
    <div className="flex items-center">
      <Label className="text-base font-semibold text-left w-52">Payment Mode:</Label>
      <div className="rounded-md px-2 py-1 underline">
        {matchingApplication?.data?.paymentData?.paymentMode?.toUpperCase() || ''}
      </div>
    </div>
    {matchingApplication?.data?.paymentData?.paymentMode === 'Bank' && (
      <div className="flex items-center">
        <Label className="text-base font-semibold text-left w-52">Bank Name:</Label>
        <div className="rounded-md px-2 py-1 underline">
          {getBankName(matchingApplication?.data?.paymentData?.bank) || 'Unknown Bank'}
        </div>
      </div>
    )}
    {matchingApplication?.data?.paymentData?.paymentMode === 'Mobile Money' && (
      <div className="flex items-center">
        <Label className="text-base font-semibold text-left w-52">Network Carrier:</Label>
        <div className="rounded-md px-2 py-1 underline">
          {matchingApplication?.data?.paymentData?.network.toUpperCase() || 'Unknown Carrier'}
        </div>
      </div>
    )}
    {matchingApplication?.data?.paymentData?.paymentMode === 'Institution' && (
      <div className="flex items-center">
        <Label className="text-base font-semibold text-left w-52">Institution:</Label>
        <div className="rounded-md px-2 py-1 underline">
          {getInstitutionName(matchingApplication?.data?.paymentData?.institution) || 'Unknown Institution'}
        </div>
      </div>
    )}
    {matchingApplication?.data?.paymentData?.paymentMode === 'Bank' && (
      <div className="flex items-center">
        <Label className="text-base font-semibold text-left w-52">Bank Branch:</Label>
        <div className="rounded-md px-2 py-1 underline">
          {matchingApplication?.data?.paymentData?.bankBranch}
        </div>
      </div>
    )}
  </div>
  <div className="col-span-1">
    {matchingApplication?.data?.paymentData?.paymentMode === 'Bank' && (
      <div className="flex items-center">
        <Label className="text-base font-semibold text-left w-52">Account Number:</Label>
        <div className="rounded-md px-2 py-1 underline">
          {matchingApplication?.data?.paymentData?.accountNumber}
        </div>
      </div>
              )}
              
        {matchingApplication?.data?.paymentData?.paymentMode === 'Institution' && (
      <div className="flex items-center">
        <Label className="text-base font-semibold text-left w-52">Staff ID:</Label>
        <div className="rounded-md px-2 py-1 underline">
          {matchingApplication?.data?.paymentData?.staffID}
        </div>
      </div>
    )}

    {matchingApplication?.data?.paymentData?.paymentMode === 'Mobile Money' && (
      <div className="flex items-center">
        <Label className="text-base font-semibold text-left w-52">Mobile Number:</Label>
        <div className="rounded-md px-2 py-1 underline">
          {matchingApplication?.data?.paymentData?.momoNumber}
        </div>
      </div>
    )}
   {matchingApplication?.data?.paymentData?.paymentMode === 'Bank' && (
  <div className="flex items-center">
    <Label className="text-base font-semibold text-left w-52">
      Account Name:
    </Label>
    <div className="rounded-md px-2 py-1 underline">
      {matchingApplication?.data?.paymentData?.bankAccountName}
    </div>
  </div>
)}

{matchingApplication?.data?.paymentData?.paymentMode === 'Mobile Money' && (
  <div className="flex items-center">
    <Label className="text-base font-semibold text-left w-52">
      Account Name:
    </Label>
    <div className="rounded-md px-2 py-1 underline">
      {matchingApplication?.data?.paymentData?.momoAccountName}
    </div>
  </div>
)}

    <div className="flex items-center">
      <Label className="text-base font-semibold text-left w-52">First Payment Date:</Label>
      <div className="rounded-md px-2 py-1 underline">
        {format(new Date(matchingApplication?.data?.paymentData?.firstPaymentDate), 'dd-MM-yyyy') ||''}
      </div>
    </div>
  </div>
</div>


      


      <SectionTitle bgColor="#792F7E" textColor="white">[D]-BENEFICIARY (IES) DETAILS</SectionTitle>
      
        <TableContainer>
  <Table className="table-auto w-full">
    <TableHeader>
      <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Other Name(S)</TableCell>
        <TableCell>Last Name</TableCell>
        <TableCell>Date of Birth</TableCell>
        <TableCell>Gender</TableCell>
        <TableCell>Relationship</TableCell>
        <TableCell>Contact</TableCell>
        <TableCell>Benefit(%)</TableCell>
      </TableRow>
    </TableHeader>
   <TableBody>
  {beneficiaries.map((beneficiary, index) => (
    <TableRow key={index}>
      <TableCell>{beneficiary.firstNames}</TableCell>
      <TableCell>{beneficiary.otherNames}</TableCell>
      <TableCell>{beneficiary.lastName}</TableCell>
      <TableCell>{format(new Date(beneficiary.dob), 'dd-MM-yyyy')}</TableCell>

      <TableCell>{beneficiary.gender}</TableCell>
      <TableCell>{beneficiary.relationship}</TableCell>
      <TableCell>{beneficiary.contactNumber}</TableCell>
      <TableCell>{beneficiary.percentage}</TableCell>
    </TableRow>
  ))}
</TableBody>

  </Table>
</TableContainer>

      <div>
      {hasTrustees && (
        <div className="mt-10">
          <SectionTitle bgColor="#792F7E" textColor="white">{trusteesTitle}</SectionTitle>

          <TableContainer>
            <Table className="table-auto w-full ">
              <TableHeader>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Other Name(s)</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Date of Birth</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Relationship</TableCell>
                  <TableCell>Contact</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {trustees.map((trustee, index) => (
                  <TableRow key={index}>
                    <TableCell>{trustee.firstName}</TableCell>
                    <TableCell>{trustee.otherName}</TableCell>
                    <TableCell>{trustee.lastName}</TableCell>
                    <TableCell>{format(new Date(trustee.dob), 'dd-MM-yyyy')}</TableCell>
                    <TableCell>{trustee.gender}</TableCell>
                    <TableCell>{trustee.relationship}</TableCell>
                    <TableCell>{trustee.contactNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>


    <div>
      {hasDependents && (
        <div className="mt-10">
          <SectionTitle bgColor="#792F7E" textColor="white">{dependentsTitle}</SectionTitle>

          <TableContainer>
            <Table className="table-auto w-full ">
              <TableHeader>
                <TableRow>
                <TableCell>Last Name</TableCell>
                  <TableCell>Other Name(s)</TableCell>
                  <TableCell>Date of Birth</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {dependents.map((dependent, index) => (
                  <TableRow key={index}>
                   <TableCell>{dependent.lastName}</TableCell>
                    <TableCell>{dependent.otherNames}</TableCell>          
                    <TableCell>{format(new Date(dependent.dob), 'dd-MM-yyyy')}</TableCell>
                    <TableCell>{dependent.gender}</TableCell>
                    <TableCell>{dependent.dependent}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>

      



      <div className="mt-10">
       <SectionTitle bgColor="#792F7E" textColor="white">{medicalHistoryTitle}</SectionTitle>

          <TableContainer>
  <Table className="table-auto w-full">
    <TableHeader>
      <TableRow>
        <TableCell>Are you presently in good health?</TableCell>
          <TableCell>Have you been diagnosed with any medical condition or illness?</TableCell>
          

        
        <TableCell>Are you on any medication?</TableCell>
                
                <TableCell>Have you undergone any surgical operations or procedures?</TableCell>
               
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow>
        <TableCell>{medicalQuestions?.goodHealth}</TableCell>
        <TableCell>{medicalQuestions?.medicalCondition}</TableCell>
                {/* <TableCell>{medicalQuestions.medicalConditionDetails}</TableCell> */}
                <TableCell>{medicalQuestions?.onMedication}</TableCell>
                {/* <TableCell>{medicalQuestions?.medicationDetails}</TableCell>  */}
                <TableCell>{medicalQuestions?.surgicalOperationOrProcedure}</TableCell>
                {/* <TableCell>{medicalQuestions?surgicalOperationOrProcedureDetails}</TableCell> */}

      </TableRow>
    </TableBody>
  </Table>
</TableContainer>


      </div>


 <div className="mt-10 ">

    <SectionTitle bgColor="#792F7E" textColor="white">{dataProtectionTitle}</SectionTitle>
        <div className="w-full mb-4 text-left ">
        <p>
          
          <span className="border-solid rounded-lg border-gray-300 mr-2">
            <input
              type="checkbox"
              checked={checkboxesChecked.checkbox1}
              onChange={() => handleCheckboxChange('checkbox1')}
             
              className="form-checkbox h-5 w-5" style={{ color: '#9E2E89' , borderColor: '#718096' }}
            />
          </span>
          I hereby consent to the processing of my personal data for business relationship and further acknowledge and agree that my personal data may be disclosed to entities associated or affiliated to StarLife Assurance Company Limited to achieve the purpose of processing under this consent.
        </p>
      </div>

      
        </div>

      <div className="mt-4 w-full ">

    <SectionTitle bgColor="#792F7E" textColor="white">{declarationTitle}</SectionTitle>
        <div className="w-full text-left mb-4">           
            <p className="font-semibold ">Declaration by Applicant</p> 
            <br />
            <div className="w-full text-left mb-4">

               <span className="border-solid border-gray-300 mr-2">
            <input
              type="checkbox"
              checked={checkboxesChecked.checkbox2}
              onChange={() => handleCheckboxChange('checkbox2')}
              className="form-checkbox h-5 w-5" style={{ color: '#9E2E89',  borderColor: '#718096'  }}
            />
            </span>
            
                          I, <strong>{matchingApplication?.data?.clientData?.firstName}</strong>
              , declare that every statement in response to questions asked in this application is true and correct to the best of my knowledge. I agree that this application shall serve as the basis and form part of the contract. All the questions have been explained to me in the language that I understand and I have been made to understand that this contract shall become operative until all of the following conditions have been met:
            <br />
            <ul className="list-decimal w-full text-left mb-4 ml-16">
              <li>This application has to be approved by StarLife Assurance Company Limited.</li>
              <li>The appropriate premium will be paid.</li>
              <li>I satisfy all the conditions precedent to the policy especially those pertaining to my health.</li>  
              <li>All the persons proposed for cover are alive and in good health.</li>
            </ul>

            <div className="grid grid-cols-2 gap-6 mb-4 mt-12">
              <div className="col-span-1">
                   <div className="flex items-center">
          <Label className="text-base	font-semibold ">Proposer's Signature:</Label>
                  <div className="rounded-md px-2 py-1 underline">
                    <img src={signature} alt="" className='w-40 h-20' style={{border:"2px solid black", width:200, height:80}} />
          </div>
          </div>
              </div>

              <div className="col-span-1">
                <div className="flex items-center mt-4">
                  <Label className="text-base	font-semibold ">Date:</Label>
                  <div className="rounded-md px-2 py-1 underline">{currentDate}</div>
               </div>
              </div>
            </div>
            <div className='border-solid border-gray-300 mr-2'> 
              
               <span className="border-solid border-gray-300 mr-2">
              <input
                type="checkbox"
                checked={checkboxesChecked.checkbox3}
              onChange={() => handleCheckboxChange('checkbox3')}
                className="form-checkbox h-5 w-5" style={{ color: '#9E2E89',  borderColor: '#718096'  }}
                />
                </span>
              I confirm and agree with the <a 
    href={policyCondition} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="text-blue-600 dark:text-blue-500 hover:underline">
        terms and conditions of this policy
</a>.

            </div>

            </div>
      </div>

      
        </div>
        </>
      ) : (
        <div className="bg-white w-full h-full"></div>
      )}
      

        </div>
      
  );
};

export default Confirmation;
